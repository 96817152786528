import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { ProlongateInfo } from 'src/models/prolongateInfo';
import fetcher from 'src/utils/fetcher';

const setProlongateInfo = makeSetStoreField('prolongateInfo');

const VACANCY_PROLONGATE_INFO_URL = '/employer/vacancy/prolongate_info';

declare global {
    interface FetcherPostApi {
        [VACANCY_PROLONGATE_INFO_URL]: {
            body: { vacanciesIds: number[] };
            queryParams: void;
            response: ProlongateInfo;
        };
    }
}

const fetchProlongateInfo =
    ({ vacanciesIds }: { vacanciesIds: number[] }) =>
    async (dispatch: Dispatch): Promise<ProlongateInfo> => {
        const prolongateInfo = await fetcher.post(VACANCY_PROLONGATE_INFO_URL, { vacanciesIds });

        dispatch(setProlongateInfo(prolongateInfo.data));

        return prolongateInfo.data;
    };

export default fetchProlongateInfo;
