import { useCallback, useState, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import ConversionNumber from 'bloko/blocks/conversion';
import { ModalTitle } from 'bloko/blocks/modal';
import TreeSelectorDummy from 'bloko/blocks/treeSelector/Dummy';
import TreeSelectorPopup from 'bloko/blocks/treeSelectorPopup';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CheckContext from 'src/components/EmployerVacancies/CheckContext';
import { UNCHECK_ALL } from 'src/components/EmployerVacancies/checkReducer';
import fetchFilteredGroupsInfo from 'src/components/EmployerVacancies/fetchFilteredGroupsInfo';
import { useNotification } from 'src/components/Notifications/Provider';
import {
    getManagersListTreeCollection,
    getFilteredGroupManagerIdsSet,
} from 'src/components/VacancyActions/fetchManagersList';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { fetchAndUpdateVacanciesCount } from 'src/models/employerVacancies/vacanciesCount';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import moveDrafts from 'src/components/VacancyModals/TransferDrafts/moveDrafts';

const TrlKeys = {
    titlePrefix: 'employer.myVacancies.draft.move.titlePrefix',
    error: 'employer.myVacancies.draft.move.error',
    cancel: 'employer.myVacancies.draft.move.cancel',
    transferSubmit: 'employer.myVacancies.draft.move',
    transferSubmitOne: 'employer.myVacancies.draft.moveOne',
    transferSome: 'draft.some',
    transferMany: 'draft.many',
};

interface TransferDraftsProps {
    isVisible: boolean;
    draftsIds: number[];
    handleCloseModal: () => void;
}

const TransferDrafts: TranslatedComponent<TransferDraftsProps> = ({ isVisible, draftsIds, handleCloseModal, trls }) => {
    const dispatch = useDispatch();
    const { dispatchCheckDraft } = useContext(CheckContext);
    const currentManagerId = useSelector((state) => parseInt(state.employerManager?.id || '', 10));
    const managersList = useSelector((state) => state.managersList);
    const groupId = useSelectorNonNullable(
        ({ managerVacanciesActiveFilteredGroupId }) => managerVacanciesActiveFilteredGroupId
    );
    const managerVacanciesFilteredGroups = useSelectorNonNullable((state) => state.managerVacanciesFilteredGroups);

    const currentGroupManagersIdsSet = useMemo(
        () => getFilteredGroupManagerIdsSet(managerVacanciesFilteredGroups, groupId),
        [managerVacanciesFilteredGroups, groupId]
    );
    const managersTree = useMemo(
        () => getManagersListTreeCollection(managersList, currentGroupManagersIdsSet),
        [managersList, currentGroupManagersIdsSet]
    );
    const { addNotification } = useNotification();

    const [isError, setError] = useState(false);
    const [selected, setManager] = useState('');

    const draftsCount = draftsIds?.length || 1;

    const handleSubmit = useCallback(async () => {
        try {
            await dispatch(
                moveDrafts(
                    {
                        draftsIds,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        managerToTransfer: managersTree.getModel(selected)?.additional!,
                        currentManagerId,
                    },
                    addNotification
                )
            );
        } catch (_) {
            setError(true);
            return;
        }
        setError(false);
        handleCloseModal();
        dispatchCheckDraft({ type: UNCHECK_ALL });
        void fetchAndUpdateVacanciesCount(dispatch);
        void dispatch(fetchFilteredGroupsInfo(groupId, 'draft'));
    }, [
        handleCloseModal,
        dispatchCheckDraft,
        dispatch,
        draftsIds,
        managersTree,
        selected,
        currentManagerId,
        addNotification,
        groupId,
    ]);
    const popupTrl = useMemo(
        () => ({
            submit: draftsCount === 1 ? trls[TrlKeys.transferSubmitOne] : trls[TrlKeys.transferSubmit],
            cancel: trls[TrlKeys.cancel],
        }),
        [trls, draftsCount]
    );

    return (
        <TreeSelectorPopup
            visible={isVisible}
            selected={[selected]}
            trl={popupTrl}
            onClose={handleCloseModal}
            error={isError ? trls[TrlKeys.error] : ''}
            onSubmit={handleSubmit}
            disabledSubmit={!selected}
            hideOnSubmitClick={false}
            title={
                <ModalTitle>
                    {trls[TrlKeys.titlePrefix]}
                    {NON_BREAKING_SPACE}
                    <ConversionNumber
                        value={draftsCount}
                        one={trls[TrlKeys.transferSome]}
                        some={trls[TrlKeys.transferMany]}
                        many={trls[TrlKeys.transferMany]}
                    />
                </ModalTitle>
            }
        >
            <TreeSelectorDummy singleChoice onChange={(id) => setManager(id)} collection={managersTree} />
        </TreeSelectorPopup>
    );
};

export default translation(TransferDrafts);
