import { Dispatch, FC, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { useNotification } from 'src/components/Notifications/Provider';
import { AfterSuccessAction } from 'src/components/VacancyModals/Prolongation';
import { ADD_MODAL, MODAL_PROLONGATE } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { setRiseErrorStatus, resetRiseValues } from 'src/models/employerVacanciesRise';

import FreeRenewalTip from 'src/components/VacancyActions/FreeRenewalTip';
import vacancyActionErrorNotification from 'src/components/VacancyActions/VacancyActionError';
import fetchProlongateInfo from 'src/components/VacancyActions/fetchProlongateInfo';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import { validateResponse } from 'src/components/VacancyActions/risePageUtils';
import { useRiseAdvice } from 'src/components/VacancyActions/useRiseAdvice';

interface ProlongateActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    afterSuccessAction: AfterSuccessAction;
    dispatchModal: Dispatch<AnyAction>;
    onClose?: VoidFunction;
    displayInOwnColumn?: boolean;
    vacanciesIds: number[];
    freeRenewalParameters?: {
        lastActivationTimeIso: string;
        intervalMinutes: string;
    };
    onClickCallback?: () => void;
}

const TrlKeys = {
    prolongate: 'vacancy.action.renewal',
};

const ProlongateAction: TranslatedComponent<ProlongateActionProps> = ({
    actionType,
    additionalAnalyticsParams,
    afterSuccessAction,
    Component,
    dispatchModal,
    displayInOwnColumn,
    freeRenewalParameters,
    icon,
    onClose,
    triggerType,
    trls,
    vacanciesIds,
    onClickCallback,
}) => {
    const isZpPlatform = useIsZarplataPlatform();

    const permissions = useSelector(({ permissions }) => permissions);
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const activatorRef = useRef(null);

    const breakpoint = useBreakpoint();
    const canShowHoverTip = [Breakpoint.M, Breakpoint.L].includes(breakpoint);

    const { lastActivationTimeIso, intervalMinutes } = freeRenewalParameters || {
        lastActivationTimeIso: null,
        intervalMinutes: null,
    };
    const isFreeRenewalAvailable = lastActivationTimeIso && intervalMinutes;

    const openModal = useCallback(
        (showRegularButton: boolean) => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_PROLONGATE,
                    data: {
                        afterSuccessAction,
                        freeRenewalParameters: {
                            lastActivationTimeIso,
                            intervalMinutes,
                        },
                        showRegularButton,
                        vacanciesIds,
                        additionalAnalyticsParams,
                    },
                },
            });
            onClose && onClose();
        },
        [
            dispatchModal,
            afterSuccessAction,
            lastActivationTimeIso,
            intervalMinutes,
            vacanciesIds,
            additionalAnalyticsParams,
            onClose,
        ]
    );

    const loadProlongateInfo = useCallback(() => {
        setLoading(true);

        // Если выбрать сначала вакансию с ошибкой, не закрывать снекбар, а затем сразу открыть вакансию без ошибки,
        // то без этой строчки будет открыта и модалка, и снекбар
        dispatch(resetRiseValues());

        return dispatch(fetchProlongateInfo({ vacanciesIds })).then(
            (prolongateInfo) => {
                setLoading(false);

                const error = validateResponse(trls, prolongateInfo.unavailable, vacanciesIds);
                if (error) {
                    dispatch(setRiseErrorStatus({ message: error }));
                    return;
                }

                openModal(!isZpPlatform);
            },
            () => {
                addNotification(vacancyActionErrorNotification);
                setLoading(false);
            }
        );
    }, [dispatch, vacanciesIds, trls, isZpPlatform, openModal, addNotification]);

    const onTrigger = (isFromRunAction?: boolean) => {
        const currentSearchParams = new URLSearchParams(location.search);
        // если isFromAdvice будет true, то пришли сюда из редиректа совета,
        // нота(которая приходит в store.isVacancyAdvicePlaceAllowed)
        // говорит покажи совет в модалке, но
        // совета показать мы не может т.к флаг isAllowedRiseAdvice не разрешает,
        // и если ничего не сделать и никак не обработать, то будет показана модалка на поднять,
        // но без совета, поэтому не реагируем на такое
        const isFromAdvice = currentSearchParams.get('advice') === 'true' && isFromRunAction;
        if (isFromAdvice) {
            return undefined;
        }

        onClickCallback?.();
        return loadProlongateInfo();
    };

    const { isAllowed: isAllowedRiseAdvice, resetTrigger } = useRiseAdvice();

    // будем вызывать этот колбэк а не `onTrigger` каждый раз когда разрешен показ совета в ноте
    const onTriggerAdvice = () => {
        resetTrigger();
        onClickCallback?.();
        return loadProlongateInfo();
    };

    return (
        <Component
            actionType={actionType}
            additionalAnalyticsParams={additionalAnalyticsParams}
            batchLength={vacanciesIds.length}
            displayInOwnColumn={displayInOwnColumn}
            icon={icon}
            isLoading={isLoading}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            onTrigger={isAllowedRiseAdvice ? onTriggerAdvice : onTrigger}
            triggerName="prolongate"
            triggerType={triggerType}
        >
            {isFreeRenewalAvailable && canShowHoverTip ? (
                <HoverTip
                    placement={TipPlacement.Bottom}
                    render={() => (
                        <FreeRenewalTip lastFreeRenewal={lastActivationTimeIso} freeRenewalInterval={intervalMinutes} />
                    )}
                    host={!process.env.SSR ? document.body : null}
                    activatorRef={activatorRef}
                >
                    <span ref={activatorRef}>{trls[TrlKeys.prolongate]}</span>
                </HoverTip>
            ) : (
                trls[TrlKeys.prolongate]
            )}
        </Component>
    );
};

export default translation(ProlongateAction);
