import { RefObject } from 'react';
import classnames from 'classnames';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { HeartOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind, ButtonAppearance, ButtonType } from 'bloko/blocks/button';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { FormSpacer } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ResponseStatus from 'src/components/VacancyView/ResponseStatus';
import VacancyActionBlacklist from 'src/components/VacancyView/VacancyActionBlacklist';
import VacancyActionChat from 'src/components/VacancyView/VacancyActionChat';
import VacancyActionFavorite from 'src/components/VacancyView/VacancyActionFavorite';
import VacancyActionResponse, { VacancyActionResponseKind } from 'src/components/VacancyView/VacancyActionResponse';
import VacancyActionResponseAgain from 'src/components/VacancyView/VacancyActionResponseAgain';
import VacancyActionShowContacts from 'src/components/VacancyView/VacancyActionShowContacts';

const TrlKeys = {
    response: 'button.placeResponse',
    showContacts: 'vacancy.action.showContacts',
};

interface VacancyActionsProps {
    contactsNodeRef?: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    resumeFormNodeRef?: RefObject<HTMLDivElement>;
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    hasWrapper?: boolean;
    hasQuestion?: boolean;
}

const VacancyActions: TranslatedComponent<VacancyActionsProps> = ({
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    resumeFormNodeRef,
    constructorBrandedVacancy,
    isRedesign,
    isMagritteVacancy,
    hasWrapper = true,
    hasQuestion,
    trls,
}) => {
    const status = useSelector((state) => state.vacancyView.status);
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const userType = useSelector((state) => state.userType);
    const contactInfo = useSelector((state) => state.vacancyView.contactInfo);
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const isAnonymousOnXs = userType === UserType.Anonymous && isXs;
    const showContactsButton = Boolean(contactInfo) && !isAnonymousOnXs;
    const isHidden =
        !isBrandingPreview && (userType === UserType.Employer || userType === UserType.BackOffice || status.archived);

    if (isHidden) {
        return null;
    }

    const content =
        isBrandingPreview && !isRedesign ? (
            <Gap bottom>
                <div className="template-preview-actions">
                    <div className="template-preview-actions__response">
                        <Button type={ButtonType.Button} kind={ButtonKind.Success} stretched>
                            {trls[TrlKeys.response]}
                        </Button>
                    </div>
                    <div className="template-preview-actions__contacts">
                        <Button
                            type={ButtonType.Button}
                            kind={constructorBrandedVacancy ? ButtonKind.Inversed : undefined}
                            appearance={ButtonAppearance.Outlined}
                            stretched
                        >
                            {trls[TrlKeys.showContacts]}
                        </Button>
                    </div>
                    <FormSpacer>
                        <Button
                            kind={constructorBrandedVacancy ? ButtonKind.Inversed : undefined}
                            appearance={ButtonAppearance.Outlined}
                            type={ButtonType.Button}
                            icon={
                                <BlokoIconReplaceContainer>
                                    <HeartOutlinedSize16
                                        initial={constructorBrandedVacancy ? 'contrast' : 'secondary'}
                                    />
                                </BlokoIconReplaceContainer>
                            }
                        />
                    </FormSpacer>
                </div>
            </Gap>
        ) : (
            <VSpacingContainer default={isMagritteVacancy ? 12 : 24}>
                {!constructorBrandedVacancy && <ResponseStatus isMagritteVacancy={isMagritteVacancy} />}
                <div className="noprint">
                    <div
                        className={classnames('vacancy-actions', 'vacancy-actions_applicant', {
                            'vacancy-actions_magritte-vacancy-top': isMagritteVacancy,
                        })}
                    >
                        {isMagritteVacancy ? <VacancyActionResponseAgain /> : null}
                        <VacancyActionResponse
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            resumeFormNodeRef={resumeFormNodeRef}
                            hasForceQuickResponse
                            isRedesign={isRedesign}
                            isMagritteVacancy={isMagritteVacancy}
                            forceFullSize={isXs && !showContactsButton && hasQuestion}
                            kind={VacancyActionResponseKind.Main}
                        />
                        <VacancyActionShowContacts
                            contactsNodeRef={contactsNodeRef}
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            constructorBrandedVacancy={constructorBrandedVacancy}
                            isRedesign={isRedesign}
                            isMagritteVacancy={isMagritteVacancy}
                        />
                        {hasQuestion && (
                            <VacancyActionChat
                                stretched={isXs && !showContactsButton}
                                isRedesign={isRedesign}
                                isMagritteVacancy={isMagritteVacancy}
                            />
                        )}
                        <VacancyActionFavorite
                            constructorBrandedVacancy={constructorBrandedVacancy}
                            isRedesign={isRedesign}
                            isMagritteVacancy={isMagritteVacancy}
                        />
                        <VacancyActionBlacklist isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} />
                    </div>
                </div>
            </VSpacingContainer>
        );

    if (!hasWrapper) {
        return <>{content}</>;
    }

    return isRedesign ? (
        content
    ) : (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                {content}
                <VSpacing base={8} />
            </Column>
        </ColumnsRow>
    );
};

export default translation(VacancyActions);
