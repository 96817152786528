import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import useEnableCompensationWithModeAndFrequency from 'src/hooks/useEnableCompensationWithModeAndFrequency';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

interface UseVacancyViewMetaParams {
    trls: {
        pageTitle: string;
        jobIn: string;
        archived: string;
        archivedFrom: string;
        inCompany: string;
        titleCompensation: string;
        noCompensation: string;
        compensationFrom: string;
        compensationTo: string;
        workExperience: string;
        publicationDate: string;
        month: Record<string, string>;
        currencies: Record<string, string>;
        zp: {
            title: string;
            ogTitle: string;
            description: string;
            ogDescription: string;
        };
    };
}

interface UseVacancyMetaResult {
    title: string;
    ogTitle?: string;
    description: string;
    ogDescription: string;
}

const useVacancyViewMeta = ({ trls }: UseVacancyViewMetaParams): UseVacancyMetaResult => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const employmentFieldsDictionary = useSelector((state) => state.vacancyEmploymentFieldsDictionary);
    const compensationFieldsDictionary = useSelector((state) => state.vacancyCompensationFieldsDictionary);

    const newEmploymentFieldsEnabled = useSelector((state) => state.vacancyNewEmploymentFieldsEnabled);
    const newCompensationFieldsEnabled = useEnableCompensationWithModeAndFrequency();

    const vacancyTranslations = vacancyView.translations as Record<string, string>;
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        const areaCatalogTitle = vacancyView.area?.areaCatalogTitle || '';
        const title = format(trls.zp.title, {
            '{vacancyName}': vacancyView.name,
            '{companyName}': vacancyView.company.visibleName,
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const ogTitle = format(trls.zp.ogTitle, {
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const description = format(trls.zp.description, {
            '{vacancyName}': vacancyView.name,
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const ogDescription = format(trls.zp.ogDescription, {
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        return {
            title,
            ogTitle,
            description,
            ogDescription,
        };
    }

    // title part
    let archivedVacancyTitlePart = '';
    if (vacancyView?.status?.archived) {
        if (vacancyView.validThroughTime) {
            const dateObject = new Date(vacancyView.validThroughTime);

            archivedVacancyTitlePart = ` (${trls.archivedFrom} ${dateObject.getDate()} ${
                trls.month[dateObject.getMonth()]
            } ${dateObject.getFullYear()})`;
        } else {
            archivedVacancyTitlePart = ` ${trls.archived}`;
        }
    }

    const areaCatalogTitlePart = vacancyView?.area?.areaCatalogTitle ? ` ${vacancyView.area.areaCatalogTitle}` : '';
    const title = `${trls.pageTitle} ${vacancyView.name}${areaCatalogTitlePart}, ${format(trls.jobIn, {
        '{0}': vacancyView.company.visibleName,
    })}${archivedVacancyTitlePart}`;

    // description part
    let employmentPart = '';
    if (newEmploymentFieldsEnabled) {
        const employmentFormText = employmentFieldsDictionary.employmentForm.find(
            (i) => i.id === vacancyView.employmentForm
        )?.text;

        if (employmentFormText) {
            employmentPart = ` ${employmentFormText}.`;
        }
    } else {
        employmentPart = ` ${vacancyTranslations.employment}.`;
    }
    let compensationPart = '';
    if (vacancyView.compensation.noCompensation) {
        compensationPart = trls.noCompensation;
    }
    if (vacancyView.compensation.perModeFrom) {
        compensationPart = `${trls.compensationFrom} ${vacancyView.compensation.perModeFrom} `;
    }
    if (vacancyView.compensation.perModeTo) {
        compensationPart += `${trls.compensationTo} ${vacancyView.compensation.perModeTo} `;
    }
    if (vacancyView.compensation.currencyCode) {
        compensationPart += trls.currencies[vacancyView.compensation.currencyCode].replace('.', '');
    }
    if (newCompensationFieldsEnabled) {
        const compensationModeText = compensationFieldsDictionary.mode
            .find((i) => i.id === vacancyView.compensation.mode)
            ?.text.toLowerCase();

        if (compensationModeText) {
            compensationPart += ` ${compensationModeText}`;
        }
    }
    const areaPart = vacancyView.area?.name ? ` ${vacancyView.area.name}.` : '';
    const workExperiencePart = ` ${trls.workExperience}: ${vacancyTranslations.workExperience}.`;
    const publicationDatePart = vacancyView?.publicationDate
        ? ` ${trls.publicationDate}: ${formatDate(vacancyView.publicationDate, 'dd.MM.yyyy')}.`
        : '';

    const description =
        `${trls.pageTitle} ${vacancyView.name} ${trls.inCompany} ${vacancyView.company.visibleName}. ` +
        `${trls.titleCompensation}: ${compensationPart}.${areaPart}${workExperiencePart}` +
        `${employmentPart}${publicationDatePart}`;

    const ogDescription =
        `${trls.titleCompensation}: ${compensationPart}.${areaPart}${workExperiencePart}` +
        `${employmentPart}${publicationDatePart}`;

    return { title, description, ogDescription };
};

export default useVacancyViewMeta;
