import { useEffect, useMemo, useState } from 'react';

import { EmploymentForm } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FieldTip from 'src/components/FieldTip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmploymentFieldsValueCounting from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/Counting';
import TextWrapper from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/TextWrapper';
import { isOtherValue } from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/utils';

const TrlKeys = {
    employmentFormPrefix: 'vacancy.view.employment.employmentFormPrefix',
    internship: 'vacancy.view.employment.internship',
    acceptTemporary: 'vacancy.view.employment.temporary',
    acceptTemporaryInfo: 'vacancy.view.employment.temporaryInfo',
};

interface CommonEmploymentTextProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const CommonEmploymentText: TranslatedComponent<CommonEmploymentTextProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const [isRendered, setIsRendered] = useState(false);

    const query = useSelector((state) => state.router.location.query);
    const employmentFieldsDictionary = useSelector((state) => state.vacancyEmploymentFieldsDictionary);

    const employmentForm = useSelector((state) => state.vacancyView.employmentForm);
    const flyInFlyOutDurations = useSelector((state) => state.vacancyView.flyInFlyOutDurations);
    const internship = useSelector((state) => state.vacancyView.internship);
    const acceptTemporary = useSelector((state) => state.vacancyView.acceptTemporary);

    const employmentFormText = useMemo(() => {
        let text = employmentFieldsDictionary.employmentForm.find((form) => form.id === employmentForm)?.text;

        if (employmentForm === EmploymentForm.Full || employmentForm === EmploymentForm.Part) {
            text = `${text} ${trls[TrlKeys.employmentFormPrefix]}`;
        }

        return text;
    }, [employmentForm, employmentFieldsDictionary.employmentForm, trls]);

    const isPreview = Boolean(query.doPreview);
    const areFlyInFlyOutDurationsEmpty =
        flyInFlyOutDurations.length === 0 ||
        (flyInFlyOutDurations.length === 1 && isOtherValue(flyInFlyOutDurations[0]));

    useEffect(() => {
        setIsRendered(true);
    }, []);

    return (
        <TextWrapper
            key={`${isRendered}`}
            isRedesign={isRedesign}
            isMagritteVacancy={isMagritteVacancy}
            isMobile={isMobile}
            isDotted
            dataQa="common-employment-text"
        >
            <>
                {employmentFormText}

                {employmentForm === EmploymentForm.FlyInFlyOut && !areFlyInFlyOutDurationsEmpty && (
                    <>
                        {' '}
                        <EmploymentFieldsValueCounting
                            field="flyInFlyOutDuration"
                            dictionary={employmentFieldsDictionary.flyInFlyOutDurations}
                            values={flyInFlyOutDurations}
                            isMagritteVacancy={isMagritteVacancy}
                        />
                    </>
                )}
            </>

            {internship && trls[TrlKeys.internship]}

            {acceptTemporary &&
                (!isPreview && isRendered ? (
                    <FieldTip
                        fieldName={trls[TrlKeys.acceptTemporary]}
                        tipContent={trls[TrlKeys.acceptTemporaryInfo]}
                        withoutVertPadding
                    >
                        {trls[TrlKeys.acceptTemporary]}
                    </FieldTip>
                ) : (
                    trls[TrlKeys.acceptTemporary]
                ))}
        </TextWrapper>
    );
};

export default translation(CommonEmploymentText);
