import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { QS_PARAM_TO_OPEN_AUCTION, QS_PARAM_TO_OPEN_AUCTION_BUDGET_REPLENISH } from 'src/components/Auction/utils';
import { useSelector } from 'src/hooks/useSelector';
import { AuctionHhtmlSourceLabel, setAuctionHhtmlSourceLabel } from 'src/models/auctionHhtmSourceLabel';

const useHhtmSourceLabelFromRunActionQs = (): AuctionHhtmlSourceLabel => {
    const dispatch = useDispatch();
    const hhtmSourceLabelFromRunActionQs = useSelector(
        (state) =>
            [QS_PARAM_TO_OPEN_AUCTION, QS_PARAM_TO_OPEN_AUCTION_BUDGET_REPLENISH].find((param) => {
                return state.runActionArgument === param;
            }) as AuctionHhtmlSourceLabel
    );
    const auctionHhtmSourceLabel = useSelector((state) => state.auctionHhtmSourceLabel);

    const [hhtmSourceLabel, setHhtmSourceLabel] = useState<AuctionHhtmlSourceLabel>(null);

    useEffect(() => {
        // hhtmSourceLabel, будет иметь значение только в том случае если пользователь открыл аукцион по ссылке
        if (hhtmSourceLabelFromRunActionQs && !auctionHhtmSourceLabel) {
            dispatch(setAuctionHhtmlSourceLabel(hhtmSourceLabelFromRunActionQs));
            setHhtmSourceLabel(hhtmSourceLabelFromRunActionQs);
        } else {
            setHhtmSourceLabel(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return hhtmSourceLabel;
};

export default useHhtmSourceLabelFromRunActionQs;
