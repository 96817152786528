import { FC, RefObject } from 'react';
import classnames from 'classnames';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { AppStore } from 'src/app/store';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import { checkWidgetRenderForVacancyView as checkEmployerReviewsWidgetRender } from 'src/components/EmployerReviews/WidgetBig/checkRender';
import useMagritteResponseExp from 'src/hooks/useMagritteResponseExp';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import VacancyAdaptiveBanners from 'src/components/VacancyView/VacancyAdaptiveBanners';
import VacancyAnonymousResponse from 'src/components/VacancyView/VacancyAnonymousResponse';
import VacancyApplicantRespondedInfo from 'src/components/VacancyView/VacancyApplicantRespondedInfo';
import VacancyApplicantResponse from 'src/components/VacancyView/VacancyApplicantResponse';
import VacancyCreationTime from 'src/components/VacancyView/VacancyCreationTime';

interface VacancyFooterProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
    withBanners?: boolean;
}
export const checkVacancyFooterRender = ({ userType }: AppStore): boolean =>
    [UserType.Anonymous, UserType.Applicant, UserType.NedoUser].includes(userType);

const VacancyFooter: FC<VacancyFooterProps> = ({ vacancyBodyFooterNodeRef, isRedesign, withBanners }) => {
    const showReviewWidget = useSelector(checkEmployerReviewsWidgetRender);
    const showVacancyFooter = useSelector(checkVacancyFooterRender);
    const { isMagritteResponseExpAny } = useMagritteResponseExp();
    const hasApplicantBanner = useSelector((state) => !!state.microFrontends.careerBannerVacancy);
    const hasAnonymousBanner = useSelector((state) => !!state.microFrontends.careerVacancyBannerAnonymous);

    if (!showVacancyFooter) {
        return null;
    }

    return (
        <div className={classnames({ 'vacancy-section': !isRedesign })}>
            <ColumnsRow>
                <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l="10" container>
                    <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l="8">
                        <Gap bottom>
                            <VacancyCreationTime isRedesign={isRedesign} />
                        </Gap>
                    </Column>
                    <VacancyAnonymousResponse isRedesign={isRedesign} />
                </Column>
                <VacancyApplicantResponse vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} isRedesign={isRedesign} />

                {showReviewWidget && (
                    <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l="10">
                        <VSpacing base={isRedesign ? 12 : 6} xs={isRedesign ? 1 : 6} />
                        <Text>
                            <EmployerReviewsWidgetBig isRedesign={isRedesign} />
                        </Text>
                    </Column>
                )}
                {withBanners && (
                    <Column xs="4" s="8" m="12" l="0" container>
                        <VacancyAdaptiveBanners />
                    </Column>
                )}
                <VacancyApplicantRespondedInfo
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    isRedesign={isRedesign}
                    withWrapper
                    isMagritteExp={isMagritteResponseExpAny}
                />
                {(hasApplicantBanner || hasAnonymousBanner) && (
                    <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l="10">
                        <VSpacing base={10} />
                        {hasApplicantBanner && <ContainerForMicroFrontend place={'careerBannerVacancy'} />}
                        {hasAnonymousBanner && <ContainerForMicroFrontend place={'careerVacancyBannerAnonymous'} />}
                    </Column>
                )}
                <VSpacing base={8} />
            </ColumnsRow>
        </div>
    );
};

export default VacancyFooter;
