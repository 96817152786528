import {
    CompensationMode,
    CompensationFrequency,
    EmploymentForm,
    FlyInFlyOutDuration,
    WorkExperience,
    WorkScheduleByDays,
    WorkFormat,
    WorkingHours,
} from '@hh.ru/types-hh-microcore';

import { BrandSnippet } from 'src/models/brandSnippets';
import { BrandedTemplate } from 'src/models/brandedVacancyTemplates';
import { CurrencyType } from 'src/models/currencies.types';
import DriverLicense from 'src/models/driverLicense.types';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import { EmployerDepartment } from 'src/models/employerDepartments';
import { EmployerInsider } from 'src/models/employerInsiders';
import { HiringPlanType } from 'src/models/employerVacancies/vacancyHiringPlans';
import ContactInfo from 'src/models/vacancy/contactInfo.types';
import { PublicationVacancyProperties } from 'src/models/vacancyProperties';

import { AvailablePublicationType } from 'src/models/vacancyCreate/availablePublicationTypes';

interface EmployerTest {
    id: string;
    name: string;
}

export interface Address {
    addressId: number;
    showMetroOnly: boolean;
}

export interface VacancyTest {
    userTestId: number;
}

export interface Publication {
    auction?: {
        bid: number;
        budget: number;
        checked: boolean;
    };
    clickmeProducts: {
        autoCampaignChecked: boolean;
    };
    hiddenFromSearch: boolean;
    employerServiceId: string;
    withZpCrossPost?: boolean;
    advertising?: boolean;
    anonymous?: boolean;
    publicationVacancyProperties: PublicationVacancyProperties;
}

export enum ChatWritePossibility {
    Disabled = 'DISABLED',
    EnabledAfterInvitation = 'ENABLED_AFTER_INVITATION',
}

export interface IVacancyCreateFormValues {
    acceptHandicapped: boolean;
    acceptIncompleteResumes: boolean;
    acceptTemporary: boolean;
    acceptKids: boolean;
    address?: Address;
    vacancyName: string;
    professionalRoleIds: string[];
    areaIds: number[];
    hiringPlan?: HiringPlanType;
    employmentForm: EmploymentForm;
    compensation: {
        compensationFrom?: number;
        compensationTo?: number | null;
        compensationPerModeFrom?: number;
        compensationPerModeTo?: number;
        currencyCode: CurrencyType;
        compensationGross: boolean;
        compensationMode?: CompensationMode;
        compensationFrequency?: CompensationFrequency;
    };
    flyInFlyOutDuration: FlyInFlyOutDuration;
    contactInfo: ContactInfo;
    departmentCode: string;
    driverLicenseTypes: DriverLicense[];
    description: string;
    brandedTemplate: string | null;
    brandSnippetId: string | null;
    brandSnippetExpirationTime: string | null;
    keySkills: string[];
    responseLetterRequired: boolean;
    chatWritePossibility: ChatWritePossibility;
    employerManagerId: string;
    responseNotifications: boolean;
    publicationTime: string;
    scheduledPublicationDateTimeIso: string;
    publication: Publication;
    test?: VacancyTest;
    vacancyId?: number;
    skillsSurveyProfession?: string | null;
    showSkillsSurvey?: boolean | null;
    assignedEmployerManagerId?: string | null;
    timeStart: string;
    timeEnd: string;
    nightShifts: boolean;
    internship: boolean;
    workExperience: WorkExperience;
    workScheduleByDays: WorkScheduleByDays;
    workFormat: WorkFormat;
    workingHours: WorkingHours;
}

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type VacancyCreateFormValues = Nullable<IVacancyCreateFormValues>;

export interface AdditionFormStateValues {
    employerInsiders: EmployerInsider[];
    employerAddresses: EmployerAddress[];
    employerDepartments: EmployerDepartment[];
    employerTests: EmployerTest[];
    brandSnippets: BrandSnippet[];
    brandedVacancyTemplates: BrandedTemplate[];
    selectedPublicationTypeData: AvailablePublicationType | null;
    vacancyCreateInitialBody: Partial<VacancyCreateFormValues>;
}

export type VacancyCreateTemplateId = number | null;

export interface VacancyCreateTemplate {
    id: VacancyCreateTemplateId;
    name: string;
    shared: boolean;
}
