import Analytics from '@hh.ru/analytics-js';
import { Button, Card, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { SalaryRange, CompensationMode } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import SalaryAdviceTooltip from 'src/components/VacancyModals/VacancyAdviceModal/SalaryAdviceTooltip';
import { SALARY_VACANCY_ADVICES } from 'src/components/VacancyModals/VacancyAdviceModal/constants';
import { getLinkUrl } from 'src/components/VacancyModals/VacancyAdviceModal/utils';
import SalaryMarketRangeChart from 'src/components/VacancyView/SalaryMarketRangeChart';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySalaryAdvice } from 'src/models/vacancy/vacancyAdvices.types';

import SalaryRangeCardHeading from 'src/pages/VacancyView/components/VacancyStatTab/SalaryRangeCard/SalaryRangeCardHeading';
import { NO_SALARY_ADVICE } from 'src/pages/VacancyView/components/VacancyStatTab/SalaryRangeCard/constants';

const TrlKeys = {
    link: 'employer.vacancy.tab.stat.salaryChart.link.text',
};

const SalaryRangeCard: TranslatedComponent = ({ trls }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const canvacData = useSelector((state) => state.canvacVacancySalaryData);
    const compensation = useSelector((state) => state.vacancyView.compensation);
    const advices = useSelector((state) => state.vacancyAdvices);
    const advice =
        advices.find((item): item is VacancySalaryAdvice => SALARY_VACANCY_ADVICES.includes(item)) ?? NO_SALARY_ADVICE;

    const salaryChartRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_salary_chart',
        vacancyId,
    });

    if (
        !canvacData?.salaryRange ||
        canvacData?.salaryRange === SalaryRange.Undefined ||
        !vacancyId ||
        !compensation ||
        // temp_start-PORTFOLIO-37087 Канвак не умеет не в месячную зарплату
        compensation.mode !== CompensationMode.Month
        // temp_end-PORTFOLIO-37087
    ) {
        return null;
    }

    const hasSalaryAdvice = advice !== NO_SALARY_ADVICE;
    const { marketRange, salaryRange } = canvacData;

    const onChartClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_salary_chart', { vacancyId });
    };

    const onButtonClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_salary_chart_link', { vacancyId });
    };

    return (
        <Card
            id={ANCHOR_TO_SECTION.SalaryRangeCard}
            stretched
            showBorder
            padding={12}
            borderRadius={24}
            ref={salaryChartRef}
        >
            <Card stretched padding={12}>
                <SalaryRangeCardHeading advice={advice} marketRange={marketRange} salaryRange={salaryRange} />
                <VSpacing default={24} xs={20} />
                <SalaryMarketRangeChart
                    compensation={compensation}
                    marketRange={marketRange}
                    salaryRange={salaryRange}
                    infoComponent={
                        <SalaryAdviceTooltip
                            vacancyId={vacancyId}
                            analyticEventName="vacancy_view_stat_tab_salary_chart_tooltip"
                        />
                    }
                    overrideColor={!hasSalaryAdvice}
                    onChartClick={onChartClick}
                />
            </Card>
            {hasSalaryAdvice && (
                <>
                    <VSpacing default={12} />
                    <Button
                        stretched
                        Element={Link}
                        target="_blank"
                        to={getLinkUrl(advice, vacancyId)}
                        mode="secondary"
                        style="accent"
                        onClick={onButtonClick}
                        data-qa="tab-stat-edit-salary"
                    >
                        {trls[TrlKeys.link]}
                    </Button>
                </>
            )}
        </Card>
    );
};

export default translation(SalaryRangeCard);
