import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionCampaignStatus, AuctionFieldName } from 'src/models/auctionData';
import { NARROW_NON_BREAKING_SPACE, NON_BREAKING_SPACE, REGULAR_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const getClickRemainder = (bid: number, balance: number) => {
    return bid ? Math.floor(balance / bid) : 0;
};

const TrlKeys = {
    title: 'auction.budget.title',
    outOf: 'outOf',
    enoughFor: 'auction.budget.enoughFor',
    click: {
        one: 'click.one',
        some: 'click.some',
        many: 'click.many',
    },
    hint: {
        [AuctionCampaignStatus.CampaignLowBalance]: 'auction.budget.campaignLowBalance',
        [AuctionCampaignStatus.AccountLowBalance]: 'auction.budget.accountLowBalance',
    },
};

interface BudgetBannerProps {
    button?: JSX.Element;
}

const BudgetBanner: TranslatedComponent<BudgetBannerProps> = ({ trls, button }) => {
    const currentCampaign = useSelectorNonNullable(({ auctionData }) => auctionData.currentVacancyAuctionCampaign);
    const bid = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid].value || '');
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const accountBalance = useSelectorNonNullable(({ auctionData }) => auctionData.accountBalance);

    if (!currentCampaign) {
        return null;
    }

    const bidRemainder = getClickRemainder(Number(bid), currentCampaign.campaignBalance);

    return (
        <Card actionCard={false} borderRadius={24} data-qa="auction-budget-banner" padding={24} showBorder stretched>
            <div className={styles.row}>
                <div>
                    <Text style="secondary" typography="label-2-regular">
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing default={4} />
                    <div className={styles.budgetAmount}>
                        <Text Element="span" typography="title-4-semibold">
                            <FormatMoney
                                currency={currency}
                                groupSeparator={NARROW_NON_BREAKING_SPACE}
                                inCents
                                render={(amount, currencyNode) => (
                                    <>
                                        <span data-qa="auction-budget-remainder">{amount}</span>
                                        {NON_BREAKING_SPACE}
                                        {currencyNode}
                                    </>
                                )}
                            >
                                {currentCampaign.campaignBalance}
                            </FormatMoney>
                        </Text>
                        {REGULAR_SPACE}
                        <Text Element="span" style="secondary" typography="subtitle-1-semibold">
                            {trls[TrlKeys.outOf]}
                            {NON_BREAKING_SPACE}
                            <FormatMoney currency={currency} groupSeparator={NARROW_NON_BREAKING_SPACE} inCents>
                                {currentCampaign.budget}
                            </FormatMoney>
                        </Text>
                    </div>
                </div>
                {button}
            </div>
            <VSpacing default={12} />
            {currentCampaign.campaignStatus === AuctionCampaignStatus.CampaignLowBalance ||
            currentCampaign.campaignStatus === AuctionCampaignStatus.AccountLowBalance ? (
                <Text typography="label-2-regular">
                    <span className={styles.budgetStatusHint}>
                        {formatToReactComponent(trls[TrlKeys.hint[currentCampaign.campaignStatus]], {
                            '{0}': (
                                <FormatMoney currency={currency} inCents>
                                    {accountBalance}
                                </FormatMoney>
                            ),
                        })}
                    </span>
                </Text>
            ) : (
                <Text typography="label-2-regular">
                    {trls[TrlKeys.enoughFor]}
                    {NON_BREAKING_SPACE}
                    <Conversion
                        value={bidRemainder}
                        one={trls[TrlKeys.click.one]}
                        some={trls[TrlKeys.click.some]}
                        many={trls[TrlKeys.click.many]}
                        zero={`0 ${trls[TrlKeys.click.many]}`}
                    />
                </Text>
            )}
        </Card>
    );
};

export default translation(BudgetBanner);
