import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import auctionHowItWorksButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_how_it_works_button_click';
import auctionModalCreateCampaignButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_create_campaign_button_click';
import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    Action,
    Text,
    VSpacing,
    Loader,
    Card,
    Link,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import {
    createClickmeAuctionCampaignWithoutPurchase,
    createOrUpdateCartWithClickmeAuctionCampaignDraft,
} from 'src/api/price/cart';
import AuctionContentShown from 'src/components/Auction/Analytics/AuctionContentShown';
import AuctionControls from 'src/components/Auction/Common/AuctionControls/AuctionControls';
import BidRating from 'src/components/Auction/Common/BidRating/BidRating';
import FlexibleRow, { FlexibleRowAlignItems } from 'src/components/FlexibleRow';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import {
    AuctionFieldName,
    CampaignCreationStatus,
    CreatedAuctionData,
    defaultControlInfo,
    setCreatedAuctionData,
} from 'src/models/auctionData';
import { REGULAR_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.title',
    description: 'auction.description',
    draftTitle: 'auction.creation.draftTitle',
    draftDescription: 'auction.creation.draftDescription',
    draftAction: 'auction.creation.draftAction',
    draftLabel: 'auction.creation.draftLabel',
    howItWorksTrigger: 'auction.howItWorks.trigger',
    cancel: 'Cancel',
    purchase: 'employer.auction.price.purchase',
    create: 'employer.myvacancies.auctionModal.create',
};

interface AuctionCreationModalProps {
    hideModal: () => void;
    loading?: boolean;
    showHowItWorksModal: () => void;
    visible: boolean;
    onCampaignCreated?: (data: CreatedAuctionData) => void;
    isWebView: boolean;
}

const AuctionCreationModal: TranslatedComponent<AuctionCreationModalProps> = ({
    hideModal,
    loading,
    showHowItWorksModal,
    trls,
    visible,
    onCampaignCreated,
    isWebView = false,
}) => {
    const bidControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid] || defaultControlInfo
    );
    const budgetControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Budget] || defaultControlInfo
    );
    const isCampaignDraft = useSelector(
        ({ auctionData }) => auctionData?.campaignCreationStatus === CampaignCreationStatus.CampaignDraftCreated
    );
    const draftPurchaseUrl = useSelector(({ auctionData }) => auctionData?.campaignDraft?.purchaseUrl);
    const product = useSelector(({ auctionData }) => auctionData?.productInfo.data || null);
    const productLoading = useSelector(({ auctionData }) => auctionData?.productInfo.loading || false);
    const vacancyId = useSelector(({ auctionData }) => auctionData?.vacancyId || null);
    const campaignDraftId = useSelector(({ auctionData }) => auctionData?.campaignDraft?.campaignDraftId);
    const campaignCreationStatus = useSelector(({ auctionData }) => auctionData?.campaignCreationStatus);
    const maxBidAmongSimilarVacancies = useSelector(
        ({ auctionData }) => auctionData?.bidRating.data.maxBidAmongSimilarVacancies
    );
    const useAvailableMoney = useSelector(({ auctionData }) => auctionData?.useAvailableMoney);
    const availableMoney = useSelector(({ auctionData }) => auctionData?.availableMoney);
    const { addNotification } = useNotification();

    const { pathname, query } = useSelector(({ router }) => router.location);

    const dispatch = useDispatch();
    const push = usePush();

    const [disabled, setDisabledOn, setDisabledOff] = useOnOffState(false);

    const submitIsDisabled = loading || productLoading || (!useAvailableMoney && !product) || !vacancyId || disabled;

    const pushUrlOnCreateOrClose = useCallback(
        (created: boolean, hasError?: boolean, closed?: boolean) => {
            const url = urlParser(pathname);
            const { error, ...restQuery } = query;
            url.params = {
                ...restQuery,
                created,
                ...(hasError ? { error: true } : {}),
                ...(closed ? { closed: true } : {}),
            };
            push(url.href);
        },
        [pathname, push, query]
    );

    const createCampaign = useCallback(() => {
        if (submitIsDisabled || bidControlInfo.error || budgetControlInfo.error) {
            return;
        }
        setDisabledOn();
        const currentBid = Number(bidControlInfo.value);
        const currentBudget = Number(budgetControlInfo.value);
        const action = product
            ? createOrUpdateCartWithClickmeAuctionCampaignDraft(
                  [product],
                  {
                      httmFromLabel: 'clickmePopup',
                      source: 'clickmePopup',
                  },
                  vacancyId,
                  currentBid,
                  currentBudget,
                  addNotification,
                  push,
                  campaignDraftId
              )
            : createClickmeAuctionCampaignWithoutPurchase(
                  vacancyId,
                  currentBid,
                  currentBudget,
                  (data) => {
                      dispatch(setCreatedAuctionData(data));
                      onCampaignCreated?.(data);
                      if (isWebView) {
                          pushUrlOnCreateOrClose(true);
                      } else {
                          hideModal();
                      }
                  },
                  isWebView ? () => pushUrlOnCreateOrClose(false, true) : hideModal,
                  addNotification
              );
        action.catch(console.error).finally(setDisabledOff);
        auctionModalCreateCampaignButtonClick({
            vacancyId,
            campaignDraftId,
            campaignCreationStatus: campaignCreationStatus!,
            bidInCents: currentBid,
            budgetInCents: currentBudget,
            maxBidAmongSimilarVacancies: maxBidAmongSimilarVacancies!,
            useAvailableMoney: !!useAvailableMoney,
            availableMoneyInCents: availableMoney ?? 0,
        });
    }, [
        submitIsDisabled,
        bidControlInfo.error,
        bidControlInfo.value,
        budgetControlInfo.error,
        budgetControlInfo.value,
        setDisabledOn,
        product,
        vacancyId,
        addNotification,
        push,
        campaignDraftId,
        isWebView,
        hideModal,
        setDisabledOff,
        campaignCreationStatus,
        maxBidAmongSimilarVacancies,
        useAvailableMoney,
        availableMoney,
        dispatch,
        onCampaignCreated,
        pushUrlOnCreateOrClose,
    ]);

    const onHowItWorksClick = useCallback(() => {
        auctionHowItWorksButtonClick({
            vacancyId,
            campaignDraftId,
            campaignCreationStatus: campaignCreationStatus!,
            // Тут ещё не создана кампания
            campaignId: undefined,
            campaignStatus: undefined,
        });
        showHowItWorksModal();
    }, [campaignCreationStatus, campaignDraftId, showHowItWorksModal, vacancyId]);

    const onModalClose = isWebView ? () => pushUrlOnCreateOrClose(false, false, true) : hideModal;

    const modalContent = (
        <div>
            {isCampaignDraft && draftPurchaseUrl ? (
                <Card actionCard={false} borderRadius={24} padding={24} style="warning" stretched>
                    <FlexibleRow alignItems={FlexibleRowAlignItems.Start} disableOnXS>
                        <div>
                            <Text typography="title-5-semibold">{trls[TrlKeys.draftTitle]}</Text>
                            <VSpacing default={4} />
                            <Text typography="paragraph-2-regular">{trls[TrlKeys.draftDescription]}</Text>
                        </div>
                        <VSpacing default={20} gteS={0} />
                        <Button size="medium" style="neutral" mode="primary" Element="a" href={draftPurchaseUrl}>
                            {trls[TrlKeys.draftAction]}
                        </Button>
                    </FlexibleRow>
                </Card>
            ) : (
                <>
                    <Text>
                        {trls[TrlKeys.description]}
                        {REGULAR_SPACE}
                        <Link inline Element="button" onClick={onHowItWorksClick}>
                            {trls[TrlKeys.howItWorksTrigger]}
                        </Link>
                    </Text>
                </>
            )}
            <VSpacing default={24} />
            {loading ? (
                <div className={styles.centeringWrapper}>
                    <Loader size={24} />
                    <VSpacing default={24} />
                </div>
            ) : (
                <AuctionContentShown>
                    <BidRating />
                    <VSpacing default={24} />
                    <AuctionControls />
                    <VSpacing default={24} gteS={0} />
                </AuctionContentShown>
            )}
        </div>
    );

    const createAuctionButton = (
        <Button
            data-qa={useAvailableMoney && !!availableMoney ? 'create-auction-button' : 'purchase-auction-button'}
            disabled={submitIsDisabled}
            size="medium"
            style="accent"
            mode="primary"
            loading={loading || productLoading}
            onClick={createCampaign}
        >
            {useAvailableMoney && !!availableMoney ? trls[TrlKeys.create] : trls[TrlKeys.purchase]}
        </Button>
    );

    const cancelButton = !isWebView && (
        <Button size="medium" style="accent" mode="secondary" onClick={hideModal} data-qa="auction-cancel-button">
            {trls[TrlKeys.cancel]}
        </Button>
    );

    return (
        <>
            <Modal
                visible={visible}
                onClose={onModalClose}
                title={trls[TrlKeys.title]}
                actions={!isWebView && <Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                titleSize="medium"
                footer={
                    <ActionBar
                        primaryActions={
                            <>
                                {cancelButton}
                                {createAuctionButton}
                            </>
                        }
                    />
                }
                data-qa="auction-creation-modal"
            >
                {modalContent}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                header={
                    <NavigationBar
                        title={trls[TrlKeys.title]}
                        right={
                            !isWebView ? (
                                <Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />
                            ) : undefined
                        }
                    />
                }
                footer={
                    <BottomSheetFooter>
                        {createAuctionButton}
                        {cancelButton}
                    </BottomSheetFooter>
                }
                data-qa="auction-creation-bottom-sheet"
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(AuctionCreationModal);
