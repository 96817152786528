import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios, { Canceler } from 'axios';

import useDebounce from 'bloko/common/hooks/useDebounce';

import AuctionInput, { AuctionInputProps } from 'src/components/Auction/Common/AuctionControls/AuctionInput';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldError, BidRatingRequestParams } from 'src/models/auctionData';

import { fetchBidRating } from 'src/components/Auction/Common/AuctionControls/Bid/fetchBidRating';

const BID_STEP = 500;

interface BidProps extends Omit<AuctionInputProps, 'step'> {
    error?: AuctionFieldError;
    onPlusOrMinusClick?: (bid: string) => void;
    minusDataQa?: string;
    plusDataQa?: string;
    inversed?: boolean;
}

const Bid: FC<BidProps> = ({ error, value, onPlusOrMinusClick, inversed, ...props }) => {
    const areaIds = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.areaIds);
    const name = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.name);
    const professionalRoleIds = useSelectorNonNullable(
        ({ auctionData }) => auctionData.vacancyInfo.professionalRoleIds
    );
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId || null);
    const dispatch = useDispatch();

    const cancelFetchBidRatingRef = useRef<Canceler | null>(null);
    const fetchBidRatingDebouncedRef = useRef(
        useDebounce((params: BidRatingRequestParams & { bid: number; vacancyId: string | null }) => {
            const cancelToken = new axios.CancelToken((cancel) => {
                cancelFetchBidRatingRef.current = cancel;
            });

            void dispatch(fetchBidRating({ params, cancelToken }));
        }, 500)
    );

    const isFirstBidRequestRef = useRef(true);
    useEffect(() => {
        if (isFirstBidRequestRef.current) {
            isFirstBidRequestRef.current = false;
            return;
        }
        cancelFetchBidRatingRef.current?.();
        if (!error) {
            fetchBidRatingDebouncedRef.current({
                areaIds,
                bid: Number(value),
                name,
                professionalRoleIds,
                vacancyId,
            });
        } else {
            fetchBidRatingDebouncedRef.current.cancel();
        }
    }, [areaIds, dispatch, error, name, professionalRoleIds, vacancyId, value]);

    return (
        <AuctionInput
            {...props}
            value={value}
            step={BID_STEP}
            onPlusOrMinusClick={onPlusOrMinusClick}
            inversed={inversed}
        />
    );
};

export default Bid;
