import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SalaryInfo from 'src/components/SalaryInfo';
import translation from 'src/components/translation';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';

import styles from './chart-legend-info.less';

const TrlKeys = {
    text: 'vacancy.salaryMarketRangeChart.legend.text',
    from: 'vacancy.salaryMarketRangeChart.legend.from',
    to: 'vacancy.salaryMarketRangeChart.legend.to',
    noCompensation: 'vacancy.salaryMarketRangeChart.legend.noCompensation',
};

interface ChartLegendInfoProps {
    compensation: VacancyCompensation;
}

const ChartLegendInfo: TranslatedComponent<ChartLegendInfoProps> = ({ compensation, trls }) => {
    const { isMobile } = useBreakpoint();
    const hasCompensation = Boolean(compensation?.from || compensation?.to);
    const textTypography = isMobile ? 'label-3-regular' : 'label-2-regular';

    return (
        <div className={styles.chartLegendInfo} data-qa="salary-info-legend">
            {hasCompensation && <div className={styles.chartLegendInfoCircle} />}
            <Text typography={textTypography}>{trls[TrlKeys.text]}</Text>
            <Text typography={textTypography} style="secondary">
                <SalaryInfo
                    defaultValue={trls[TrlKeys.noCompensation]}
                    from={compensation?.from}
                    to={compensation?.to}
                    currency={compensation?.currencyCode}
                />
            </Text>
        </div>
    );
};

export default translation(ChartLegendInfo);
