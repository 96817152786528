import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    Text,
    Title,
    useBreakpoint,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.howItWorks.title',
    principle: 'auction.howItWorks.principle',
    principleListItem: {
        0: 'auction.howItWorks.principle.listItem.0',
        1: 'auction.howItWorks.principle.listItem.1',
        2: 'auction.howItWorks.principle.listItem.2',
    },
    principleDescription: 'auction.howItWorks.principle.description',
    subTitle: {
        bid: 'auction.howItWorks.subTitle.bid',
        factor: 'auction.howItWorks.subTitle.factor',
    },
    factor: 'auction.howItWorks.factor',
    factorListItem: {
        0: 'auction.howItWorks.factor.listItem.0',
        1: 'auction.howItWorks.factor.listItem.1',
    },
    relevance: 'auction.howItWorks.factor.relevance',
    bidDescription: {
        0: 'auction.howItWorks.bid.description.0',
        1: 'auction.howItWorks.bid.description.1',
    },
    close: 'close',
};

interface HowItWorksModalProps {
    visible: boolean;
    onClose: () => void;
}

const HowItWorksModal: TranslatedComponent<HowItWorksModalProps> = ({ onClose, trls, visible }) => {
    const { isMobile } = useBreakpoint();
    const closeButton = (
        <Button onClick={onClose} mode="secondary">
            {trls[TrlKeys.close]}
        </Button>
    );

    const content = (
        <>
            <Text>{trls[TrlKeys.principle]}</Text>
            <VSpacing default={16} />
            <ol className={styles.principleList}>
                <li className={styles.principleListItem}>
                    <Text>{trls[TrlKeys.principleListItem[0]]}</Text>
                    <VSpacing default={16} />
                </li>
                <li className={styles.principleListItem}>
                    <Text>{trls[TrlKeys.principleListItem[1]]}</Text>
                    <VSpacing default={16} />
                </li>
                <li className={styles.principleListItem}>
                    <Text>{trls[TrlKeys.principleListItem[2]]}</Text>
                    <VSpacing default={16} />
                </li>
            </ol>
            <VSpacing default={8} />
            <Text>{trls[TrlKeys.principleDescription]}</Text>
            <VSpacing default={32} />
            <Title Element="h2" size="medium">
                {trls[TrlKeys.subTitle.factor]}
            </Title>
            <VSpacing default={16} />
            <Text>{trls[TrlKeys.factor]}</Text>
            <VSpacing default={8} />
            <ul className={styles.factorList}>
                <li className={styles.factorListItem}>
                    <Text>{trls[TrlKeys.factorListItem[0]]}</Text>
                </li>
                <li className={styles.factorListItem}>
                    <Text>{trls[TrlKeys.factorListItem[1]]}</Text>
                </li>
            </ul>
            <VSpacing default={16} />
            <Text>{trls[TrlKeys.relevance]}</Text>
            <VSpacing default={16} />
            <div className={styles.howAuctionWorksImage} />
            <VSpacing default={32} />
            <Title Element="h2" size="medium">
                {trls[TrlKeys.subTitle.bid]}
            </Title>
            <VSpacing default={16} />
            <Text>{trls[TrlKeys.bidDescription[0]]}</Text>
            <VSpacing default={16} />
            <Text>{trls[TrlKeys.bidDescription[1]]}</Text>
            <VSpacing default={24} />
        </>
    );

    if (isMobile) {
        return (
            <BottomSheet
                visible={visible}
                onClose={onClose}
                header={<NavigationBar title={trls[TrlKeys.title]} />}
                footer={<BottomSheetFooter>{closeButton}</BottomSheetFooter>}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title={trls[TrlKeys.title]}
            actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
            titleSize="large"
            footer={<ActionBar primaryActions={closeButton} />}
        >
            {content}
        </Modal>
    );
};

export default translation(HowItWorksModal);
