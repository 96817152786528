import { ReactNode } from 'react';
import classnames from 'classnames';

import { urlParser } from '@hh.ru/browser-api-utils';
import { VSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { Badge, BadgeType } from 'src/models/vacancyView.types';

import Card, { CardVariant } from 'src/components/VacancyView/EmployerCards/Card';
import Scroller from 'src/components/VacancyView/EmployerCards/Scroller';

enum HrBrandPosition {
    Nominee = 'nominee',
    Winner = 'winner',
}

interface EmployerCardsProps {
    companyId?: number;
    badges?: {
        badge: Badge[];
    };
    isEmployerView?: boolean;
    isMagritteVacancy?: boolean;
}

interface BadgeCard {
    isVisible: boolean;
    variant: CardVariant;
    title: string;
    subtitle: string;
    dataQa: string;
    url?: string;
    tip?: ReactNode;
}

const ratingTopTitleVariants = [20, 50, 100, 200] as const;

const TrlKeys = {
    ratingTitle: {
        top10: 'employer.cards.hhrating.title.top10',
        top20: 'employer.cards.hhrating.title.top20',
        top50: 'employer.cards.hhrating.title.top50',
        top100: 'employer.cards.hhrating.title.top100',
        top200: 'employer.cards.hhrating.title.top200',
        member: 'employer.cards.hhrating.title.member',
    },
    hrBrandTitle: {
        [HrBrandPosition.Nominee]: 'employer.cards.hrbrand.title.nominee',
        [HrBrandPosition.Winner]: 'employer.cards.hrbrand.title.winner',
    },
    hrBrandTip: {
        [HrBrandPosition.Nominee]: 'employer.cards.hrbrand.tip.nominee',
        [HrBrandPosition.Winner]: 'employer.cards.hrbrand.tip.winner',
    },
    employerReviewsTitle: 'employer.cards.employerReviews.title',
    employerReviewsSubtitle: 'employer.cards.employerReviews.subtitle',
    ratingSubtitleTop10: 'employer.cards.hhrating.subtitle.top10',
    ratingSubtitle: 'employer.cards.hhrating.subtitle.member',
    hrBrandSubtitle: 'employer.cards.hrbrand.subtitle',
    insiderInterviewTitle: 'employer.cards.insiderInterview.title',
    insiderInterviewSubtitle: 'employer.cards.insiderInterview.subtitle',
    itAccreditationTitle: 'employer.cards.itAccreditation.title',
    itAccreditationSubtitle: 'employer.cards.itAccreditation.subtitle',
    specialProjectTitle: 'employer.cards.specialProject.title',
    specialProjectSubtitle: 'employer.cards.specialProject.subtitle',
};

const getHhRatingCard = (badge: Badge | undefined, trls: LangTrls): BadgeCard => {
    const getRatingInfo = (): { titleTrl: string; subtitleTrl: string; dataQa: string } => {
        if (!badge?.position) {
            return { titleTrl: '', subtitleTrl: '', dataQa: '' };
        }

        const position = parseInt(badge.position, 10);
        const dataQa = classnames([
            `employer-card-${CardVariant.Rating}`,
            `employer-card-${CardVariant.Rating}_position-${position}`,
            { [`employer-card-${CardVariant.Rating}_with-url`]: !!badge?.url },
        ]);

        if (position <= 10) {
            return {
                titleTrl: format(trls[TrlKeys.ratingTitle.top10], { '{}': position }),
                subtitleTrl: trls[TrlKeys.ratingSubtitleTop10],
                dataQa,
            };
        }

        const stop = ratingTopTitleVariants.find((stop) => position <= stop);
        return {
            titleTrl: stop ? trls[TrlKeys.ratingTitle[`top${stop}`]] : trls[TrlKeys.ratingTitle.member],
            subtitleTrl: trls[TrlKeys.ratingSubtitle],
            dataQa,
        };
    };

    const ratingInfo = getRatingInfo();

    return {
        isVisible: !!badge,
        variant: CardVariant.Rating,
        title: ratingInfo.titleTrl,
        subtitle: ratingInfo.subtitleTrl,
        dataQa: ratingInfo.dataQa,
        url: badge?.url,
    };
};

const getHrBrandCard = (badges: Badge[], trls: LangTrls, userType: UserType): BadgeCard => {
    const isHrBrandPosition = (position?: string): position is HrBrandPosition =>
        !!position && Object.values(HrBrandPosition).includes(position);

    const renderTip = () => {
        if (badges.length < 2) {
            return null;
        }

        return badges.map((badge) => {
            if (isHrBrandPosition(badge.position)) {
                return (
                    <p key={badge.position}>
                        {trls[TrlKeys.hrBrandTip[badge.position]]}
                        {badge.year ? ` ${badge.year}` : ''}
                    </p>
                );
            }

            return false;
        });
    };

    if (badges.length === 0) {
        return { isVisible: false, variant: CardVariant.HrBrand, title: '', subtitle: '', url: '', dataQa: '' };
    }

    const lastBadge = badges.find((badge) => !badge.year) || badges[0];

    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=card&utm_term=${userType}`;

    return {
        isVisible: true,
        variant: CardVariant.HrBrand,
        title: isHrBrandPosition(lastBadge.position) ? trls[TrlKeys.hrBrandTitle[lastBadge.position]] : '',
        subtitle: trls[TrlKeys.hrBrandSubtitle],
        url: `${lastBadge.url}${urlParams}`,
        tip: renderTip(),
        dataQa: classnames([
            `employer-card-${CardVariant.HrBrand}`,
            `employer-card-${CardVariant.HrBrand}_position-${lastBadge.position || ''}`,
            `employer-card-${CardVariant.HrBrand}_with-url`,
        ]),
    };
};

const getSpecialProjectCard = (trls: LangTrls, projectUrl?: string): BadgeCard => {
    const data: BadgeCard = {
        isVisible: Boolean(projectUrl),
        variant: CardVariant.SpecialProject,
        title: trls[TrlKeys.specialProjectTitle],
        subtitle: trls[TrlKeys.specialProjectSubtitle],
        dataQa: `employer-card-${CardVariant.SpecialProject}`,
    };

    if (projectUrl) {
        const parsedUrl = urlParser(projectUrl);

        parsedUrl.params.utm_source ??= 'hh';
        parsedUrl.params.utm_medium ??= 'referral';
        parsedUrl.params.utm_campaign ??= 'vacancies_hh';
        data.url = parsedUrl.href;
    }

    return data;
};

const EmployerCards: TranslatedComponent<EmployerCardsProps> = ({
    companyId,
    badges,
    isEmployerView = false,
    isMagritteVacancy = false,
    trls,
}) => {
    const employerReviewsIsOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const isTrustedEmployer = useSelector((state) => state.vacancyView.company['@trusted']);
    const insiderInfo = useSelector((state) => state.vacancyView.insider);
    const userType = useSelector((state) => state.userType);
    const { isMobile } = useBreakpoint();
    const ratingBadge = useGetCompanyHHRatingBadge(badges, true);
    const hrBrandBadges = badges?.badge.filter((badge) => badge.type === BadgeType.HrBrand) || [];
    const insiderInterviewBadge = insiderInfo && (insiderInfo.isVisible || insiderInfo['@isVisible']);

    const isEmployerWithItAccreditation = useSelector((state) => state.vacancyView.company.accreditedITEmployer);
    const specialProjectUrl = useSelector((state) => state.specialProject?.url);

    const badgeCards: (BadgeCard | null)[] = [
        {
            isVisible: employerReviewsIsOpenEmployer && isTrustedEmployer,
            variant: CardVariant.EmployerReviews,
            title: trls[TrlKeys.employerReviewsTitle],
            subtitle: trls[TrlKeys.employerReviewsSubtitle],
            dataQa: `employer-card-${CardVariant.EmployerReviews}`,
        },
        getHhRatingCard(ratingBadge, trls),
        getHrBrandCard(hrBrandBadges, trls, userType),
        {
            isVisible: !!insiderInterviewBadge,
            variant: CardVariant.InsiderInterview,
            title: trls[TrlKeys.insiderInterviewTitle],
            subtitle: trls[TrlKeys.insiderInterviewSubtitle],
            url: `/interview/${insiderInfo?.id || ''}?employerId=${companyId || ''}`,
            dataQa: classnames([
                `employer-card-${CardVariant.InsiderInterview}`,
                `employer-card-${CardVariant.InsiderInterview}_with-link`,
            ]),
        },
        {
            isVisible: (isEmployerView || isMagritteVacancy) && isEmployerWithItAccreditation,
            variant: CardVariant.ItAccreditation,
            title: trls[TrlKeys.itAccreditationTitle],
            subtitle: trls[TrlKeys.itAccreditationSubtitle],
            dataQa: `employer-card-${CardVariant.ItAccreditation}`,
        },
        getSpecialProjectCard(trls, specialProjectUrl),
    ];
    const badgeCardsVisible = badgeCards.filter((badge): badge is BadgeCard => !!badge && badge.isVisible);

    if (badgeCardsVisible.length === 0) {
        return null;
    }

    const cards = badgeCardsVisible.map((badgeCard, index) => (
        <Card
            key={badgeCard.variant}
            variant={badgeCard.variant}
            title={badgeCard.title}
            subtitle={badgeCard.subtitle}
            position={index + 1}
            url={badgeCard.url}
            tip={badgeCard.tip}
            dataQa={badgeCard.dataQa}
            isEmployerView={isEmployerView}
            isMagritteVacancy={isMagritteVacancy}
            isMobile={isMobile}
        />
    ));

    return isMagritteVacancy ? (
        <>
            <VSpacing default={32} />
            <VSpacingContainer default={32}>{cards}</VSpacingContainer>
        </>
    ) : (
        <Scroller isEmployerView={isEmployerView}>{cards}</Scroller>
    );
};

export default translation(EmployerCards);
