import { useRef, useMemo, FC, PropsWithChildren } from 'react';

import { LineChartRawData } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/types';

import ChartContext from 'src/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContext';
import {
    getBorderAxesValues,
    getPreparedData,
    getXAxis,
    getYAxis,
    getLinePath,
    getAreaPath,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/utils';

import styles from './chart-container.less';

export interface ChartContainerProps {
    width: number;
    height: number;
    data: LineChartRawData;
    /**
     * Позволят выводить точки графика кратные указанному числу
     */
    chartDimension?: number;
    /**
     * Добавляет плавной сглаженности графику
     */
    isCurveChart?: boolean;
}

const ChartContainer: FC<ChartContainerProps & PropsWithChildren> = ({
    width,
    height,
    data,
    chartDimension = 0,
    isCurveChart = false,
    children,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    // подготавливаем данные для графиков и осей
    const chartData = useMemo(() => {
        const preparedData = getPreparedData(data);
        const borderAxesValues = getBorderAxesValues(data);

        const xAxis = getXAxis(borderAxesValues.date.min, borderAxesValues.date.max, width);

        const borderYAxes = borderAxesValues.value;
        const yAxis = getYAxis(borderYAxes.min, borderYAxes.max, height);

        const linePath = getLinePath(preparedData, xAxis, yAxis, isCurveChart);
        const areaPath = getAreaPath(preparedData, xAxis, yAxis, borderAxesValues.value.min, isCurveChart);
        return { data: preparedData, xAxis, yAxis, linePath, areaPath };
    }, [data, width, height, isCurveChart]);

    return (
        <div ref={containerRef} className={styles.chartContainer} data-qa="line-chart">
            <ChartContext.Provider value={{ chartData, width, height, containerRef, chartDimension }}>
                <svg
                    className={styles.chartSvg}
                    style={{ overflow: 'visible' }}
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                >
                    {children}
                </svg>
            </ChartContext.Provider>
        </div>
    );
};

export default ChartContainer;
