import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import vacanciesTransferButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/vacancies_transfer_button_click';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    SearchInput,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import TreeSelectorDummy from 'bloko/blocks/treeSelector/Dummy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContext from 'src/components/EmployerVacancies/FilterContext';
import { UNCHECK_ALL } from 'src/components/EmployerVacancies/checkReducer';
import fetchClustersInfo from 'src/components/EmployerVacancies/fetchClustersInfo';
import fetchFilteredGroupsInfo from 'src/components/EmployerVacancies/fetchFilteredGroupsInfo';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import { NotificationProps } from 'src/components/Notifications/Provider/types';
import {
    getManagersListTreeCollection,
    getFilteredGroupManagerIdsSet,
} from 'src/components/VacancyActions/fetchManagersList';
import VacancyContactsChangeModal from 'src/components/VacancyContactsChangeModal';
import VacancyContactsChangeModalLeftButtons from 'src/components/VacancyContactsChangeModal/LeftButtons';
import VacancyContactsChangeModalRightButtons from 'src/components/VacancyContactsChangeModal/RightButtons';
import VacancyContactsChangeModalTitle from 'src/components/VacancyContactsChangeModal/Title';
import VacancyContactsChangeModalTitleDescription from 'src/components/VacancyContactsChangeModal/TitleDescription';
import { ContactsAction, FormValues } from 'src/components/VacancyContactsChangeModal/types';
import fetchVacancyManagers from 'src/components/VacancyView/api/fetchVacancyMangers';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import moveVacancies from 'src/components/VacancyModals/Transfer/moveVacancies';

interface TransferProps {
    isVisible: boolean;
    vacanciesIds: number[];
    filterEmployerManagerIdList?: string[];
    dispatchCheckVacancy?: ({ type }: { type: string }) => void;
    handleCloseModal: () => void;
    page?: 'employer_vacancies' | 'vacancy';
}

enum VacancyTransferStep {
    ManagersSelect = 'managersSelect',
    ContactsTransfer = 'contactsTransfer',
}

const TrlKeys = {
    titlePrefix: 'employer.myVacancyes.move.titlePrefix',
    error: 'employer.myVacancyes.move.error',
    cancel: 'employer.myVacancyes.move.cancel',
    transferSubmit: 'employer.myVacancyes.move',
    transferSubmitOne: 'employer.myVacancyes.moveOne',
    transferSome: 'vacancy.some',
    transferMany: 'vacancy.many',
};

const ErrorNotification: FC<{ setError: (error: string) => void; error: string } & NotificationProps> = ({
    setError,
    error,
    ...props
}) => (
    <Notification
        addon={<MinusCircleFilledSize24 initial="negative" />}
        onClose={() => {
            setError('');
        }}
        autohideTime={3000}
        {...props}
    >
        {error}
    </Notification>
);

const Transfer: TranslatedComponent<TransferProps> = ({
    vacanciesIds,
    isVisible,
    dispatchCheckVacancy,
    handleCloseModal,
    filterEmployerManagerIdList,
    page = 'employer_vacancies',
    trls,
}) => {
    const dispatch = useDispatch();
    const managerVacanciesFilteredGroups = useSelectorNonNullable((state) => state.managerVacanciesFilteredGroups);
    const managersList = useSelector((state) => state.managersList);
    const groupId = useSelectorNonNullable(
        ({ managerVacanciesActiveFilteredGroupId }) => managerVacanciesActiveFilteredGroupId
    );
    const [contentFilterQuery, setContentFilterQuery] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const { isMobile } = useBreakpoint();
    const { addNotification } = useNotification();

    const [step, setStep] = useState(VacancyTransferStep.ManagersSelect);

    const currentGroupManagersIdsSet = useMemo(
        () => getFilteredGroupManagerIdsSet(managerVacanciesFilteredGroups, groupId),
        [managerVacanciesFilteredGroups, groupId]
    );
    const managersTree = useMemo(
        () => getManagersListTreeCollection(managersList, currentGroupManagersIdsSet),
        [managersList, currentGroupManagersIdsSet]
    );

    const [error, setError] = useState('');
    const [selected, setManager] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { filters, dispatchFilter } = useContext(FilterContext);

    const vacanciesCount = vacanciesIds?.length || 1;

    useEffect(() => {
        if (!error) {
            return;
        }
        addNotification(ErrorNotification, { props: { setError, error } });
    }, [addNotification, error]);

    const handleSubmit = useCallback(() => {
        const selectedMager = managersTree.getModel(selected)?.additional;
        if (!selectedMager) {
            setError(trls[TrlKeys.error]);
            return;
        }

        setStep(VacancyTransferStep.ContactsTransfer);
    }, [managersTree, selected, trls]);

    const handleChangeContactsSubmit = useCallback(
        async (changeContactFormValues: FormValues, updateContactsAction: ContactsAction) => {
            setIsSubmitting(true);
            const selectedMager = managersTree.getModel(selected)?.additional;
            if (!selectedMager) {
                return;
            }

            Analytics.sendHHEventButtonClick('modal_transfer_button', {
                vacanciesIds,
            });

            try {
                await dispatch(
                    moveVacancies({
                        vacanciesIds,
                        filterEmployerManagerIdList,
                        managerToTransfer: selectedMager,
                        groupId,
                        currentGroupManagersIdsSet,
                        changeContactFormValues,
                        updateContactsAction,
                        page,
                    })
                );
            } catch (err) {
                setIsSubmitting(false);
                throw err;
            }

            vacanciesTransferButtonClick({ updateContactsAction });

            setError('');
            handleCloseModal();

            if (page === 'vacancy') {
                const vacancyId = vacanciesIds[0].toString();
                const ownerVacancyId = selectedMager.managerId.toString();
                await dispatch(fetchVacancyManagers({ params: { vacancyId, ownerVacancyId } }));
                setIsSubmitting(false);
                return;
            }

            dispatchCheckVacancy?.({ type: UNCHECK_ALL });
            void dispatch(fetchClustersInfo(filters, dispatchFilter, groupId));
            if (groupId) {
                void dispatch(fetchFilteredGroupsInfo(groupId));
            }
            setIsSubmitting(false);
        },
        [
            managersTree,
            selected,
            vacanciesIds,
            handleCloseModal,
            dispatchCheckVacancy,
            page,
            dispatch,
            filterEmployerManagerIdList,
            groupId,
            currentGroupManagersIdsSet,
            filters,
            dispatchFilter,
        ]
    );

    const handleQueryChange = useCallback((inputValue: string) => {
        const value = inputValue.trim();
        setFilterValue(value);
        setContentFilterQuery(value);
    }, []);

    const title = (
        <>
            {step === VacancyTransferStep.ManagersSelect && (
                <>
                    {trls[TrlKeys.titlePrefix]}
                    {NON_BREAKING_SPACE}
                    <ConversionNumber
                        value={vacanciesCount}
                        one={trls[TrlKeys.transferSome]}
                        some={trls[TrlKeys.transferMany]}
                        many={trls[TrlKeys.transferMany]}
                    />
                </>
            )}
            {step === VacancyTransferStep.ContactsTransfer && (
                <VacancyContactsChangeModalTitle vacanciesCount={vacanciesCount} />
            )}
        </>
    );

    const titleDescription =
        step === VacancyTransferStep.ContactsTransfer ? (
            <VacancyContactsChangeModalTitleDescription vacanciesCount={vacanciesCount} />
        ) : undefined;

    const rightButtons = (
        <>
            {step === VacancyTransferStep.ManagersSelect && (
                <>
                    <Button mode="secondary" style="accent" onClick={handleCloseModal}>
                        {trls[TrlKeys.cancel]}
                    </Button>
                    <Button
                        mode="primary"
                        style="accent"
                        onClick={handleSubmit}
                        disabled={!selected || isSubmitting}
                        data-qa="vacancy-transfer-submit"
                    >
                        {vacanciesCount === 1 ? trls[TrlKeys.transferSubmitOne] : trls[TrlKeys.transferSubmit]}
                    </Button>
                </>
            )}
            {step === VacancyTransferStep.ContactsTransfer && (
                <VacancyContactsChangeModalRightButtons
                    isSubmitting={isSubmitting}
                    backStep={() => setStep(VacancyTransferStep.ManagersSelect)}
                />
            )}
        </>
    );

    const content = (
        <>
            {step === VacancyTransferStep.ManagersSelect && (
                <div data-qa="vacancy-transfer-managers-select">
                    <TreeSelectorDummy
                        singleChoice
                        collection={managersTree}
                        selected={[selected]}
                        onChange={(id) => setManager(id)}
                        contentFilterQuery={contentFilterQuery}
                    />
                </div>
            )}
            {step === VacancyTransferStep.ContactsTransfer && (
                <VacancyContactsChangeModal
                    vacanciesCount={vacanciesCount}
                    managerId={selected}
                    onSubmit={handleChangeContactsSubmit}
                    setError={setError}
                    type="move"
                />
            )}
        </>
    );

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                visible={isVisible}
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                options={
                    step === VacancyTransferStep.ManagersSelect && (
                        <SearchInput value={filterValue} onChange={handleQueryChange} />
                    )
                }
                title={title}
                titleDescription={titleDescription}
                footer={
                    <ActionBar
                        primaryActions={rightButtons}
                        secondaryActions={
                            step === VacancyTransferStep.ContactsTransfer ? (
                                <VacancyContactsChangeModalLeftButtons handleCloseModal={handleCloseModal} />
                            ) : undefined
                        }
                    />
                }
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isVisible && isMobile}
                height="full-screen"
                header={
                    <NavigationBar
                        title={title}
                        right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                        options={
                            step === VacancyTransferStep.ManagersSelect && (
                                <SearchInput value={filterValue} onChange={handleQueryChange} />
                            )
                        }
                    />
                }
                footer={<BottomSheetFooter>{rightButtons}</BottomSheetFooter>}
                onClose={handleCloseModal}
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(Transfer);
