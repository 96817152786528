import { useCallback } from 'react';
import classnames from 'classnames';

import { Card, Loader, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { getNormalizedAuctionFields } from 'src/components/Auction/utils';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { BidFrequencyStatus } from 'src/models/auctionData';

import styles from './styles.less';

const TrlKeys = {
    [BidFrequencyStatus.Rare]: {
        title: 'auction.bidRating.rare.title',
        description: 'auction.bidRating.rare.description',
    },
    [BidFrequencyStatus.Average]: {
        title: 'auction.bidRating.average.title',
        description: 'auction.bidRating.average.description',
        sudoHint: 'auction.bidRating.average.sudoHint',
    },
    [BidFrequencyStatus.Often]: {
        title: 'auction.bidRating.often.title',
        description: 'auction.bidRating.often.description',
        sudoHint: 'auction.bidRating.often.sudoHint',
    },
};

interface BidRatingProps {
    inversed?: boolean;
    inBuilder?: boolean;
}

const BidRating: TranslatedComponent<BidRatingProps> = ({ inversed, trls, children }) => {
    const auctionData = useSelector(({ auctionData }) => auctionData);
    const bidRatingData = useSelectorNonNullable(({ auctionData }) => auctionData.bidRating.data);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const loading = useSelectorNonNullable(({ auctionData }) => auctionData.bidRating.loading);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const { canEditAuction } = getNormalizedAuctionFields(auctionData);

    const { averageThresholdBid, bidFrequencyStatus, oftenThresholdBid } = bidRatingData;

    const cardStyle = () => {
        if (inversed) {
            return undefined;
        }
        if (averageThresholdBid) {
            return 'warning';
        }
        if (bidFrequencyStatus === BidFrequencyStatus.Rare) {
            return 'negative';
        }
        return 'extra-1';
    };

    const renderContent = useCallback(
        (bidFrequencyStatus: BidFrequencyStatus) => (
            <>
                <Text typography="title-5-semibold">{trls[TrlKeys[bidFrequencyStatus].title]}</Text>
                <VSpacing default={4} />
                <Text typography="paragraph-2-regular">
                    {bidFrequencyStatus === BidFrequencyStatus.Often
                        ? trls[TrlKeys[BidFrequencyStatus.Often].description]
                        : formatToReactComponent(trls[TrlKeys[bidFrequencyStatus].description], {
                              '{0}': (
                                  <FormatMoney currency={currency} inCents>
                                      {oftenThresholdBid}
                                  </FormatMoney>
                              ),
                          })}
                </Text>
            </>
        ),
        [currency, oftenThresholdBid, trls]
    );

    const bidRatingContent = (
        <div
            className={classnames(styles.bidRatingWrapper, {
                [styles.bidRatingWrapperLoading]: loading && !children,
                [styles.bidRatingWrapperLoadingWithChildren]: loading && children,
            })}
        >
            <div className={styles.bidRatingWrapperContent}>
                <div>{renderContent(bidFrequencyStatus)}</div>
            </div>
            <div className={styles.bidRatingWrapperLoader}>
                <Loader size={24} />
            </div>
            {children}
        </div>
    );

    const thresholdBid = isSudo && (
        <>
            <VSpacing default={canEditAuction ? 24 : 20} />
            <Text typography="label-2-regular" style="secondary">
                {formatToReactComponent(trls[TrlKeys[BidFrequencyStatus.Often].sudoHint], {
                    '{0}': (
                        <Text Element="span" typography="subtitle-2-semibold" style="secondary">
                            <FormatMoney currency={currency} inCents>
                                {oftenThresholdBid}
                            </FormatMoney>
                        </Text>
                    ),
                })}
                {averageThresholdBid &&
                    formatToReactComponent(trls[TrlKeys[BidFrequencyStatus.Average].sudoHint], {
                        '{0}': (
                            <Text Element="span" typography="subtitle-2-semibold" style="secondary">
                                <FormatMoney currency={currency} inCents>
                                    {averageThresholdBid}
                                </FormatMoney>
                            </Text>
                        ),
                    })}
            </Text>
        </>
    );

    return (
        <>
            <Card borderRadius={24} padding={24} style={cardStyle()} stretched actionCard={false}>
                {bidRatingContent}
                {canEditAuction && thresholdBid}
            </Card>
            {!canEditAuction && thresholdBid}
        </>
    );
};

export default translation(BidRating);
