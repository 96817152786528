import { useRef, useCallback, useEffect, useState, MutableRefObject } from 'react';
import classnames from 'classnames';

import { Text, VSpacing, VSpacingContainer, Link } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import scrollToElement from 'Utils/ScrollToElement';
import RelatedVacanciesTitle from 'src/components/RelatedVacancies/Title';
import { RELATED_VACANCIES_TYPES } from 'src/components/RelatedVacancies/relatedVacanciesTypes';
import SuitableVacancy from 'src/components/SuitableVacancy';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE, NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const RELATED_VACANCIES_SHOWN_COUNT = 2;
const SCROLL_OFFSET = -20;

interface RelatedVacanciesPreviewProps {
    fullListRef?: MutableRefObject<HTMLElement>;
}

const TrlKeys = {
    moreVacancies: {
        similar: {
            one: 'vacancy.similar.one',
            some: 'vacancy.similar.some',
            many: 'vacancy.similar.many',
        },
        suitable: {
            one: 'vacancy.suitable.more.one',
            some: 'vacancy.suitable.more.some',
            many: 'vacancy.suitable.more.many',
        },
    },
};

const RelatedVacanciesPreview: TranslatedComponent<RelatedVacanciesPreviewProps> = ({ trls, fullListRef }) => {
    const {
        vacancies,
        resultsFound: vacanciesCount,
        type: relatedType,
    } = useSelector((state) => state.relatedVacancies);
    const vacanciesPreview = vacancies?.slice(0, RELATED_VACANCIES_SHOWN_COUNT);
    const stickyPlaceRef = useRef<HTMLDivElement | null>(null);
    const stickyElementRef = useRef<HTMLDivElement | null>(null);
    const dummyRef = useRef<HTMLDivElement | null>(null);
    const breakpoint = useBreakpoint(Breakpoint.L);
    const isL = breakpoint === Breakpoint.L;
    const [isVisible, setIsVisible] = useState(true);

    const updateMetrics = useCallback(() => {
        if (!isL || !fullListRef?.current || !stickyElementRef.current || !stickyPlaceRef.current) {
            return;
        }
        const fullListTop = fullListRef.current.offsetTop;
        const stickyElementBottom = stickyPlaceRef.current.offsetTop + stickyElementRef.current.offsetHeight;
        const diff = fullListTop - stickyElementBottom;
        if (dummyRef.current) {
            dummyRef.current.style.height = `${diff}px`;
        }
        setIsVisible(diff > 0);
    }, [fullListRef, isL]);

    useEffect(() => {
        updateMetrics();
        const intervalId = setInterval(() => {
            updateMetrics();
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [updateMetrics]);

    const scrollToFullList = useCallback(() => {
        if (fullListRef?.current) {
            scrollToElement(fullListRef.current, {
                centered: false,
                topOffset: SCROLL_OFFSET,
            });
        }
    }, [fullListRef]);

    if (!vacanciesPreview?.length || !isL || !relatedType) {
        return null;
    }

    const isSuitable = relatedType === RELATED_VACANCIES_TYPES.SUITABLE;
    const vacanciesCountFormatted = NumberFormatter.format(String(vacanciesCount), {
        groupSeparator: NARROW_NON_BREAKING_SPACE,
    });
    const titleContent = <RelatedVacanciesTitle type={relatedType} isMagritteVacancy />;
    const suitableVacancies = vacanciesPreview.map((vacancy) => (
        <SuitableVacancy
            key={vacancy.vacancyId}
            vacancyId={vacancy.vacancyId}
            vacancyName={vacancy.name}
            companyId={Number(vacancy.company.id)}
            companyVisibleName={vacancy.company.visibleName}
            companyDepartment={vacancy.company.department}
            companyLogo={vacancy.company.logos?.logo?.[0]?.['@url']}
            compensation={vacancy.compensation}
            area={vacancy.area}
            links={{ desktop: `/vacancy/${vacancy.vacancyId}` }}
        />
    ));
    const linkContent = (
        <>
            {!isSuitable && vacanciesCountFormatted}
            {!isSuitable && NON_BREAKING_SPACE}
            <ConversionNumber
                value={vacanciesCount ?? 0}
                format={(trl) => format(trl, { '{0}': vacanciesCountFormatted })}
                one={trls[TrlKeys.moreVacancies[relatedType].one]}
                some={trls[TrlKeys.moreVacancies[relatedType].some]}
                many={trls[TrlKeys.moreVacancies[relatedType].many]}
                hasValue={false}
            />
        </>
    );

    return (
        <>
            <div ref={stickyPlaceRef} />
            <div
                className={classnames('related-vacancies-preview', {
                    'related-vacancies-preview_hidden': !isVisible,
                })}
                ref={stickyElementRef}
            >
                <Text typography="label-2-regular" style="secondary">
                    {titleContent}
                </Text>
                <VSpacing default={12} />
                <div className="recommended-vacancies">
                    <VSpacingContainer default={8}>{suitableVacancies}</VSpacingContainer>
                </div>
                <div className="related-vacancies-preview-switch">
                    <VSpacing default={12} />
                    <Link
                        iconRight={ChevronRightOutlinedSize16}
                        onClick={scrollToFullList}
                        data-qa="related-vacancies-link"
                    >
                        {linkContent}
                    </Link>
                </div>
            </div>
            <div ref={dummyRef} />
        </>
    );
};

export default translation(RelatedVacanciesPreview);
