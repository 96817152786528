import { useCallback } from 'react';
import { AxiosError } from 'axios';

import useOnOffState from 'src/hooks/useOnOffState';
import fetcher from 'src/utils/fetcher';

const GET_MANAGER_CONTACTS = '/shards/employer/get_manager_contacts';

export interface QueryParams {
    managerId: string;
}

export interface Response {
    fio: string;
    email: string;
    phone: string;
    comment: string;
    additionalPhone: string;
    additionalComment: string;
}

declare global {
    interface FetcherGetApi {
        [GET_MANAGER_CONTACTS]: {
            queryParams: QueryParams;
            response: Response;
        };
    }
}

type FetchManagerContactsFunction = (
    params: QueryParams
) => Promise<[data: Response, error: null] | [data: null, error: Error | AxiosError]>;

type UseFetchManagerContactsHook = () => [isLoading: boolean, fetch: FetchManagerContactsFunction];

const useFetchManagerContacts: UseFetchManagerContactsHook = () => {
    const [isLoading, startLoading, finishLoading] = useOnOffState(false);

    const fetchManagerList: FetchManagerContactsFunction = useCallback(
        async (params) => {
            startLoading();

            let response: Response;
            try {
                response = await fetcher.get(GET_MANAGER_CONTACTS, { params });
            } catch (error) {
                finishLoading();
                return [null, error as AxiosError];
            }

            finishLoading();
            return [response, null];
        },
        [finishLoading, startLoading]
    );

    return [isLoading, fetchManagerList];
};

export default useFetchManagerContacts;
