import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Column, { ColumnsWrapper, ColumnsRow } from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import usePrintDetect from 'src/hooks/usePrintDetect';
import { useSelector } from 'src/hooks/useSelector';

import VacancyRowContent from 'src/components/VacancyView/VacancyRowContent';

interface VacancyDefaultContainerProps {
    ContainerWrapper: FC<PropsWithChildren>;
    rightColumn: ReactNode;
    isChameleonEmployerView: boolean;
    isRedesign: boolean;
}

const VacancyDefaultContainer: FC<VacancyDefaultContainerProps & PropsWithChildren> = ({
    ContainerWrapper = ColumnsWrapper,
    children,
    rightColumn,
    isChameleonEmployerView,
    isRedesign,
}) => {
    const isZarplata = useIsZarplataPlatform();
    const isPrinting = usePrintDetect();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const userType = useSelector(({ userType }) => userType);

    useEffect(() => {
        if (vacancyId) {
            Analytics.sendEvent(userType, 'view-vacancy', undefined, undefined, undefined, {
                product_id: vacancyId,
            });
        }
        if (isZarplata) {
            Analytics.addEventPixels('ZP_VIEW_PRODUCT');
        }
    }, [isZarplata, userType, vacancyId]);

    const renderContent = (content: ReactNode) => {
        if (isRedesign) {
            return <Text size={isPrinting ? undefined : TextSize.Large}>{content}</Text>;
        }
        return content;
    };
    let mColumn: '9' | '8' | '12' = isChameleonEmployerView ? '9' : '8';
    if (isRedesign) {
        mColumn = '12';
    }
    return (
        <ContainerWrapper>
            <VacancyRowContent isChameleonEmployerView={isChameleonEmployerView}>
                {renderContent(
                    <ColumnsRow>
                        <Column xs="4" s="8" m={mColumn} l={isRedesign ? '10' : '11'} container>
                            {children}
                        </Column>
                        {rightColumn}
                    </ColumnsRow>
                )}
            </VacancyRowContent>
        </ContainerWrapper>
    );
};

export default VacancyDefaultContainer;
