import { useDispatch } from 'react-redux';

import auctionModalEditCampaignButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_edit_campaign_button_click';
import { Button } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { changeAndRestoreAuctionCampaign, changedClickmeCampaign } from 'src/api/price/cart';
import {
    getEditCampaignClickAnalyticsParams,
    isPauseCampaignStatus,
    isRestoreAction,
} from 'src/components/Auction/utils';
import { useNotification } from 'src/components/Notifications/Provider';
import saveSuccessfullyAuctionModalNotification from 'src/components/Notifications/VacancyAuctionSaveSuccessfullNotification';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import {
    AuctionData,
    AuctionFieldName,
    AuctionCampaignStatus,
    defaultControlInfo,
    CurrentVacancyAuctionCampaign,
    updateAuctionCampaignInfo,
} from 'src/models/auctionData';

const setAuctionData = makeSetStoreField('auctionData');

const TrlKeys = {
    save: 'Save',
    restoreAndSave: 'auction.edit.restoreAndSave',
};

const getButtonTextKey = (bidChanged: boolean, campaignStatus: AuctionCampaignStatus | undefined) =>
    isRestoreAction(bidChanged, campaignStatus) ? TrlKeys.restoreAndSave : TrlKeys.save;

interface SaveButtonProps {
    auctionLoading: boolean;
    onSave: () => void;
    onError: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
}

const SaveButton: TranslatedComponent<SaveButtonProps> = ({
    trls,
    auctionLoading,
    onSave,
    onError,
    onCampaignInfoUpdate,
}) => {
    const bidRatingLoading = useSelector(({ auctionData }) => auctionData?.bidRating.loading);
    const bidControl =
        useSelector(({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid]) || defaultControlInfo;
    const budgetControl =
        useSelector(({ auctionData }) => auctionData?.controls[AuctionFieldName.Budget]) || defaultControlInfo;
    const currentVacancyAuctionCampaign = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign);
    const vacancyId = useSelector(({ auctionData }) => auctionData?.vacancyId || null);
    const { addNotification } = useNotification();

    const [loading, startLoading, stopLoading] = useOnOffState(false);

    const dispatch = useDispatch();

    const bidChanged = bidControl?.initialValue !== bidControl?.value;
    const disabledButton = !bidChanged || !vacancyId || !currentVacancyAuctionCampaign;

    const loadingButton = auctionLoading || bidRatingLoading || loading;

    if (!bidChanged && isPauseCampaignStatus(currentVacancyAuctionCampaign?.campaignStatus)) {
        return null;
    }

    if (disabledButton) {
        return null;
    }

    const save = () => {
        if (disabledButton || loadingButton || budgetControl.error) {
            return;
        }
        startLoading();

        const { budget: initialCampaignBudget, campaignId } = currentVacancyAuctionCampaign;
        const currentBid = Number(bidControl.value);

        if (isRestoreAction(bidChanged, currentVacancyAuctionCampaign.campaignStatus)) {
            const callback = (campaign: Partial<CurrentVacancyAuctionCampaign>) => {
                dispatch(updateAuctionCampaignInfo(campaign));
                onCampaignInfoUpdate?.(campaign);
                onSave();
            };

            void dispatch(
                changeAndRestoreAuctionCampaign(
                    vacancyId,
                    campaignId,
                    currentBid,
                    initialCampaignBudget,
                    callback,
                    onError,
                    addNotification
                )
            ).finally(stopLoading);
        } else {
            const callback = (auction: AuctionData) => {
                dispatch(setAuctionData(auction));
                onCampaignInfoUpdate?.(auction.currentVacancyAuctionCampaign!);
                onSave();
                addNotification(saveSuccessfullyAuctionModalNotification);
            };
            void dispatch(
                changedClickmeCampaign(
                    vacancyId,
                    campaignId,
                    currentBid,
                    initialCampaignBudget,
                    callback,
                    addNotification,
                    onError
                )
            ).finally(stopLoading);
        }

        auctionModalEditCampaignButtonClick(dispatch(getEditCampaignClickAnalyticsParams('BID')));
    };

    return (
        <Button
            style="accent"
            mode="primary"
            disabled={disabledButton}
            loading={loadingButton}
            onClick={save}
            data-qa="edit-auction-button"
        >
            {trls[getButtonTextKey(bidChanged, currentVacancyAuctionCampaign?.campaignStatus)]}
        </Button>
    );
};

export default translation(SaveButton);
