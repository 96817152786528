import { useRef, useState } from 'react';
import classnames from 'classnames';

import {
    CrossOutlinedSize16,
    EnvelopeOutlinedSize16,
    LocationOutlinedSize16,
    PhoneOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Gap from 'bloko/blocks/gap';
import { IconLink } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import { ContactInfo } from 'src/models/vacancyView.types';

import ContactChat from 'src/components/VacancyView/ContactChat';
import VacancyContactsAnonymousAgreement from 'src/components/VacancyView/VacancyContactsAnonymousAgreement';

declare global {
    interface FetcherPutApi {
        '/shards/user_agreements/anonymous_agreement': {
            body: void;
            queryParams: void;
            response: void;
        };
    }
}

interface VacancyContactsCallTrackingProps {
    vacancyId: number;
    contactInfo?: ContactInfo | null;
    address?: string;
    onPhoneClick?: () => void;
    onEmailClick?: () => void;
    onAddressClick?: () => void;
    onClose?: () => void;
    chatButtonInfo?: VacancyChatInfoType | null;
    employerId?: number;
    chatWritePossibility?: string;
    block?: boolean;
}

const TrlKeys = {
    link: 'vacancy.callTracking.link',
    textC: 'vacancy.contacts.callTracking.disclaimer.text.c',
    why: 'vacancy.contacts.callTracking.disclaimer.why',
    tooltip: 'vacancy.contacts.callTracking.disclaimer.tooltip',
    number: 'vacancy.contacts.callTracking.disclaimer.number',
    numbers: 'vacancy.contacts.callTracking.disclaimer.numbers',
};

const VacancyContactsCallTracking: TranslatedComponent<VacancyContactsCallTrackingProps> = ({
    contactInfo,
    vacancyId,
    address,
    onPhoneClick,
    onAddressClick,
    onEmailClick,
    onClose,
    chatButtonInfo,
    employerId,
    chatWritePossibility,
    block,
    trls,
}) => {
    const isVacancyChatEnable = chatWritePossibility !== 'DISABLED';

    const { fio, email } = contactInfo || {};
    const phones = contactInfo?.phones?.phones;

    const userType = useSelector((state) => state.userType);
    const anonymousAgreementAccepted = useSelector((state) => state.anonymousAgreementAccepted);
    const [showAgreement, setShowAgreement] = useState(userType === UserType.Anonymous && !anonymousAgreementAccepted);
    const activatorRef = useRef(null);

    return (
        <>
            {showAgreement && (
                <VacancyContactsAnonymousAgreement
                    hideAgreement={() => setShowAgreement(false)}
                    vacancyId={vacancyId}
                    onClose={onClose}
                />
            )}
            <div
                className={classnames('vacancy-contacts-call-tracking', {
                    'vacancy-contacts-call-tracking_block': Boolean(block),
                    'vacancy-contacts-call-tracking_disable': showAgreement,
                })}
            >
                {onClose && (
                    <span className="vacancy-contacts-call-tracking__close">
                        <IconLink type="button" Element="button" onClick={onClose}>
                            <BlokoIconReplaceContainer>
                                <CrossOutlinedSize16 initial="secondary" />
                            </BlokoIconReplaceContainer>
                        </IconLink>
                    </span>
                )}
                {fio && (
                    <div className="vacancy-contacts-call-tracking__fio">
                        <Text size={TextSize.Large} Element="p" data-qa="vacancy-contacts__fio" strong>
                            <AsyncHighlighter>{fio}</AsyncHighlighter>
                        </Text>
                    </div>
                )}
                {chatButtonInfo && isVacancyChatEnable && employerId && (
                    <ContactChat
                        chatButtonInfo={chatButtonInfo}
                        vacancyId={vacancyId}
                        employerId={employerId}
                        onClick={onClose}
                    />
                )}
                {Boolean(phones?.length) && (
                    <div className="vacancy-contacts-call-tracking__disclaimer">
                        <span className="vacancy-contacts-call-tracking__icon" />
                        <div className="vacancy-contacts-call-tracking__disclaimer-content">
                            <Text size={TextSize.Small} Element="span">
                                {trls[TrlKeys.textC]}
                            </Text>
                            {'\u00a0'}
                            <HoverTip
                                render={() => trls[TrlKeys.tooltip]}
                                placement={TipPlacement.Top}
                                host={typeof window === 'undefined' ? null : document.body}
                                flexible
                                activatorRef={activatorRef}
                            >
                                <Link appearance={LinkAppearance.Pseudo} ref={activatorRef}>
                                    <Text size={TextSize.Small} Element="span">
                                        {trls[TrlKeys.why]}
                                    </Text>
                                </Link>
                            </HoverTip>
                        </div>
                    </div>
                )}
                <Gap top>
                    <div className="vacancy-contacts-call-tracking__phones">
                        {phones?.map((phone, index) => {
                            const { country, city, number, comment } = phone;
                            const formattedPhone = `+${country}\u00a0${city}\u00a0${number}`;
                            return (
                                <div key={index} className="vacancy-contacts-call-tracking__phone">
                                    <div className="vacancy-contacts-call-tracking__phone-icon">
                                        <BlokoIconReplaceContainer>
                                            <PhoneOutlinedSize16 initial="accent" />
                                        </BlokoIconReplaceContainer>
                                    </div>
                                    <div className="vacancy-contacts-call-tracking__phone-number">
                                        <Link href={`tel:+${country}${city}${number}`} onClick={onPhoneClick}>
                                            {formattedPhone}
                                        </Link>
                                    </div>
                                    {Boolean(comment) && (
                                        <Text size={TextSize.Small}>
                                            <AsyncHighlighter>{comment}</AsyncHighlighter>
                                        </Text>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Gap>
                {email && (
                    <Gap top>
                        <div className="vacancy-contacts-call-tracking__email">
                            <div className="vacancy-contacts-call-tracking__email-icon">
                                <BlokoIconReplaceContainer>
                                    <EnvelopeOutlinedSize16 initial="accent" />
                                </BlokoIconReplaceContainer>
                            </div>
                            <Link
                                href={`mailto:${email}`}
                                rel="nofollow"
                                data-qa="vacancy-contacts__email"
                                onClick={onEmailClick}
                                disableVisited
                            >
                                {email}
                            </Link>
                        </div>
                    </Gap>
                )}
                {address && (
                    <Gap top>
                        <div className="vacancy-contacts-call-tracking__address">
                            <div className="vacancy-contacts-call-tracking__address-icon">
                                <BlokoIconReplaceContainer>
                                    <LocationOutlinedSize16 initial="accent" />
                                </BlokoIconReplaceContainer>
                            </div>
                            <Link
                                href={`${paths.vacancySearchMap}?vacancy_id=${vacancyId}`}
                                data-qa="vacancy-contacts__address"
                                onClick={onAddressClick}
                                disableVisited
                            >
                                {address}
                            </Link>
                        </div>
                    </Gap>
                )}
            </div>
        </>
    );
};

export default translation(VacancyContactsCallTracking);
