import { ReactNode, FC } from 'react';

import styles from './tab-pane.less';

interface TabPaneProps {
    value: string | number | null;
    index: string | number;
    children: ReactNode;
    destroyInactive?: boolean;
}

const TabPane: FC<TabPaneProps> = ({ value, index, children, destroyInactive = false }) => {
    const isActive = value === index;

    if (destroyInactive) {
        return isActive ? <div data-qa={`tab-pane-content-${index}`}>{children}</div> : null;
    }

    return (
        <div data-qa={`tab-pane-content-${index}`} className={isActive ? '' : styles.tabPaneHide}>
            {children}
        </div>
    );
};

export default TabPane;
