import { Text as MagritteText, VSpacing as MagritteVSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AgreementText from 'src/components/ApplicantSignInSignUpFlow/AgreementText';
import SignInFormStep, { SignInFormRenderProps } from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import Heading from 'src/components/VacancyView/SignUpBlock/Form/Heading';

import styles from './sign-in-step.less';

const TrlKeys = {
    header: 'signup.header.fillPhone',
    submit: 'signupForm.create.submit',
    inputPlaceholder: 'signup.placeholder.phoneNumber',
};

export interface SignInStepProps {
    callbackSubmit?: () => void;
    next: () => void;
}

const SignInStep: TranslatedComponent<SignInStepProps> = ({ next, trls, callbackSubmit }) => {
    const backurl = useSelector(({ authUrl }) => authUrl?.backurl);
    const isMagritte = useMagritte();
    const { isMobile } = useBreakpoint();

    const render = ({ renderLogin, renderSubmitButton, renderCaptcha }: SignInFormRenderProps) => {
        const headerTrl = trls[TrlKeys.header];
        const placeholderTrl = trls[TrlKeys.inputPlaceholder];
        const vSpacing = isMagritte ? <MagritteVSpacing default={isMobile ? 16 : 24} /> : <VSpacing base={6} xs={4} />;
        const header = <Heading>{headerTrl}</Heading>;
        const agreement = isMagritte ? (
            <MagritteText Element="div" style="secondary" typography="label-4-regular">
                <AgreementText />
            </MagritteText>
        ) : (
            <Text size={TextSize.Small}>
                <div className={styles.agreement}>
                    <AgreementText />
                </div>
            </Text>
        );
        const input = renderLogin({
            placeholder: placeholderTrl,
            size: isMagritte ? 'medium' : undefined,
        });
        const button = renderSubmitButton(trls[TrlKeys.submit], {
            scale: undefined,
            size: isMagritte ? 'medium' : undefined,
        });
        const captcha = renderCaptcha();

        return (
            <div className={isMagritte ? undefined : styles.wrapper}>
                {header}
                {vSpacing}
                <div className={styles.container}>
                    <div className={styles.inputWrapper}>{input}</div>
                    <div className={styles.buttonWrapper}>{button}</div>
                </div>
                {captcha}
                {vSpacing}
                {agreement}
            </div>
        );
    };

    return (
        <SignInFormStep
            isAutofocusEnabled={false}
            backurl={backurl}
            next={next}
            callbackSubmit={callbackSubmit}
            dataQaModifier="account-signup-vacancy-page"
            render={render}
        />
    );
};

export default translation(SignInStep);
