import { useCallback, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { FlyInFlyOutDuration, WorkScheduleByDays, WorkingHours, WorkFormat } from '@hh.ru/types-hh-microcore';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

interface UseCountingTextsProps<T> {
    field: 'flyInFlyOutDuration' | 'workScheduleByDays' | 'workingHours' | 'workFormats';
    values: T[];
    dictionary: {
        id: T;
        text: string;
    }[];
    trls: LangTrls;
}

interface Texts {
    text: string;
    count: string;
    tooltip: string;
    bottomSheet: string;
}

const TrlKeys = {
    or: 'vacancy.view.employment.counting.or',
    more: 'vacancy.view.employment.counting.more',
    and: 'vacancy.view.employment.counting.and',
    other: 'vacancy.view.employment.counting.other',
    shift: 'vacancy.view.employment.counting.shift',
    on: 'vacancy.view.employment.counting.on',
};

export function useCountingTexts<T extends WorkingHours | FlyInFlyOutDuration | WorkScheduleByDays | WorkFormat>({
    values,
    dictionary,
    field,
    trls,
}: UseCountingTextsProps<T>): Texts {
    const query = useSelector((state) => state.router.location.query);
    const pathname = useSelector((state) => state.router.location.pathname);
    const isPrinting = Boolean(query.print);
    const isPreview = pathname === '/vacancy/preview';

    const VIEW_LIMIT = isPrinting || isPreview ? Infinity : 5;
    const SLICE_COUNT = isPrinting || isPreview ? values.length : 3;

    const { isMobile } = useBreakpoint();

    const overrideDictionary: Record<string, string> = useMemo(
        () => ({
            OTHER: trls[TrlKeys.other],
        }),
        [trls]
    );

    const translated = useMemo(
        () =>
            dictionary.reduce<string[]>((acc, curr) => {
                if (!values.includes(curr.id)) {
                    return acc;
                }

                if (overrideDictionary[curr.id]) {
                    acc.push(overrideDictionary[curr.id]);
                } else {
                    acc.push(curr.text);
                }
                return acc;
            }, []),
        [dictionary, values, overrideDictionary]
    );

    const formatForFlyInFlyOutDuration = useCallback(
        ({ text, count, tooltip, bottomSheet }: Texts) => {
            const replaceLastShift = (str: string) => {
                const lastShifts = /[0-9]+(?!.*[0-9])/.exec(str);
                const lastShift = lastShifts?.[0];

                if (lastShift) {
                    return str.replace(lastShift, `${lastShift} ${trls[TrlKeys.shift]}`);
                }

                return str;
            };

            return {
                text: text ? replaceLastShift(`${trls[TrlKeys.on]} ${text}`) : '',
                count,
                tooltip: tooltip ? replaceLastShift(tooltip) : '',
                bottomSheet: bottomSheet ? replaceLastShift(bottomSheet) : '',
            };
        },
        [trls]
    );

    const { text, count, tooltip, bottomSheet } = useMemo(() => {
        let text = '';
        let count = '';

        let tooltip = '';
        let bottomSheet = '';

        const getEnumerationString = (arg?: string[]) =>
            (arg || translated).reduce<string>((acc, curr, index, source) => {
                const isFirst = index === 0;
                const isLast = index === source.length - 1;

                if (isFirst) {
                    acc += curr;
                } else if (isLast) {
                    const conjunction = curr === trls[TrlKeys.other] ? trls[TrlKeys.and] : trls[TrlKeys.or];

                    acc += ` ${conjunction} ${curr}`;
                } else {
                    acc += `, ${curr}`;
                }

                return acc;
            }, '');

        if (translated.length === 1) {
            text = translated[0];
        } else if (translated.length > VIEW_LIMIT) {
            text = translated.slice(0, SLICE_COUNT).join(', ');
            count = `${trls[TrlKeys.more]} ${values.length - SLICE_COUNT}`;

            tooltip = isMobile ? '' : getEnumerationString(translated.slice(SLICE_COUNT));
            bottomSheet = isMobile ? getEnumerationString() : '';
        } else {
            text = getEnumerationString();
        }

        if (field === 'flyInFlyOutDuration') {
            return formatForFlyInFlyOutDuration({ text, count, tooltip, bottomSheet });
        }

        return {
            text,
            count,
            tooltip,
            bottomSheet,
        };
    }, [field, isMobile, translated, values.length, VIEW_LIMIT, SLICE_COUNT, trls, formatForFlyInFlyOutDuration]);

    return {
        text: text.toLowerCase(),
        count: count.toLowerCase(),
        tooltip: tooltip.toLowerCase(),
        bottomSheet: bottomSheet.toLowerCase(),
    };
}
