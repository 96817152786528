import { FC } from 'react';

import FormatMoney from 'src/components/FormatMoney';
import { CurrencyType } from 'src/models/currencies.types';
import { NON_BREAKING_SPACE, NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

interface ClickmeAvailableMoneyProps {
    availableMoney: number;
    currency: CurrencyType;
}

const ClickmeAvailableMoney: FC<ClickmeAvailableMoneyProps> = ({ availableMoney, currency }) => {
    return (
        <span className={styles.availableMoney}>
            <FormatMoney
                currency={currency}
                groupSeparator={NARROW_NON_BREAKING_SPACE}
                inCents
                render={(amount, currencyNode) => (
                    <>
                        <span data-qa="clickme-account-balance">{amount}</span>
                        {NON_BREAKING_SPACE}
                        {currencyNode}
                    </>
                )}
            >
                {availableMoney}
            </FormatMoney>
        </span>
    );
};

export default ClickmeAvailableMoney;
