import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import VacancyBlockEmployer from 'src/components/VacancyView/VacancyBlock/Employer';
import VacancySidebarBanners from 'src/components/VacancyView/VacancySidebarBanners';

import RelatedVacanciesPreview from 'src/pages/VacancyView/components/RelatedVacanciesPreview';

const VacancySidebar = ({ fullListRef, vacancyBodyFooterNodeRef }) => {
    return (
        <div className="noprint">
            <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            <div>
                <VacancySidebarBanners />
                <Column xs="0" s="0" m="0" l="6">
                    <RelatedVacanciesPreview fullListRef={fullListRef} />
                </Column>
            </div>
        </div>
    );
};

VacancySidebar.propTypes = {
    fullListRef: PropTypes.object,
    vacancyBodyFooterNodeRef: PropTypes.object,
};

export default VacancySidebar;
