import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useEnableCompensationWithModeAndFrequency from 'src/hooks/useEnableCompensationWithModeAndFrequency';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    label: 'vacancy.view.compensation.frequency.label',
};

interface VacancyCompensationFrequencyProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyCompensationFrequency: TranslatedComponent<VacancyCompensationFrequencyProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const frequency = useSelector((state) => state.vacancyView.compensation.frequency);
    const frequencyDictionary = useSelector((state) => state.vacancyCompensationFieldsDictionary.frequency);
    const frequencyText = frequencyDictionary.find(({ id }) => id === frequency)?.text;

    const enableNewCompensation = useEnableCompensationWithModeAndFrequency();

    if (!enableNewCompensation || !frequencyText) {
        return null;
    }

    const textContent = `${trls[TrlKeys.label]}: ${frequencyText.toLowerCase()}`;

    if (isMagritteVacancy) {
        return (
            <Text Element="p" typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                {textContent}
            </Text>
        );
    }

    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
        >
            {textContent}
        </p>
    );
};

export default translation(VacancyCompensationFrequency);
