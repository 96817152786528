import { FC } from 'react';
import classnames from 'classnames';

import styles from './legend-point.less';

interface LegendPointProps {
    color: 'blue' | 'green';
}

const LegendPoint: FC<LegendPointProps> = ({ color }) => (
    <div
        className={classnames(styles.legendPoint, {
            [styles.legendPointGreen]: color === 'green',
            [styles.legendPointBlue]: color === 'blue',
        })}
    />
);

export default LegendPoint;
