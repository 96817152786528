import { useDispatch } from 'react-redux';

import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { isPauseCampaignStatus, isRestoreAction } from 'src/components/Auction/utils';
import { useNotification } from 'src/components/Notifications/Provider';
import saveSuccessfullyAuctionModalNotification from 'src/components/Notifications/VacancyAuctionSaveSuccessfullNotification';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import {
    AuctionCampaignStatus,
    AuctionFieldName,
    CurrentVacancyAuctionCampaign,
    defaultControlInfo,
} from 'src/models/auctionData';

import { changeStatusRequest } from 'src/components/Auction/Modals/AuctionEditingModal/ChangeStatusButton/changeStatusRequest';

const TrlKeys = {
    restore: 'auction.modal.restore',
    pause: 'auction.modal.pause',
};

interface ChangeStatusButtonProps {
    auctionLoading: boolean;
    onRestoreCampaign: () => void;
    onStopCampaign: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
}

const getParamsByStatus = (campaignStatus?: AuctionCampaignStatus) => {
    if (isPauseCampaignStatus(campaignStatus)) {
        return {
            newStatus: AuctionCampaignStatus.Active,
            style: 'accent',
            mode: 'primary',
            trlKey: TrlKeys.restore,
        } as const;
    }

    return {
        newStatus: AuctionCampaignStatus.Blocked,
        style: 'negative',
        mode: 'secondary',
        trlKey: TrlKeys.pause,
    } as const;
};

const ChangeStatusButton: TranslatedComponent<ChangeStatusButtonProps> = ({
    auctionLoading,
    onRestoreCampaign,
    onStopCampaign,
    onCampaignInfoUpdate,
    trls,
}) => {
    const currentVacancyAuctionCampaign = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign);
    const { addNotification } = useNotification();
    const bidControl =
        useSelector(({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid]) || defaultControlInfo;

    const [loading, startLoading, stopLoading] = useOnOffState(false);

    const dispatch = useDispatch();

    const editStatusButtonIsDisabled = !currentVacancyAuctionCampaign;
    const bidChanged = bidControl?.initialValue !== bidControl?.value;

    if (isRestoreAction(bidChanged, currentVacancyAuctionCampaign?.campaignStatus)) {
        return null;
    }

    const { newStatus, style, mode, trlKey } = getParamsByStatus(currentVacancyAuctionCampaign?.campaignStatus);

    const handleClickEditStatus = () => {
        startLoading();

        const onSuccessChangedStatus = (campaign: Partial<CurrentVacancyAuctionCampaign>) => {
            onCampaignInfoUpdate?.(campaign);
            if (isPauseCampaignStatus(currentVacancyAuctionCampaign?.campaignStatus)) {
                addNotification(saveSuccessfullyAuctionModalNotification);
                onRestoreCampaign();
            } else {
                onStopCampaign();
            }
        };

        dispatch(
            changeStatusRequest(
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                currentVacancyAuctionCampaign?.campaignId!,
                newStatus,
                onSuccessChangedStatus,
                addNotification
            )
        )
            .catch(console.error)
            .finally(stopLoading);
    };

    return (
        <Button
            size="medium"
            style={style}
            mode={mode}
            disabled={editStatusButtonIsDisabled}
            loading={auctionLoading || loading}
            onClick={handleClickEditStatus}
            data-qa="auction-change-status"
        >
            {trls[trlKey]}
        </Button>
    );
};

export default translation(ChangeStatusButton);
