import { createContext } from 'react';

import { DEFAULT_FILTERS_VALUES, VacanciesFilters } from 'src/models/employerVacancies/vacancy.types';

import { FilterAction } from 'src/components/EmployerVacancies/filterReducer';

export type DispatchFilter = ({ type, payload }: FilterAction) => void;

interface FilterContextType {
    filters: VacanciesFilters;
    dispatchFilter: DispatchFilter;
}

const DUMMY_FUNCTION = () => {
    // do nothing
};

const FilterContext = createContext<FilterContextType>({
    filters: DEFAULT_FILTERS_VALUES,
    dispatchFilter: DUMMY_FUNCTION,
});

export default FilterContext;
