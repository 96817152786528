import { BottomSheet, BottomSheetFooter, Modal, NavigationBar, Button, Action, ActionBar } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.confirmationModal.title',
    text: 'auction.confirmationModal.text',
    button: 'auction.confirmationModal.button',
};

interface ConfirmationModalProps {
    onModalClose: () => void;
}

const ConfirmationModal: TranslatedComponent<ConfirmationModalProps> = ({ trls, onModalClose }) => {
    const { visible, data } = useSelectorNonNullable(({ auctionConfirmationModal }) => auctionConfirmationModal);

    const modalContent = (
        <div className={styles.modalContentInner}>
            {formatToReactComponent(trls[TrlKeys.text], {
                '{0}': (
                    <FormatMoney currency={data.currency} inCents>
                        {data.price}
                    </FormatMoney>
                ),
            })}
        </div>
    );

    const modalButton = (
        <Button size="medium" style="accent" mode="primary" onClick={onModalClose}>
            {trls[TrlKeys.button]}
        </Button>
    );

    return (
        <>
            <Modal
                visible={visible}
                onClose={onModalClose}
                title={trls[TrlKeys.title]}
                actions={<Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                titleSize="small"
                footer={<ActionBar primaryActions={modalButton} />}
                data-qa="auction-confirmation-modal"
            >
                {modalContent}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                header={<NavigationBar title={trls[TrlKeys.title]} />}
                footer={<BottomSheetFooter>{modalButton}</BottomSheetFooter>}
                data-qa="auction-confirmation-bottom-sheet"
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(ConfirmationModal);
