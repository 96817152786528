import { useCallback, ReactNode, useState, ChangeEvent } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing, Segmented, Segment, useBreakpoint } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyBody from 'src/components/VacancyView/VacancyBody';
import VacancyBrandedBody from 'src/components/VacancyView/VacancyBrandedBody';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import TabPane from 'src/pages/VacancyView/components/VacancyTabs/TabPane';

import SearchSnippet from 'src/pages/VacancyView/components/VacancyMainTab/SearchSnippet';
import { SegmentSize, SegmentPane } from 'src/pages/VacancyView/components/VacancyMainTab/SearchSnippet/constants';
import useSnippetData from 'src/pages/VacancyView/components/VacancyMainTab/useSnippetData';
import useVacancySearchSnippet from 'src/pages/VacancyView/components/VacancyMainTab/useVacancySearchSnippet';

interface VacancyMainTabProps {
    employerReviews: ReactNode;
    backofficeRelatedVacancies: ReactNode;
    vacancySuitableResumesBanner?: ReactNode;
}

const TrlKeys = {
    segment: {
        main: 'employer.vacancy.segment.main.title',
        snippet: 'employer.vacancy.segment.snippet.title',
    },
};

const VacancyMainTab: TranslatedComponent<VacancyMainTabProps> = ({
    trls,
    employerReviews,
    backofficeRelatedVacancies,
    vacancySuitableResumesBanner,
}) => {
    const { isBranded } = useBranding();
    const [activePane, setActivePane] = useState(SegmentPane.Main);
    const vacancySearchSnippet = useVacancySearchSnippet();
    const showSearchSnippet = !!vacancySearchSnippet;
    const [resetSnippet, fetchSnippet] = useSnippetData();
    const { isXS, isS } = useBreakpoint();
    const systemInfo = useSelector((state) => state.vacancyView.systemInfo);
    const isShowSuitableResume = systemInfo && (isS || isXS);

    const CustomColumnsWrapper = useCallback(({ children }: { children: ReactNode }) => <>{children}</>, []);
    const AdditionalWrapper = useCallback(
        ({ children }: { children: ReactNode }) => (
            <div className="vacancy-branded-employer-additional-wrapper">{children}</div>
        ),
        []
    );
    const DescriptionWrapper = useCallback(
        ({ children }: { children: ReactNode }) => (
            <div className="vacancy-branded-employer-description-wrapper">{children}</div>
        ),
        []
    );

    const onSelectSnippetPane = (pane: SegmentSize) => {
        if (pane === SegmentSize.Full) {
            fetchSnippet();
        } else {
            resetSnippet();
        }
    };

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const pane = event.target.value as SegmentPane;
        if (pane === SegmentPane.Main) {
            resetSnippet();
        }
        setActivePane(pane);
        Analytics.sendHHEventButtonClick(`vacancy_view_${pane}_segment_select`, {
            vacancyId: vacancySearchSnippet?.vacancyId,
        });
    };

    const onSnownSegment = (element: HTMLDivElement) => {
        if (element) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_segment_control',
                vacancyId: vacancySearchSnippet?.vacancyId,
            });
        }
    };

    return (
        <>
            {showSearchSnippet && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <div ref={onSnownSegment}>
                            <Segmented mode="label" selectedValue={activePane} onChange={onSelect} stretched>
                                <Segment value={SegmentPane.Main} data-qa="segmented-item-main">
                                    {trls[TrlKeys.segment.main]}
                                </Segment>
                                <Segment value={SegmentPane.Snippet} data-qa="segmented-item-snippet">
                                    {trls[TrlKeys.segment.snippet]}
                                </Segment>
                            </Segmented>
                        </div>
                        <VSpacing default={24} />
                    </Column>
                </ColumnsRow>
            )}
            <TabPane index={SegmentPane.Main} value={activePane}>
                {isBranded ? (
                    <VacancyBrandedBody
                        CustomColumnsWrapper={CustomColumnsWrapper}
                        AdditionalWrapper={AdditionalWrapper}
                        DescriptionWrapper={DescriptionWrapper}
                        isEmployerView
                        newEmployerVacancyMap
                    />
                ) : (
                    <>
                        <VacancyBody isEmployerView />
                        {employerReviews}
                        {isShowSuitableResume && (
                            <>
                                <VSpacing default={24} />
                                <Column xs="4" s="8" m="0" l="0">
                                    {vacancySuitableResumesBanner}
                                </Column>
                            </>
                        )}
                        <VSpacing default={isShowSuitableResume ? 32 : 24} l={32} />
                        {backofficeRelatedVacancies}
                    </>
                )}
            </TabPane>
            {showSearchSnippet && (
                <TabPane index={SegmentPane.Snippet} value={activePane} destroyInactive>
                    <ColumnsRow>
                        <Column xs="4" s="8" m="8" l="10">
                            <SearchSnippet vacancy={vacancySearchSnippet} onSelectPane={onSelectSnippetPane} />
                            <VSpacing default={24} />
                        </Column>
                    </ColumnsRow>
                </TabPane>
            )}
        </>
    );
};

export default translation(VacancyMainTab);
