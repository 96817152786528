import { PropsWithChildren, FC } from 'react';
import classnames from 'classnames';

interface VacancySectionWrapperProps {
    hasWrapper?: boolean;
}

const VacancySectionWrapper: FC<VacancySectionWrapperProps & PropsWithChildren> = ({ hasWrapper, children }) => {
    if (hasWrapper) {
        const className = classnames('vacancy-section', 'vacancy-section_magritte');
        return <div className={className}>{children}</div>;
    }

    return <>{children}</>;
};

export default VacancySectionWrapper;
