import { useRef } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import auctionEditingBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_editing_banner_element_shown';
import { TooltipHover, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CampaignStatusText from 'src/components/Auction/Common/CampaignStatus/CampaignStatusText';
import HoverWarningTipToEdit from 'src/components/Auction/HoverWarningTipToEdit';
import useAuctionEditingModal from 'src/components/Auction/hooks/useAuctionEditingModal';
import { QS_PARAM_TO_OPEN_AUCTION } from 'src/components/Auction/utils';
import FlexibleRow from 'src/components/FlexibleRow';
import BannerWrapper from 'src/components/VacancyBannerWrapper';
import translation from 'src/components/translation';
import useRunEmployerVacancyAction from 'src/hooks/useRunEmployerVacancyAction';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'auction.title',
    status: 'auction.banner.status',
    clicks: 'auction.banner.clicks',
    edit: 'auction.banner.edit',
};

const AuctionStatistic: TranslatedComponent = ({ trls }) => {
    const campaignCreationStatus = useSelectorNonNullable(({ auctionData }) => auctionData.campaignCreationStatus);
    const campaignId = useSelectorNonNullable(({ auctionData }) =>
        String(auctionData.currentVacancyAuctionCampaign?.campaignId)
    );
    const campaignStatus = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign?.campaignStatus
    );

    const isVacancyFromMainCabinet = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign?.campaignAccountInfo.isMain
    );
    const clicks = useSelectorNonNullable(({ auctionData }) => auctionData.auctionStatistics.clicks);
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId!);

    const { showAuctionEditingModal, renderAuctionEditingModal } = useAuctionEditingModal({
        campaignId: String(campaignId),
        campaignStatus: campaignStatus!,
        campaignCreationStatus,
        vacancyId,
    });

    useRunEmployerVacancyAction(QS_PARAM_TO_OPEN_AUCTION, showAuctionEditingModal);

    const activatorRef = useRef<HTMLDivElement>(null);

    const auctionStatisticElement = (
        <BannerWrapper header={trls[TrlKeys.title]} dataQa="auction-editing-banner">
            <ElementShownAnchor
                fn={auctionEditingBannerElementShown}
                campaignId={campaignId}
                campaignStatus={campaignStatus}
                clicks={clicks}
                vacancyId={vacancyId}
            >
                <FlexibleRow>
                    <span>{trls[TrlKeys.status]}</span>
                    <CampaignStatusText dataQaPrefix="auction-statistics-banner" />
                </FlexibleRow>
                <VSpacing default={16} />
                <FlexibleRow>
                    <span>{trls[TrlKeys.clicks]}</span>
                    <span data-qa="auction-editing-banner-clicks">{clicks || '–'}</span>
                </FlexibleRow>
            </ElementShownAnchor>
        </BannerWrapper>
    );

    return (
        <>
            <div
                onClick={isVacancyFromMainCabinet ? showAuctionEditingModal : undefined}
                data-qa="open-edit-auction-modal"
            >
                <div ref={activatorRef}>{auctionStatisticElement}</div>
                {isVacancyFromMainCabinet && (
                    <TooltipHover placement="bottom-left" activatorRef={activatorRef}>
                        <HoverWarningTipToEdit />
                    </TooltipHover>
                )}
            </div>
            {renderAuctionEditingModal()}
        </>
    );
};

export default translation(AuctionStatistic);
