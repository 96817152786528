import { Fragment, RefObject, useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import { ContactInfo } from 'src/models/vacancyView.types';

import ContactsCallTracking from 'src/components/VacancyView/VacancyContactsCallTracking';
import { scrollWithAttention, SIGNUP_FORM_ATTENTION_CLASS } from 'src/components/VacancyView/scrolling';

const TrlKeys = {
    signUpText: 'vacancy.contactInfo.signup.text',
    signUpButton: 'vacancy.contactInfo.signup.button',
};

interface VacancyContactsDropContentProps {
    chatButtonInfo?: VacancyChatInfoType | null;
    contactInfo?: ContactInfo | null;
    address?: string;
    vacancyId: number;
    isAnonymous?: boolean;
    employerId?: number;
    chatWritePossibility?: string;
    loading: boolean;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    onClose?: () => void;
}

const VacancyContactsDropContent: TranslatedComponent<VacancyContactsDropContentProps> = ({
    chatButtonInfo,
    contactInfo,
    address,
    vacancyId,
    isAnonymous,
    employerId,
    chatWritePossibility,
    loading,
    vacancyBodyFooterNodeRef,
    onClose,
    trls,
}) => {
    const isZarplata = useIsZarplataPlatform();

    const sendEmailOnClickAnalytics = useCallback(() => {
        Analytics.sendHHEvent('applicantEmailIntent', {
            from: 'vacancy',
            vacancy_id: String(vacancyId),
        });
    }, [vacancyId]);

    const sendPhoneOnClickAnalytics = useCallback(() => {
        Analytics.sendHHEvent('applicantCallIntent', {
            from: 'vacancy',
            vacancy_id: String(vacancyId),
        });
        Analytics.sendEvent('applicant', 'call-from-vacancy');
        if (isZarplata) {
            Analytics.addEventPixels('ZP_CALL');
        }
    }, [isZarplata, vacancyId]);

    const renderPhoneComment = (comment?: string) => {
        if (!comment) {
            return null;
        }
        return (
            <>
                {', '}
                <AsyncHighlighter>{comment}</AsyncHighlighter>
            </>
        );
    };

    const phones = contactInfo?.phones?.phones;

    if (loading) {
        return <Loading scale={LoadingScale.Small} />;
    }

    if (isAnonymous) {
        return (
            <>
                <div className="vacancy-contacts-signup-text">{trls[TrlKeys.signUpText]}</div>
                <VSpacing base={3} />
                <Button
                    kind={ButtonKind.Primary}
                    onClick={() => {
                        if (vacancyBodyFooterNodeRef?.current) {
                            scrollWithAttention(vacancyBodyFooterNodeRef.current, SIGNUP_FORM_ATTENTION_CLASS);
                        }
                        onClose?.();
                    }}
                >
                    {trls[TrlKeys.signUpButton]}
                </Button>
            </>
        );
    }

    if (contactInfo?.callTrackingEnabled) {
        return (
            <ContactsCallTracking
                chatButtonInfo={chatButtonInfo}
                contactInfo={contactInfo}
                vacancyId={vacancyId}
                address={address}
                onPhoneClick={sendPhoneOnClickAnalytics}
                onEmailClick={sendEmailOnClickAnalytics}
                onClose={onClose}
                employerId={employerId}
                chatWritePossibility={chatWritePossibility}
            />
        );
    }

    return (
        <div>
            {contactInfo?.fio && (
                <div data-qa="vacancy-contacts__fio">
                    <AsyncHighlighter>{contactInfo.fio}</AsyncHighlighter>
                    <VSpacing base={1} />
                </div>
            )}
            {phones?.map((phone, index) => {
                const { country, city, number, comment } = phone;
                const formattedPhone = `+${country}\u00a0(${city})\u00a0${number}`;
                return (
                    <Fragment key={index}>
                        <p data-qa="vacancy-contacts__phone" className="vacancy-contacts__phone-desktop">
                            {formattedPhone}
                            {renderPhoneComment(comment)}
                        </p>
                        <div className="vacancy-contacts__phone-mobile">
                            <BlokoLink
                                disableVisited
                                Element={Link}
                                to={`tel:+${country}${city}${number}`}
                                onClick={sendPhoneOnClickAnalytics}
                            >
                                {formattedPhone}
                            </BlokoLink>
                            {renderPhoneComment(comment)}
                        </div>
                    </Fragment>
                );
            })}
            <VSpacing base={1} />
            {contactInfo?.email && (
                <Link
                    to={`mailto:${contactInfo.email}`}
                    rel="nofollow"
                    data-qa="vacancy-contacts__email"
                    onClick={sendEmailOnClickAnalytics}
                >
                    {contactInfo.email}
                </Link>
            )}
        </div>
    );
};

export default translation(VacancyContactsDropContent);
