import { Text, Title, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';

export const checkVacancyDriverLicenseRender = ({ vacancyView }: AppStore): boolean =>
    !!vacancyView.driverLicenseTypes?.driverLicenseType?.length;

const TrlKeys = {
    driverExperience: 'vacancy.view.driverExperience',
    driverLicense: 'vacancy.view.driverLicense',
};

interface VacancyDriverLicenseProps {
    hasVacancySectionWrapper?: boolean;
    isEmployerView?: boolean;
}

const VacancyDriverLicense: TranslatedComponent<VacancyDriverLicenseProps> = ({
    hasVacancySectionWrapper = true,
    trls,
}) => {
    const driverLicenseTypes = useSelector(({ vacancyView }) => vacancyView.driverLicenseTypes);
    const showDriverLicense = useSelector(checkVacancyDriverLicenseRender);

    if (!driverLicenseTypes?.driverLicenseType || !showDriverLicense) {
        return null;
    }

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="small">
                {trls[TrlKeys.driverExperience]}
            </Title>
            <VSpacing default={16} />
            <Text Element="span" typography="label-2-regular">
                {trls[TrlKeys.driverLicense]} {driverLicenseTypes.driverLicenseType.join(', ')}
            </Text>
        </VacancySectionWrapper>
    );
};

export default translation(VacancyDriverLicense);
