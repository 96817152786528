import {
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    Button,
    Action,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AuctionContentBudgetReplenishShown from 'src/components/Auction/Analytics/AuctionContentBudgetReplenishShown';
import AvailableMoney from 'src/components/Auction/Common/AuctionControls/AvailableMoney/AvailableMoney';
import BudgetBanner from 'src/components/Auction/Common/BudgetBanner';
import translation from 'src/components/translation';
import { CurrentVacancyAuctionCampaign } from 'src/models/auctionData';

import Control from 'src/components/Auction/Modals/BudgetReplenishmentModal/Control';
import SubmitButton from 'src/components/Auction/Modals/BudgetReplenishmentModal/SubmitButton';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.replenishment.title',
    back: 'Cancel',
};

interface BudgetReplenishmentModalProps {
    visible: boolean;
    onCloseBudgetReplenishment: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
    onCloseAll: () => void;
    onClose: () => void;
}

const BudgetReplenishmentModal: TranslatedComponent<BudgetReplenishmentModalProps> = ({
    trls,
    visible,
    onCloseBudgetReplenishment,
    onCloseAll,
    onCampaignInfoUpdate,
    onClose,
}) => {
    const modalContent = (
        <div className={styles.auctionReplenishmentContent}>
            <BudgetBanner />
            <VSpacing default={24} />
            <Control />
            <VSpacing default={32} />
            <AvailableMoney />
            <VSpacing default={8} />
        </div>
    );

    const cancelButton = (
        <Button
            size="medium"
            style="accent"
            mode="secondary"
            onClick={onCloseBudgetReplenishment}
            data-qa="auction-cancel-button"
        >
            {trls[TrlKeys.back]}
        </Button>
    );

    const submitButton = (
        <SubmitButton onClose={onClose} onCloseAll={onCloseAll} onCampaignInfoUpdate={onCampaignInfoUpdate} />
    );

    return (
        <AuctionContentBudgetReplenishShown>
            <Modal
                visible={visible}
                onClose={onCloseBudgetReplenishment}
                title={trls[TrlKeys.title]}
                actions={<Action mode="secondary" onClick={onCloseBudgetReplenishment} icon={CrossOutlinedSize24} />}
                titleSize="medium"
                size="small"
                footer={
                    <ActionBar
                        primaryActions={
                            <>
                                {cancelButton}
                                {submitButton}
                            </>
                        }
                    />
                }
                data-qa="auction-replenishment-modal"
            >
                {modalContent}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={visible}
                onClose={onCloseBudgetReplenishment}
                header={
                    <NavigationBar
                        title={trls[TrlKeys.title]}
                        right={
                            <Action mode="secondary" onClick={onCloseBudgetReplenishment} icon={CrossOutlinedSize24} />
                        }
                    />
                }
                footer={
                    <BottomSheetFooter>
                        {submitButton}
                        {cancelButton}
                    </BottomSheetFooter>
                }
                data-qa="auction-replenishment-bottom-sheet"
            >
                {modalContent}
            </BottomSheet>
        </AuctionContentBudgetReplenishShown>
    );
};

export default translation(BudgetReplenishmentModal);
