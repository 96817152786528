import { FC } from 'react';

import AvailableToFreeProlongate from 'src/components/VacancyModals/Prolongation/AvailableToFreeProlongate';
import AvailableToPurchase from 'src/components/VacancyModals/Prolongation/AvailableToPurchase';
import AvailableToSpend from 'src/components/VacancyModals/Prolongation/AvailableToSpend';
import Unavailable from 'src/components/VacancyModals/Prolongation/Unavailable';

interface ContentProps {
    handleClickMoreDetails: () => void;
}

const Content: FC<ContentProps> = ({ handleClickMoreDetails }) => (
    <>
        <Unavailable handleClickMoreDetails={handleClickMoreDetails} />
        <AvailableToSpend />
        <AvailableToPurchase />
        <AvailableToFreeProlongate />
    </>
);

export default Content;
