import { Dispatch, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { ADD_MODAL, MODAL_VACANCY_ADVICE } from 'src/components/VacancyModals/constants';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';

const setPreparedAdviceWithStatistics = makeSetStoreField('preparedAdviceWithStatistics');

export interface UseShowAdviceModal {
    dispatchModal: Dispatch<AnyAction>;
    vacancyId: string;
    vacancyName: string;
    vacancyAreaName: string;
}

const useShowAdviceModal = ({ dispatchModal, vacancyId, vacancyName, vacancyAreaName }: UseShowAdviceModal) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const runActionArgument = useSelector((state) => state.runActionArgument);
    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const adviceWithStatistics = useSelector((state) => state.preparedAdviceWithStatistics);
    const compensation = useSelector((state) => state.vacancyView.compensation);

    const setAdvices = useCallback(
        (advices: VacancyAdviceWithStatistics[]) => {
            dispatch(setPreparedAdviceWithStatistics(advices));
        },
        [dispatch]
    );

    const openModal = useCallback(
        (from: 'link' | 'button') => {
            if (!canvacVacancySalaryData) {
                return;
            }

            Analytics.sendHHEventButtonClick(`important_banner_advice_${from}`, {
                vacancyId,
            });

            const { marketRange, salaryRange, numberOfSimilarVacancies } = canvacVacancySalaryData;

            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_VACANCY_ADVICE,
                    data: {
                        vacancyId,
                        vacancyName,
                        vacancyAreaName,
                        adviceWithStatistics,
                        setAdvices,
                        compensation,
                        marketRange,
                        salaryRange,
                        numberOfSimilarVacancies,
                    },
                },
            });
        },
        [
            vacancyId,
            vacancyName,
            vacancyAreaName,
            adviceWithStatistics,
            setAdvices,
            compensation,
            canvacVacancySalaryData,
            dispatchModal,
        ]
    );

    useEffect(() => {
        if (runActionArgument === 'showAdvice') {
            openModal('link');
            const currentSearchParams = new URLSearchParams(location.search);
            currentSearchParams.delete('runAction');
            history.replaceState(null, '', `?${String(currentSearchParams)}${location.hash}`);
        }
    }, [openModal, location.search, location.hash, runActionArgument]);

    return {
        openModal,
    };
};

export default useShowAdviceModal;
