import { useDispatch } from 'react-redux';

import { FormLabel, Text, FormHelper, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Bid from 'src/components/Auction/Common/AuctionControls/Bid';
import Error from 'src/components/Auction/Common/AuctionControls/Error';
import { validateAuctionControls } from 'src/components/Auction/Common/AuctionControls/validateAuctionControls';
import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldName, updateAuctionControlInfo } from 'src/models/auctionData';

const TrlKeys = {
    bid: 'auction.controls.bid',
    bidInfo: {
        0: 'auction.controls.bid.info.0',
        1: 'auction.controls.bid.info.1',
    },
};

const BidControl: TranslatedComponent = ({ trls }) => {
    const bidControl = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid]);
    const budgetControl = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Budget]);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);

    const dispatch = useDispatch();

    const updateValue = (value: string, showError: boolean) => {
        const errors = validateAuctionControls({
            bid: value,
            budget: budgetControl.value,
            minBid: bidControl.minValue,
            minBudget: budgetControl.minValue,
        });

        dispatch([
            updateAuctionControlInfo({
                fieldName: AuctionFieldName.Bid,
                value,
                error: errors[AuctionFieldName.Bid],
                showError: showError && !!errors[AuctionFieldName.Bid],
            }),
        ]);
    };

    const handleChange = (value: string) => {
        updateValue(value, false);
    };

    const handlePlusMinusClick = (value: string) => {
        updateValue(value, true);
    };

    return (
        <>
            <FormLabel
                info={
                    <>
                        {trls[TrlKeys.bidInfo[0]]}
                        <VSpacing default={8} />
                        {trls[TrlKeys.bidInfo[1]]}
                    </>
                }
            >
                <Text typography="label-2-regular" style="secondary">
                    {trls[TrlKeys.bid]}
                </Text>
            </FormLabel>
            <VSpacing default={8} />
            <Bid
                inversed
                invalid={bidControl.showError}
                error={bidControl.error}
                maxLength={7}
                name={AuctionFieldName.Bid}
                min={bidControl.minValue}
                value={bidControl.value}
                onChange={handleChange}
                onBlur={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Bid,
                            showError: Boolean(bidControl.error),
                        })
                    );
                }}
                onFocus={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Bid,
                            showError: false,
                        })
                    );
                }}
                postfix={<Currency value={currency} />}
                onPlusOrMinusClick={handlePlusMinusClick}
                data-qa="auction-bid-control"
                minusDataQa="bid-minus"
                plusDataQa="bid-plus"
            />
            <FormHelper
                invalid={bidControl.showError}
                errorMessage={<Error error={bidControl.error} />}
                topSpacing={8}
            />
        </>
    );
};

export default translation(BidControl);
