import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyConversionInfo from 'src/components/VacancyConversionInfo';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';

interface ConversionBannerProps {
    vacancyId: string;
}

const ConversionBanner: TranslatedComponent<ConversionBannerProps> = ({ vacancyId }) => {
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_conversion',
                vacancyId,
            });
        }
    }, [vacancyId]);

    if (!conversion) {
        return null;
    }

    return (
        <Link
            to={`/employer/statistics/vacancy_conversion?vacancyId=${String(vacancyId)}`}
            target="_blank"
            onClick={() => {
                Analytics.sendHHEventButtonClick('important_banner_conversion_button', {
                    vacancyId,
                });
            }}
        >
            <BannerWrapper dataQa="vacancy-important-banner-conversion">
                <div ref={ref}>
                    <VacancyConversionInfo conversion={conversion} />
                </div>
            </BannerWrapper>
        </Link>
    );
};

export default translation(ConversionBanner);
