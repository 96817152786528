import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios, { Canceler } from 'axios';

import useDebounce from 'bloko/common/hooks/useDebounce';

import fetchCalculatedProduct from 'src/api/fetchCalculatedProduct';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { setProductInfoLoading, setProductInfoData, AuctionFieldError } from 'src/models/auctionData';
import { ClickmeProductType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';
import fetcher from 'src/utils/fetcher';

interface UseFetchProductProps {
    replenishment: number;
    error?: AuctionFieldError;
}

const useFetchProduct = ({ error, replenishment }: UseFetchProductProps): void => {
    const currentVacancyAuctionCampaign = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign
    );
    const useAvailableMoney = useSelectorNonNullable(({ auctionData }) => auctionData.useAvailableMoney || false);
    const availableMoney = useSelectorNonNullable(({ auctionData }) => auctionData.availableMoney || 0);

    const dispatch = useDispatch();

    const cancelFetchProductRef = useRef<Canceler | null>(null);
    const fetchProductDebouncedRef = useRef(
        useDebounce((count: number) => {
            const cancelToken = new axios.CancelToken((cancel) => {
                cancelFetchProductRef.current = cancel;
            });

            dispatch(setProductInfoLoading(true));
            fetchCalculatedProduct({
                // count должен быть в рублях
                params: { code: ServiceItemCode.Clickme, count: Math.round(count / 100).toString(), isAuction: true },
                cancelToken,
            })
                .then(
                    (data) => {
                        dispatch(setProductInfoData(data as ClickmeProductType));
                    },
                    (error) => {
                        if (fetcher.isCancel(error)) {
                            return;
                        }

                        console.error(error);
                    }
                )
                .finally(() => {
                    dispatch(setProductInfoLoading(false));
                });
        }, 500)
    );

    const isFirstProductRequestRef = useRef(true);
    useEffect(() => {
        if (isFirstProductRequestRef.current) {
            isFirstProductRequestRef.current = false;
            return;
        }
        cancelFetchProductRef.current?.();

        if (replenishment && !error) {
            const superActualBudget = useAvailableMoney
                ? replenishment - Math.min(replenishment, availableMoney)
                : replenishment;

            if (superActualBudget > 0) {
                fetchProductDebouncedRef.current(superActualBudget);
            } else {
                fetchProductDebouncedRef.current.cancel();
                dispatch([setProductInfoData(null), setProductInfoLoading(false)]);
            }
        } else {
            dispatch(setProductInfoLoading(false));
            fetchProductDebouncedRef.current.cancel();
        }
    }, [availableMoney, currentVacancyAuctionCampaign, dispatch, error, useAvailableMoney, replenishment]);
};

export default useFetchProduct;
