import { AuctionFieldError, AuctionFieldName } from 'src/models/auctionData';

interface ValidateAuctionControlsArgs {
    bid: string;
    budget: string;
    minBid: number;
    minBudget: number;
}

type ErrorByAuctionFieldName = Record<AuctionFieldName, undefined | AuctionFieldError>;

export const validateAuctionControls = ({
    bid,
    budget,
    minBid,
    minBudget,
}: ValidateAuctionControlsArgs): ErrorByAuctionFieldName => {
    const errors = {
        [AuctionFieldName.Bid]: undefined,
        [AuctionFieldName.Budget]: undefined,
    } as ErrorByAuctionFieldName;

    if (bid === '') {
        errors[AuctionFieldName.Bid] = AuctionFieldError.BidRequired;
    }
    if (budget === '') {
        errors[AuctionFieldName.Budget] = AuctionFieldError.BudgetRequired;
    }
    if (bid && Number(bid) < minBid) {
        errors[AuctionFieldName.Bid] = AuctionFieldError.MinBidValue;
    }
    if (budget && Number(budget) < minBudget) {
        errors[AuctionFieldName.Budget] = AuctionFieldError.MinBudgetValue;
    }
    if (budget && !errors[AuctionFieldName.Budget] && Number(budget) % 10000) {
        errors[AuctionFieldName.Budget] = AuctionFieldError.InvalidBudget;
    }
    if (bid && budget && !errors[AuctionFieldName.Bid] && Number(bid) > Number(budget)) {
        errors[AuctionFieldName.Bid] = AuctionFieldError.InvalidBid;
    }

    return errors;
};
