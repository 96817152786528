import { formatToReactComponent, TranslatedComponent, translation, useSelector } from '@hh.ru/front-static-app';
import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { toDate } from 'bloko/blocks/calendar/datesHelper';

import { formatDatesInterval } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/utils';
import LinkToFullChart from 'src/pages/VacancyView/components/VacancyStatTab/LinkToFullChart';

import styles from './chart-header.less';

const TrlKeys = {
    dateRangeHeader: 'employer.vacancy.tab.stat.dateRange',
    link: 'employer.vacancy.tab.stat.link',
};

const ChartHeader: TranslatedComponent = ({ trls }) => {
    const { data } = useSelector((state) => state.vacancyConversionStatistics);
    const { isXS } = useBreakpoint();

    if (!data) {
        return <div />;
    }

    const startDate = toDate(data.startDate);
    const endDate = toDate(data.endDate);

    return (
        <div className={styles.chartHeaderWrap}>
            <Text typography="label-3-regular" style="secondary">
                {formatToReactComponent(trls[TrlKeys.dateRangeHeader], {
                    '{0}': formatDatesInterval(startDate, endDate, isXS),
                })}
            </Text>
            <LinkToFullChart vacancyId={data.vacancyId} text={isXS ? '' : trls[TrlKeys.link]} />
        </div>
    );
};

export default translation(ChartHeader);
