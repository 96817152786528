import { ReactNode, useCallback } from 'react';

import { Text as MagritteText } from '@hh.ru/magritte-ui';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import VerificationForm, {
    VerificationFormBaseProps,
    VerificationFormRenderProps,
} from 'src/components/Verification/VerificationForm';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';

import Heading from 'src/components/VacancyView/SignUpBlock/Form/Heading';

import styles from './verification-step.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        description: 'otp.confirm.sub.header',
    },
    whatsApp: {
        sent: 'signUpForm.verification.codeSent.whatsApp',
    },
};

const getHeader = (authType?: string) => {
    switch (authType) {
        case 'SMS':
            return TrlKeys.phone.sent;
        case 'WHATS_APP':
            return TrlKeys.whatsApp.sent;
        default:
            return TrlKeys.email.sent;
    }
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const isMagritte = useMagritte();
    const { authType, login = '' } = formProps;

    const renderParagraph = useCallback(
        (content: ReactNode) =>
            isMagritte ? (
                <MagritteText Element="p" typography="paragraph-2-regular" style="secondary">
                    {content}
                </MagritteText>
            ) : (
                <p>{content}</p>
            ),
        [isMagritte]
    );

    const render = ({ renderInput, renderCodeError, renderCodeSender, renderSubmit }: VerificationFormRenderProps) => {
        const input = renderInput({
            placeholder: codePlaceholder,
            isAutofocus: true,
            size: isMagritte ? 'medium' : undefined,
        });
        const button = renderSubmit({
            isStretched: true,
            size: isMagritte ? 'medium' : undefined,
        });
        const isSmsAuthType = authType === 'SMS';
        const formattedCodeSent = formatToReactComponent(trls[getHeader(authType)], {
            '{0}': isMagritte ? login : <strong>{login}</strong>,
        });
        const headingContent = isMagritte ? formattedCodeSent : trls[TrlKeys.header];

        return (
            <div className={isMagritte ? undefined : styles.wrapper}>
                <Heading>{headingContent}</Heading>
                <VSpacing base={2} />
                {!isMagritte && renderParagraph(formattedCodeSent)}
                <VSpacing base={2} />
                {isSmsAuthType ? (
                    <>
                        {renderParagraph(trls[TrlKeys.phone.description1])}
                        {renderParagraph(trls[TrlKeys.phone.description2])}
                    </>
                ) : (
                    renderParagraph(trls[TrlKeys.email.description])
                )}
                <VSpacing base={6} xs={4} />
                <div className={styles.formContainer}>
                    <div className={styles.inputWrapper}>{input}</div>
                    <div className={styles.buttonWrapper}>{button}</div>
                </div>
                <div>
                    {renderCodeError()}
                    <VSpacing base={6} xs={4} />
                    <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                </div>
            </div>
        );
    };

    return <VerificationForm render={render} {...formProps} />;
};

export default translation(VerificationStep);
