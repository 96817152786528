import {
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    Button,
    Action,
    VSpacing,
    Loader,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import AuctionContentStatisticsShown from 'src/components/Auction/Analytics/AuctionContentStatisticsShown';
import BidRating from 'src/components/Auction/Common/BidRating/BidRating';
import BudgetBanner from 'src/components/Auction/Common/BudgetBanner';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CurrentVacancyAuctionCampaign } from 'src/models/auctionData';

import BidControl from 'src/components/Auction/Modals/AuctionEditingModal/BidControl';
import ChangeStatusButton from 'src/components/Auction/Modals/AuctionEditingModal/ChangeStatusButton/ChangeStatusButton';
import SaveButton from 'src/components/Auction/Modals/AuctionEditingModal/SaveButton';
import StatisticsBanner from 'src/components/Auction/Modals/AuctionEditingModal/StatisticsBanner/StatisticsBanner';
import VacancyAndArea from 'src/components/Auction/Modals/AuctionEditingModal/VacancyAndArea';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.title',
    howItWorksTrigger: 'auction.howItWorks.trigger',
    budgetOpen: 'auction.edit.openBudgetModal',
};

interface AuctionEditingModalProps {
    visible: boolean;
    onClose: () => void;
    loading: boolean;
    showBudgetModal: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
    isWebView: boolean;
}
const AuctionEditingModal: TranslatedComponent<AuctionEditingModalProps> = ({
    onClose,
    visible,
    loading,
    showBudgetModal,
    onCampaignInfoUpdate,
    trls,
    isWebView = false,
}) => {
    const { pathname, query } = useSelector(({ router }) => router.location);
    const push = usePush();

    const pushBackUrlOnSaveOrClose = (saved: boolean, hasError?: boolean, closed?: boolean) => {
        const url = urlParser(pathname);
        const { error, ...restQuery } = query;
        url.params = {
            ...restQuery,
            saved,
            ...(hasError ? { error: true } : {}),
            ...(closed ? { closed: true } : {}),
        };
        push(url.href);
    };

    const onSave = () => {
        if (isWebView) {
            pushBackUrlOnSaveOrClose(true);
        } else {
            onClose();
        }
    };

    const onError = () => {
        if (isWebView) {
            pushBackUrlOnSaveOrClose(false, true);
        } else {
            onClose();
        }
    };

    const onStopCampaign = () => {
        if (isWebView) {
            pushBackUrlOnSaveOrClose(true);
        }
    };

    const onModalClose = isWebView ? () => pushBackUrlOnSaveOrClose(false, false, true) : onClose;

    const modalContent = (
        <>
            {loading ? (
                <div className={styles.modalLoaderWrapper}>
                    <Loader size={24} />
                </div>
            ) : (
                <div className={styles.modalContentWrapper}>
                    <div className={styles.modalContentStatistics}>
                        <StatisticsBanner />
                    </div>
                    <AuctionContentStatisticsShown>
                        <BudgetBanner
                            button={
                                <Button
                                    size="medium"
                                    style="accent"
                                    mode="secondary"
                                    onClick={showBudgetModal}
                                    data-qa="open-auction-replenishment"
                                >
                                    {trls[TrlKeys.budgetOpen]}
                                </Button>
                            }
                        />
                        <VSpacing default={16} />
                        <BidRating>
                            <VSpacing default={20} />
                            <BidControl />
                        </BidRating>
                    </AuctionContentStatisticsShown>
                </div>
            )}
            <VSpacing default={24} gteS={0} />
        </>
    );

    const stopButton = (
        <ChangeStatusButton
            auctionLoading={loading}
            onStopCampaign={onStopCampaign}
            onRestoreCampaign={onSave}
            onCampaignInfoUpdate={onCampaignInfoUpdate}
        />
    );

    const saveButton = (
        <SaveButton
            auctionLoading={loading}
            onSave={onSave}
            onError={onError}
            onCampaignInfoUpdate={onCampaignInfoUpdate}
        />
    );

    return (
        <>
            <Modal
                visible={visible}
                onClose={onModalClose}
                title={trls[TrlKeys.title]}
                titleDescription={<VacancyAndArea />}
                actions={!isWebView && <Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                titleSize="medium"
                size="large"
                footer={
                    <ActionBar
                        primaryActions={
                            <>
                                {stopButton}
                                {saveButton}
                            </>
                        }
                    />
                }
                data-qa="auction-editing-modal"
            >
                {modalContent}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                header={
                    <NavigationBar
                        title={trls[TrlKeys.title]}
                        subtitle={<VacancyAndArea />}
                        right={
                            !isWebView ? (
                                <Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />
                            ) : undefined
                        }
                    />
                }
                footer={
                    <BottomSheetFooter>
                        {saveButton}
                        {stopButton}
                    </BottomSheetFooter>
                }
                data-qa="auction-editing-bottom-sheet"
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(AuctionEditingModal);
