import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import isValidKey from 'bloko/common/isValidKey';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldError, AuctionFieldName } from 'src/models/auctionData';

const TrlKeys = {
    [AuctionFieldError.InvalidBid]: 'auction.controls.error.invalidBid',
    [AuctionFieldError.InvalidBudget]: 'auction.controls.error.invalidBudget',
    [AuctionFieldError.MinBidValue]: 'auction.controls.error.minBidValue',
    [AuctionFieldError.MinBudgetValue]: 'auction.controls.error.minBudgetValue',
    [AuctionFieldError.BidRequired]: 'auction.controls.error.bidRequired',
    [AuctionFieldError.BudgetRequired]: 'auction.controls.error.budgetRequired',
    [AuctionFieldError.MinReplenishmentValue]: 'auction.controls.error.minReplenishmentValue',
    [AuctionFieldError.ReplenishmentRequired]: 'auction.controls.error.replenishmentRequired',
    [AuctionFieldError.InvalidReplenishment]: 'auction.controls.error.invalidReplenishment',
};

interface ErrorProps {
    error: AuctionFieldError | undefined;
}

const Error: TranslatedComponent<ErrorProps> = ({ error, trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const minBid = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid].minValue);
    const minBudget = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Budget].minValue
    );
    const minReplenishment = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Replenishment]?.minValue
    );

    if (!error) {
        return null;
    }

    const minValueErrorsMap = {
        [AuctionFieldError.MinBidValue]: minBid,
        [AuctionFieldError.MinBudgetValue]: minBudget,
        [AuctionFieldError.MinReplenishmentValue]: minReplenishment,
    };

    if (isValidKey(minValueErrorsMap, error)) {
        const minValue = minValueErrorsMap[error];

        return (
            <div data-qa="auction-controls-error">
                {formatToReactComponent(trls[TrlKeys[error]], {
                    '{0}': (
                        <FormatMoney currency={currency} inCents>
                            {minValue}
                        </FormatMoney>
                    ),
                })}
            </div>
        );
    }

    return <div data-qa="auction-controls-error">{trls[TrlKeys[error]]}</div>;
};

export default translation(Error);
