import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import { FormHelper } from '@hh.ru/magritte-ui';

import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'src/components/Auction/utils';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldName, updateAuctionControlInfo, setProductInfoLoading } from 'src/models/auctionData';

import AuctionDummyControls from 'src/components/Auction/Common/AuctionControls/AuctionDummyControls/AuctionDummyControls';
import Error from 'src/components/Auction/Common/AuctionControls/Error';
import Hint from 'src/components/Auction/Common/AuctionControls/Hint';
import { validateAuctionControls } from 'src/components/Auction/Common/AuctionControls/validateAuctionControls';

const AuctionControls: FC = () => {
    const bidControlInfo = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid]);
    const budgetControlInfo = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Budget]
    );
    const dispatch = useDispatch();

    const validateAndChangeAuctionControls = useCallback(
        (bid: string, budget: string, activeFieldName: AuctionFieldName) => {
            const minBid = bidControlInfo.minValue;
            const minBudget = budgetControlInfo.minValue;
            const errors = validateAuctionControls({ bid, budget, minBid, minBudget });

            dispatch([
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Bid,
                    value: bid,
                    error: errors[AuctionFieldName.Bid],
                    showError: activeFieldName !== AuctionFieldName.Bid && Boolean(errors[AuctionFieldName.Bid]),
                }),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Budget,
                    value: budget,
                    error: errors[AuctionFieldName.Budget],
                    showError: activeFieldName !== AuctionFieldName.Budget && Boolean(errors[AuctionFieldName.Budget]),
                }),
            ]);
        },
        [bidControlInfo.minValue, budgetControlInfo.minValue, dispatch]
    );

    const budgetPlusOrMinusClick = useCallback(
        (bid: string, budget: string) => {
            const minBid = bidControlInfo.minValue;
            const minBudget = budgetControlInfo.minValue;
            const errors = validateAuctionControls({ bid, budget, minBid, minBudget });

            dispatch([
                setProductInfoLoading(true),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Bid,
                    value: bid,
                    error: errors[AuctionFieldName.Bid],
                    showError: !!errors[AuctionFieldName.Bid],
                }),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Budget,
                    value: budget,
                    error: errors[AuctionFieldName.Budget],
                    showError: !!errors[AuctionFieldName.Budget],
                }),
            ]);
        },
        [bidControlInfo.minValue, budgetControlInfo.minValue, dispatch]
    );

    return (
        <AuctionDummyControls
            bidError={bidControlInfo.error}
            bidShowError={bidControlInfo.showError}
            bidValue={bidControlInfo.value}
            minBid={bidControlInfo.minValue}
            budgetError={budgetControlInfo.error}
            budgetShowError={budgetControlInfo.showError}
            budgetValue={budgetControlInfo.value}
            minBudget={budgetControlInfo.minValue}
            onBidBlur={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Bid,
                        showError: Boolean(bidControlInfo.error),
                    })
                );
            }}
            onBidChange={(bid) => {
                validateAndChangeAuctionControls(bid, budgetControlInfo.value, AuctionFieldName.Bid);
            }}
            onBidFocus={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Bid,
                        showError: false,
                    })
                );
            }}
            onBudgetBlur={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Budget,
                        showError: Boolean(budgetControlInfo.error),
                    })
                );
            }}
            onBudgetChange={(budget) => {
                dispatch(setProductInfoLoading(true));
                validateAndChangeAuctionControls(bidControlInfo.value, budget, AuctionFieldName.Budget);
                debouncedAuctionControlsEdit(dispatch(getInputChangeAnalyticsParams({ budget })));
            }}
            onBudgetFocus={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Budget,
                        showError: false,
                    })
                );
            }}
            onBudgetPlusOrMinusClick={(budget) => {
                budgetPlusOrMinusClick(bidControlInfo.value, budget);
                debouncedAuctionControlsEdit(dispatch(getInputChangeAnalyticsParams({ budget })));
            }}
            renderBidError={() => (
                <FormHelper
                    invalid={bidControlInfo.showError}
                    errorMessage={<Error error={bidControlInfo.error} />}
                    topSpacing={8}
                />
            )}
            renderBudgetError={() => (
                <FormHelper
                    invalid={budgetControlInfo.showError}
                    errorMessage={<Error error={budgetControlInfo.error} />}
                    topSpacing={8}
                />
            )}
            renderFewResponsesCard={() => (
                <Hint
                    bid={bidControlInfo.value}
                    budget={budgetControlInfo.value}
                    initialBid={bidControlInfo.initialValue}
                    initialBudget={budgetControlInfo.initialValue}
                    onClick={(recommendedBudget) => {
                        dispatch(setProductInfoLoading(true));
                        validateAndChangeAuctionControls(
                            bidControlInfo.value,
                            String(recommendedBudget),
                            AuctionFieldName.Budget
                        );
                    }}
                />
            )}
        />
    );
};

export default AuctionControls;
