import { useRef } from 'react';

import { Button as MagritteButton } from '@hh.ru/magritte-ui';
import { PrinterOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';

const TrlKeys = {
    print: 'vacancy.actions.print',
    printShort: 'vacancy.actions.print.short',
};

interface PrintButtonProps {
    id?: number | null;
    draft?: boolean;
    showHidden?: boolean;
    redesigned?: boolean;
}

const PrintButton: TranslatedComponent<PrintButtonProps> = ({
    id = null,
    draft = false,
    showHidden,
    redesigned = false,
    trls,
}) => {
    const paramName = draft ? 'draftId' : 'id';
    const params = urlParser.stringify({ [paramName]: id, print: 'true', showHidden: showHidden ? 'true' : null });
    const activatorRef = useRef(null);

    if (redesigned) {
        return (
            <MagritteButton
                mode="tertiary"
                style="accent"
                Element={Link}
                to={`/vacancy/view?${params}`}
                target="_blank"
                data-qa="vacancy-print-view"
            >
                {trls[TrlKeys.printShort]}
            </MagritteButton>
        );
    }

    return (
        <HoverTip
            host={process.env.SSR ? null : document.body}
            layer={TipLayer.Topmost}
            render={() => trls[TrlKeys.print]}
            activatorRef={activatorRef}
        >
            <Button
                icon={
                    <BlokoIconReplaceContainer>
                        <PrinterOutlinedSize16 initial="secondary" />
                    </BlokoIconReplaceContainer>
                }
                Element={Link}
                to={`/vacancy/view?${params}`}
                target="_blank"
                data-qa="vacancy-print-view"
                innerRef={activatorRef}
            />
        </HoverTip>
    );
};

export default translation(PrintButton);
