import { useSelectorNonNullable } from 'src/hooks/useSelector';

export enum SubmitButtonType {
    None = 'None',
    ProlongateAndBuy = 'ProlongateAndBuy',
    Prolongate = 'Prolongate',
    Purchase = 'Purchase',
}

const useSubmitButtons = (): SubmitButtonType => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);

    const anyVacancyCanBeProlongated = availableToFreeProlongateIds.length > 0 || availableToSpendIds.length > 0;

    if (purchase && anyVacancyCanBeProlongated) {
        return SubmitButtonType.ProlongateAndBuy;
    }

    if (purchase) {
        return SubmitButtonType.Purchase;
    }

    if (anyVacancyCanBeProlongated) {
        return SubmitButtonType.Prolongate;
    }

    return SubmitButtonType.None;
};

export default useSubmitButtons;
