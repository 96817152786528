import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import usePostMessages from 'src/hooks/usePostMessages';

const IFrame = forwardRef(({ src, callbacks, ...rest }, ref) => {
    usePostMessages(callbacks);

    return <iframe ref={ref} {...rest} src={src} />;
});

IFrame.propTypes = {
    src: PropTypes.string,
    callbacks: PropTypes.object,
};

export default IFrame;
