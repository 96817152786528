import { Link as MagritteLink, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    show: 'vacancy.view.suitable.link.show',
    resume: 'vacancy.view.suitable.link.resume',
    one: 'vacancy.view.suitable.link.suitable.one',
    some: 'vacancy.view.suitable.link.suitable.many',
    many: 'vacancy.view.suitable.link.suitable.many',
    ending: 'vacancy.view.suitable.link.ending',
};

export const checkVacancySimilarResumesRender = ({ resumeSearchResult }: AppStore): boolean =>
    !!resumeSearchResult.totalResults;

const VacancySimilarResumes: TranslatedComponent = ({ trls }) => {
    const similarResumes = useSelector((state) => state.resumeSearchResult);
    const show = useSelector(checkVacancySimilarResumesRender);
    const totalResults = similarResumes.totalResults;

    if (!show || typeof totalResults !== 'number') {
        return null;
    }

    const url = urlParser(paths.resumeSearch);
    url.params = {
        vacancy_id: [similarResumes.searchCriteria?.vacancy_id],
        order_by: ['relevance'],
        from: ['vacancy'],
    };

    return (
        <div className="noprint">
            <MagritteLink Element={Link} to={url.href} data-qa="vacancy-similar-resumes">
                {trls[TrlKeys.show]} {totalResults} {trls[TrlKeys.resume]},&nbsp;
                <Conversion
                    hasValue={false}
                    value={totalResults}
                    one={trls[TrlKeys.one]}
                    some={trls[TrlKeys.some]}
                    many={trls[TrlKeys.many]}
                />{' '}
                {trls[TrlKeys.ending]}
            </MagritteLink>
            <VSpacing default={24} />
        </div>
    );
};

export default translation(VacancySimilarResumes);
