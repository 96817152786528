import { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import auctionModalOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_open_button_click';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import AuctionCreationModal from 'src/components/Auction/Modals/AuctionCreationModal/AuctionCreationModal';
import HowItWorksModal from 'src/components/Auction/Modals/HowItWorksModal';
import { auctionError, AuctionErrorType } from 'src/components/Notifications/EmployerAuction';
import { useNotification } from 'src/components/Notifications/Provider';
import useOnOffState from 'src/hooks/useOnOffState';
import {
    AuctionData,
    DisabledAuctionData,
    CAMPAIGN_CREATION_ENABLED_STATUSES,
    CampaignCreationStatus,
    CreatedAuctionData,
} from 'src/models/auctionData';
import fetcher from 'src/utils/fetcher';

const setAuctionData = makeSetStoreField('auctionData');

const GET_AUCTION_DATA_URL = '/shards/vacancy/auction/by_vacancy_id';

declare global {
    interface FetcherGetApi {
        [GET_AUCTION_DATA_URL]: {
            queryParams: {
                vacancyId: string;
            };
            response: AuctionData | DisabledAuctionData;
        };
    }
}

type UseAuctionCreationModal = (params: {
    vacancyId: string;
    campaignCreationStatus: CampaignCreationStatus;
    isWebView?: boolean;
}) => {
    showAuctionCreationModal: () => void;
    renderAuctionCreationModal: (onCampaignCreated?: (data: CreatedAuctionData) => void) => ReactNode;
};

const useAuctionCreationModal: UseAuctionCreationModal = ({ vacancyId, campaignCreationStatus, isWebView = false }) => {
    const [auctionModalVisible, showAuctionModal, hideAuctionModal] = useOnOffState(false);
    const [howItWorkModalVisible, showHowItWorksModal, hideHowItWorksModal] = useOnOffState(false);
    const [loading, startLoading, finishLoading] = useOnOffState(false);
    const { addNotification } = useNotification();

    const onShowHowItWorksModal = useCallback(() => {
        hideAuctionModal();
        showHowItWorksModal();
    }, [hideAuctionModal, showHowItWorksModal]);

    const onCloseHowItWorksModal = useCallback(() => {
        hideHowItWorksModal();
        showAuctionModal();
    }, [hideHowItWorksModal, showAuctionModal]);

    const dispatch = useDispatch();

    const showAuctionCreationModal = useCallback(async () => {
        try {
            startLoading();
            showAuctionModal();
            auctionModalOpenButtonClick({
                vacancyId,
                campaignCreationStatus,
                // Тут ещё не создана кампания
                campaignId: undefined,
                campaignStatus: undefined,
            });
            const auctionData = await fetcher.get(GET_AUCTION_DATA_URL, {
                params: {
                    vacancyId,
                },
            });
            if (!CAMPAIGN_CREATION_ENABLED_STATUSES.includes(auctionData.campaignCreationStatus)) {
                hideAuctionModal();
                addNotification(auctionError, { props: { errorType: AuctionErrorType.AlreadyCreated } });
                return;
            }
            dispatch(setAuctionData(auctionData as AuctionData));
        } catch (error) {
            hideAuctionModal();
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            finishLoading();
        }
    }, [
        campaignCreationStatus,
        dispatch,
        addNotification,
        finishLoading,
        hideAuctionModal,
        showAuctionModal,
        startLoading,
        vacancyId,
    ]);

    const renderAuctionCreationModal = useCallback(
        (onCampaignCreated?: (data: CreatedAuctionData) => void) => (
            <>
                {auctionModalVisible && (
                    <AuctionCreationModal
                        isWebView={isWebView}
                        hideModal={hideAuctionModal}
                        loading={loading}
                        showHowItWorksModal={onShowHowItWorksModal}
                        visible={auctionModalVisible}
                        onCampaignCreated={onCampaignCreated}
                    />
                )}
                <HowItWorksModal onClose={onCloseHowItWorksModal} visible={howItWorkModalVisible} />
            </>
        ),
        [
            auctionModalVisible,
            hideAuctionModal,
            howItWorkModalVisible,
            loading,
            onCloseHowItWorksModal,
            onShowHowItWorksModal,
            isWebView,
        ]
    );
    return { showAuctionCreationModal, renderAuctionCreationModal };
};

export default useAuctionCreationModal;
