import { CancelToken } from 'axios';
import { Dispatch } from 'redux';

import auctionControlsEdit from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_controls_edit';

import { getInputChangeAnalyticsParams } from 'src/components/Auction/utils';
import { BidRatingData, BidRatingRequestParams, setBidRatingData, setBidRatingLoading } from 'src/models/auctionData';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherGetApi {
        '/shards/vacancy/auction/bid_rating': {
            queryParams: BidRatingRequestParams & { bid: number };
            response: BidRatingData;
        };
    }
}

interface FetchBidRatingArgs {
    params: BidRatingRequestParams & { bid: number; vacancyId: string | null };
    cancelToken: CancelToken;
}

export const fetchBidRating =
    ({ params, cancelToken }: FetchBidRatingArgs) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setBidRatingLoading(true));

        return fetcher
            .get('/shards/vacancy/auction/bid_rating', { params, cancelToken })
            .then(
                (data) => {
                    auctionControlsEdit(
                        dispatch(
                            getInputChangeAnalyticsParams({
                                bid: String(params.bid),
                            })
                        )
                    );
                    dispatch(setBidRatingData(data));
                },
                (error) => {
                    if (fetcher.isCancel(error)) {
                        return;
                    }

                    console.error(error);
                }
            )
            .finally(() => {
                dispatch(setBidRatingLoading(false));
            });
    };
