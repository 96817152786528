import { useCallback, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Form from 'src/components/Form';
import CreateHiringManagerButton from 'src/components/HiringManager/CreateHiringManagerButton';
import SubmitButton from 'src/components/HiringManager/SubmitButton';
import HiringManagerSuggest from 'src/components/HiringManager/Suggest';
import { useShareVacancy } from 'src/components/HiringManager/api/useShareVacancy';
import { useFetchHiringManagers } from 'src/components/HiringManager/hooks/useFetchHiringManagers';
import { VacancyShareFormData } from 'src/components/HiringManager/types';
import translation from 'src/components/translation';
import { ManagersList } from 'src/models/employerManagersPage';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';

import VacancyShareSettings from 'src/components/HiringManager/VacancyShareModal/VacancyShareSettings';

const TrlKeys = {
    title: {
        addHiringManager: 'employer.vacancy.addHiringManager.modal.title',
        shareVacancy: {
            one: 'employer.vacancy.modal.title.one',
            some: 'employer.vacancy.modal.title.some',
            many: 'employer.vacancy.modal.title.many',
            noCount: 'employer.vacancy.modal.title.noCount',
        },
    },
    description: {
        addHiringManager: 'employer.vacancy.addHiringManager.modal.description',
        shareVacancy: 'employer.vacancy.share.modal.titleDescription',
    },
    createHiringManager: 'employer.vacancy.share.modal.button.createHiringManager',
    share: 'employer.vacancy.share.modal.button.share',
    requiredError: 'employer.hiringManager.suggest.requiredError',
};

export interface VacancyShareModalProps {
    kind?: 'addHiringManager' | 'shareVacancy';
    vacanciesIds: string[];
    vacanciesNames: string[];
    isVisible: boolean;
    handleCloseModal: () => void;
    onSuccessSubmit?: (selectedManagers: ManagersList[], selectedPermissions: VacancyPermission[]) => void;
}

const VACANCY_SHARE_SETTINGS_FORM_ID = 'vacancy-share-settings';

const VacancyShareModal: TranslatedComponent<VacancyShareModalProps> = ({
    trls,
    kind = 'shareVacancy',
    handleCloseModal,
    isVisible,
    vacanciesIds,
    vacanciesNames,
    onSuccessSubmit,
}) => {
    const [selectedManagers, setSelectedManagers] = useState<ManagersList[]>([]);
    const { isMobile } = useBreakpoint();
    const leftButton = (
        <CreateHiringManagerButton
            shareVacancy
            onClick={handleCloseModal}
            vacanciesIds={vacanciesIds}
            vacanciesNames={vacanciesNames}
        />
    );
    const rightButton = <SubmitButton formId={VACANCY_SHARE_SETTINGS_FORM_ID} kind="shareVacancy" />;

    const required = useCallback(
        (value: string[]) => (value?.length ? undefined : trls[TrlKeys.requiredError]),
        [trls]
    );

    const onClose = useCallback(() => {
        handleCloseModal();
        setSelectedManagers(() => []);
    }, [handleCloseModal]);
    const onSubmit = useShareVacancy({
        selectedManagers,
        vacanciesIds,
        vacanciesNames,
        onClose,
        onSuccessSubmit,
    });
    const { isLoading, hiringManagers, activeManagers } = useFetchHiringManagers(isVisible, vacanciesIds);

    const content = (
        <FinalForm<VacancyShareFormData>
            onSubmit={onSubmit}
            initialValues={{
                [VacancyPermission.ViewDesirableCompensation]: false,
                [VacancyPermission.ViewContacts]: false,
            }}
        >
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} id={VACANCY_SHARE_SETTINGS_FORM_ID}>
                    <Field<string[]> name="hiringManager" validate={required}>
                        {({ input, meta }) => (
                            <HiringManagerSuggest
                                isLoading={isLoading}
                                hiringManagers={hiringManagers}
                                activeManagers={activeManagers}
                                description={isMobile && trls[TrlKeys.description[kind]]}
                                setSelectedManagers={setSelectedManagers}
                                isInvalid={meta.touched && meta.invalid}
                                error={meta.error as string}
                                filterHiringManagers={vacanciesIds.length === 1}
                                {...input}
                            />
                        )}
                    </Field>
                    <VacancyShareSettings selectedManagers={selectedManagers} />
                </Form>
            )}
        </FinalForm>
    );

    const title =
        kind === 'addHiringManager' ? (
            trls[TrlKeys.title.addHiringManager]
        ) : (
            <Conversion
                hasValue={false}
                value={vacanciesIds.length}
                one={trls[TrlKeys.title.shareVacancy.one]}
                some={trls[TrlKeys.title.shareVacancy.some]}
                many={trls[TrlKeys.title.shareVacancy.many]}
                format={(trl) =>
                    format(vacanciesIds.length === 1 ? trls[TrlKeys.title.shareVacancy.noCount] : trl, {
                        '{0}': vacanciesIds.length,
                    })
                }
            />
        );

    return (
        <>
            <Modal
                visible={isVisible && !isMobile}
                onClose={onClose}
                footer={<ActionBar primaryActions={rightButton} secondaryActions={leftButton} />}
                title={title}
                titleSize="medium"
                titleDescription={trls[TrlKeys.description[kind]]}
                titleDescriptionStyle="secondary"
                actions={[<Action key="close" onClick={onClose} icon={CrossOutlinedSize24} mode="secondary" />]}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isVisible && isMobile}
                onClose={onClose}
                header={<NavigationBar title={title} />}
                footer={
                    <BottomSheetFooter>
                        {leftButton}
                        {rightButton}
                    </BottomSheetFooter>
                }
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(VacancyShareModal);
