import { useRef } from 'react';

import { TooltipHover, VSpacing } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './prolongate-info-subtitle.less';

const TrlKeys = {
    infoMain: 'employer.myVacancies.prolongate.info',
    infoZp: 'employer.myVacancies.prolongate.infoZp',
    infoOnlyFree: 'employer.myVacancies.prolongate.infoOnlyFree',
    infoHover: {
        0: 'employer.myVacancies.prolongate.info.hover.0',
        1: 'employer.myVacancies.prolongate.info.hover.1',
        2: 'employer.myVacancies.prolongate.info.hover.2',
    },
};

interface ProlongateInfoSubtitleProps {
    onHoverAppear?: () => void;
}

const ProlongateInfoSubtitle: TranslatedComponent<ProlongateInfoSubtitleProps> = ({ trls, onHoverAppear }) => {
    const spendLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds.length
    );
    const freeLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds.length
    );
    const purchaseLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds.length
    );
    const unavailableLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.unavailable.vacancyIds.length
    );
    const activatorRef = useRef<HTMLDivElement>(null);

    const isConsistFreeProlongation = !!freeLength;
    const isOnlyFreeProlongation = isConsistFreeProlongation && !spendLength && !purchaseLength && !unavailableLength;

    const subtitleWithIcon = isConsistFreeProlongation ? trls[TrlKeys.infoZp] : trls[TrlKeys.infoMain];
    const subtitle = isOnlyFreeProlongation ? trls[TrlKeys.infoOnlyFree] : subtitleWithIcon;

    return (
        <>
            {subtitle}
            {NON_BREAKING_SPACE}
            {!isOnlyFreeProlongation && (
                <>
                    <div className={styles.icon} ref={activatorRef}>
                        <InfoCircleOutlinedSize16 initial="secondary" />
                    </div>
                    <TooltipHover placement="bottom-center" activatorRef={activatorRef} onAppear={onHoverAppear}>
                        <>
                            {trls[TrlKeys.infoHover[0]]}
                            <VSpacing default={8} />
                            {trls[TrlKeys.infoHover[1]]}
                            <VSpacing default={8} />
                            {trls[TrlKeys.infoHover[2]]}
                        </>
                    </TooltipHover>
                </>
            )}
        </>
    );
};

export default translation(ProlongateInfoSubtitle);
