import { useCallback, MutableRefObject, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import {
    Button,
    Card,
    Text,
    Title,
    VSpacingContainer,
    useBreakpoint as useMagritteBreakpoint,
} from '@hh.ru/magritte-ui';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'src/components/NoIndex';
import VacancyContactsDrop from 'src/components/VacancyView/VacancyContactsDrop';
import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';
import useContactsAnalytics from 'src/components/VacancyView/hooks/useContactsAnalytics';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { vacancySetVisibilityContactsAction } from 'src/models/vacancyView';

import checkRender from 'src/components/VacancyView/VacancyContacts/checkRender';

const TrlKeys = {
    contacts: 'vacancy.contactInfo.opened',
    showContactsButton: 'vacancy.action.showContacts',
    title: 'vacancy.contactInfo.title',
    contactPerson: 'vacancy.contactInfo.contactPerson',
};

interface VacancyContactsRedesignedProps {
    vacancyBodyFooterNodeRef?: MutableRefObject<HTMLDivElement>;
    blockRef: MutableRefObject<HTMLDivElement>;
    hasVacancySectionWraper?: boolean;
}
const VacancyContactsRedesigned: TranslatedComponent<VacancyContactsRedesignedProps> = ({
    vacancyBodyFooterNodeRef,
    blockRef,
    hasVacancySectionWraper = true,
    trls,
}) => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userType);
    const isAnonymous = userType === UserType.Anonymous;
    const breakpoint = useBreakpoint();
    const { isMobile } = useMagritteBreakpoint();
    const show = useSelector((state) => checkRender(state, breakpoint));
    const sendViewContactsAnalytics = useContactsAnalytics({ place: 'vacancy_bottom_button' });
    const activatorRef = useRef(null);
    const {
        isVisibleContacts,
        vacancyId,
        company: { id: employerId },
        contactInfo,
    } = useSelector((state) => state.vacancyView);

    const handleOpen = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(true));
        sendViewContactsAnalytics({ vacancyId, employerId, userType });
    }, [dispatch, employerId, sendViewContactsAnalytics, userType, vacancyId]);

    if (!show) {
        return null;
    }

    const renderContacts = () => {
        const fio = contactInfo?.fio ?? '';
        const isFioShown = Boolean(fio) && !isAnonymous;

        const button = (
            <VacancyContactsDrop
                show={isVisibleContacts}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                place="bottom"
                activatorRef={activatorRef}
            >
                <Button
                    style="accent"
                    mode="secondary"
                    onClick={handleOpen}
                    stretched={isMobile}
                    data-qa="show-employer-contacts show-employer-contacts_bottom-button"
                    ref={activatorRef}
                >
                    {trls[TrlKeys.showContactsButton]}
                </Button>
            </VacancyContactsDrop>
        );

        if (isMobile) {
            return (
                <Card stretched showBorder padding={12} borderRadius={24}>
                    <Card stretched padding={12} paddingBottom={24}>
                        {isFioShown ? (
                            <VSpacingContainer default={6}>
                                <Text Element="p" typography="label-1-regular">
                                    {fio}
                                </Text>
                                <Text Element="p" typography="label-3-regular" style="secondary">
                                    {trls[TrlKeys.contactPerson]}
                                </Text>
                            </VSpacingContainer>
                        ) : (
                            <Text Element="p" typography="label-1-regular">
                                {trls[TrlKeys.title]}
                            </Text>
                        )}
                    </Card>
                    {button}
                </Card>
            );
        }

        return (
            <VSpacingContainer default={16}>
                <Title Element="h2" size="small">
                    {trls[TrlKeys.title]}
                </Title>
                {isFioShown && (
                    <Text Element="p" typography="label-2-regular">
                        {fio}
                    </Text>
                )}
                <div>{button}</div>
            </VSpacingContainer>
        );
    };

    return (
        <NoIndex>
            <div
                ref={blockRef}
                className={classnames('vacancy-contacts', {
                    noprint: !isVisibleContacts,
                })}
            >
                <VacancySectionWrapper hasWrapper={hasVacancySectionWraper}>{renderContacts()}</VacancySectionWrapper>
            </div>
        </NoIndex>
    );
};

export default translation(VacancyContactsRedesigned);
