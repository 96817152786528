import { useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { VacancyConversionStatistics, VacancyPublicationType } from 'src/models/vacancyConversionStatistics';

import Axis from 'src/pages/VacancyView/components/VacancyStatTab/Chart/Axis';
import ChartHeader from 'src/pages/VacancyView/components/VacancyStatTab/Chart/ChartHeader';
import Legend from 'src/pages/VacancyView/components/VacancyStatTab/Chart/Legend';
import LineChart, { Line } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/LineChart';
import RectangleChart, {
    RectangleChartIcon,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart';
import Color from 'src/pages/VacancyView/components/VacancyStatTab/Chart/color';
import {
    preparePublicationData,
    prepareProlongationIcons,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/utils';

import styles from './chart.less';

interface ChartProps {
    data: VacancyConversionStatistics;
}

const CHART_HEIGHT = 90;
const AXIS_X_HEIGHT = 24;
const CHART_PUBLICATIONS_HEIGHT = 8;

const TrlKeys = {
    publicationType: {
        [VacancyPublicationType.Standard]: 'statportal.pages.vacancyConversion.publicationType.standard',
        [VacancyPublicationType.StandardPlus]: 'statportal.pages.vacancyConversion.publicationType.standardPlus',
        [VacancyPublicationType.Premium]: 'statportal.pages.vacancyConversion.publicationType.premium',
        [VacancyPublicationType.Free]: 'statportal.pages.vacancyConversion.publicationType.free',
        [VacancyPublicationType.StandardAnonymous]:
            'statportal.pages.vacancyConversion.publicationType.standardAnonymous',
        [VacancyPublicationType.StandardPlusAnonymous]:
            'statportal.pages.vacancyConversion.publicationType.standardPlusAnonymous',
        [VacancyPublicationType.PremiumAnonymous]:
            'statportal.pages.vacancyConversion.publicationType.premiumAnonymous',
        [VacancyPublicationType.StandardAdvertising]:
            'statportal.pages.vacancyConversion.publicationType.standardAdvertising',
        [VacancyPublicationType.PremiumAdvertising]:
            'statportal.pages.vacancyConversion.publicationType.premiumAdvertising',
        [VacancyPublicationType.ZPAnonymous]: 'statportal.pages.vacancyConversion.publicationType.ZPAnonymous',
        [VacancyPublicationType.ZPDemo]: 'statportal.pages.vacancyConversion.publicationType.ZPDemo',
        [VacancyPublicationType.ZPPromo]: 'statportal.pages.vacancyConversion.publicationType.ZPPromo',
        [VacancyPublicationType.ZPBusiness]: 'statportal.pages.vacancyConversion.publicationType.ZPBusiness',
        [VacancyPublicationType.PFP]: 'statportal.pages.vacancyConversion.publicationType.PFP',
    },
};

const Chart: TranslatedComponent<ChartProps> = ({ data, trls }) => {
    const interval = { startDate: data.startDate, endDate: data.endDate };

    const chartRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_chart',
        vacancyId: data.vacancyId,
    });

    const { isXS } = useBreakpoint();

    const viewChartData =
        data.points.map((point) => ({
            date: point.date,
            value: point.viewCount,
            additional: {
                ...point,
            },
        })) ?? [];

    const responseChartData =
        data.points.map((point) => ({
            date: point.date,
            value: point.responseCount,
            additional: {
                isFake: point?.isFake,
            },
        })) ?? [];

    const mobileChartDimension = 4;
    const desktopChartDimension = responseChartData.length > 13 ? 2 : 0;
    const chartDimension = isXS ? mobileChartDimension : desktopChartDimension;

    const getPublicationTranslate = (type: VacancyPublicationType) => trls[TrlKeys.publicationType[type]];
    const preparedPublications = preparePublicationData(data.publications, interval, getPublicationTranslate);
    const prolongationIcons = prepareProlongationIcons(data.publications);

    const isShowPublications = Boolean(preparedPublications.length);

    return (
        <div className={styles.chart} data-qa="tab-stat-chart" ref={chartRef}>
            <ChartHeader />
            <LineChart data={viewChartData} height={CHART_HEIGHT} chartDimension={chartDimension} isCurveChart>
                <Line gradient color={Color.Blue60} showPoints showPointsLabel />
            </LineChart>
            <LineChart data={responseChartData} height={CHART_HEIGHT} chartDimension={chartDimension} isCurveChart>
                <Line gradient color={Color.Green80} showPoints showPointsLabel />
            </LineChart>
            <LineChart data={viewChartData} height={AXIS_X_HEIGHT} chartDimension={chartDimension}>
                <Axis />
            </LineChart>
            {isShowPublications && (
                <LineChart data={responseChartData} height={CHART_PUBLICATIONS_HEIGHT}>
                    <RectangleChart data={preparedPublications} background={Color.Gray10} />
                    <RectangleChartIcon data={prolongationIcons} />
                </LineChart>
            )}
            <div className={styles.chartLegendWrap}>
                <Legend data={preparedPublications} showProlongationIcon={Boolean(prolongationIcons.length)} />
            </div>
        </div>
    );
};

export default translation(Chart);
