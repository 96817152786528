import { FC } from 'react';

import ProlongateAndBuyButton from 'src/components/VacancyModals/Prolongation/ProlongateAndBuyButton';
import ProlongateButton from 'src/components/VacancyModals/Prolongation/ProlongateButton';
import PurchaseButton from 'src/components/VacancyModals/Prolongation/PurchaseButton';
import useSubmitButtons, { SubmitButtonType } from 'src/components/VacancyModals/Prolongation/useSubmitButtons';

interface SubmitButtonsProps {
    isTimerVisible: boolean;
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    additionalAnalyticsParams?: Record<string, string>;
}

const SubmitButtons: FC<SubmitButtonsProps> = ({
    isTimerVisible,
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    additionalAnalyticsParams = {},
}) => {
    const submitButtonType = useSubmitButtons();

    if (submitButtonType === SubmitButtonType.ProlongateAndBuy) {
        return (
            <ProlongateAndBuyButton
                isTimerVisible={isTimerVisible}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    if (submitButtonType === SubmitButtonType.Purchase) {
        return (
            <PurchaseButton
                isTimerVisible={isTimerVisible}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    if (submitButtonType === SubmitButtonType.Prolongate) {
        return (
            <ProlongateButton
                isTimerVisible={isTimerVisible}
                afterSuccessAction={afterSuccessAction}
                handleCloseModal={handleCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
        );
    }

    return null;
};

export default SubmitButtons;
