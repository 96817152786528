import { Dispatch } from 'redux';

import auctionControlsEdit from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_controls_edit';
import {
    Action,
    CampaignStatus,
} from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_edit_campaign_button_click';
import debounce from 'bloko/common/debounce';

import { NonNullableAppStore } from 'src/app/store';
import {
    AuctionCampaignStatus,
    AuctionData,
    AuctionDataWithCampaign,
    AuctionFieldName,
    CampaignCreationStatus,
    CampaignDraft,
    CurrentVacancyAuctionCampaign,
} from 'src/models/auctionData';

export const isActiveCampaignStatus = (campaignStatus?: AuctionCampaignStatus): boolean =>
    campaignStatus === AuctionCampaignStatus.Active;

export const isPauseCampaignStatus = (campaignStatus?: AuctionCampaignStatus): boolean =>
    campaignStatus === AuctionCampaignStatus.Blocked;

export const isCampaignLowBalanceCampaignStatus = (campaignStatus?: AuctionCampaignStatus): boolean =>
    campaignStatus === AuctionCampaignStatus.CampaignLowBalance;

export const isAccountLowBalanceCampaignStatus = (campaignStatus?: AuctionCampaignStatus): boolean =>
    campaignStatus === AuctionCampaignStatus.AccountLowBalance;

export const QS_PARAM_TO_OPEN_AUCTION = 'useSearchTop';
export const QS_PARAM_TO_OPEN_AUCTION_BUDGET_REPLENISH = 'openBudget';

interface BaseAnalyticParams {
    vacancyId?: string;
    campaignId?: string;
    campaignDraftId?: string;
    campaignCreationStatus: CampaignCreationStatus;
    draftId?: number;
    campaignStatus?: AuctionCampaignStatus;
}
interface InputChangeAnalyticParams extends BaseAnalyticParams {
    bid?: string;
    budget?: string;
}
interface AnalyticsProps {
    budget?: string;
    bid?: string;
}
export const getInputChangeAnalyticsParams =
    ({ bid, budget }: AnalyticsProps = {}) =>
    (dispatch: Dispatch, getState: () => NonNullableAppStore): InputChangeAnalyticParams => {
        const { auctionData, vacancyCreateDraftId } = getState();
        const { vacancyId, campaignDraft, currentVacancyAuctionCampaign, campaignCreationStatus } = auctionData;
        const campaignId = currentVacancyAuctionCampaign ? String(currentVacancyAuctionCampaign.campaignId) : undefined;
        const campaignDraftId = campaignDraft ? String(campaignDraft.campaignDraftId) : undefined;
        const campaignStatus = currentVacancyAuctionCampaign?.campaignStatus;
        return {
            vacancyId,
            campaignId,
            campaignDraftId,
            campaignStatus,
            campaignCreationStatus,
            draftId: vacancyCreateDraftId,
            bid,
            budget,
        };
    };

interface EditCampaignClickAnalyticParams {
    vacancyId: string;
    campaignId: string;
    bidInCents: number;
    budgetInCents: number;
    budgetDiffInCents: number;
    campaignStatus: CampaignStatus;
    maxBidAmongSimilarVacancies: number;
    useAvailableMoney: boolean;
    availableMoneyInCents: number;
    action: Action;
}

export const getEditCampaignClickAnalyticsParams =
    (action: Action) =>
    (dispatch: Dispatch, getState: () => NonNullableAppStore): EditCampaignClickAnalyticParams => {
        const auctionData = getState().auctionData as AuctionDataWithCampaign;
        const { controls, bidRating, useAvailableMoney, availableMoney } = auctionData;
        const budget = Number(controls[AuctionFieldName.Budget].value);
        const { vacancyId, currentVacancyAuctionCampaign } = auctionData;
        const campaignId = String(currentVacancyAuctionCampaign.campaignId);
        const campaignStatus = currentVacancyAuctionCampaign.campaignStatus;

        return {
            vacancyId,
            campaignId,
            campaignStatus,
            bidInCents: Number(controls[AuctionFieldName.Bid].value),
            budgetInCents: budget,
            budgetDiffInCents: Number(budget) - Number(currentVacancyAuctionCampaign?.budget),
            maxBidAmongSimilarVacancies: bidRating.data.maxBidAmongSimilarVacancies!,
            useAvailableMoney,
            availableMoneyInCents: availableMoney,
            action,
        };
    };

export const debouncedAuctionControlsEdit = debounce((props: InputChangeAnalyticParams) => {
    auctionControlsEdit(props);
}, 300);

export const isRestoreAction = (bidChanged: boolean, campaignStatus: AuctionCampaignStatus | undefined): boolean =>
    bidChanged && isPauseCampaignStatus(campaignStatus);

interface GetNormalizedAuctionFieldsReturnType {
    canCreateAuction: boolean;
    canEditAuction: boolean;
    isAuctionDraft: boolean;
    campaignStatus: CurrentVacancyAuctionCampaign['campaignStatus'];
    campaignId: CurrentVacancyAuctionCampaign['campaignId'];
    campaignCreationStatus: AuctionData['campaignCreationStatus'];
    campaignDraftId: CampaignDraft['campaignDraftId'];
    auctionNullableAnalyticsData: {
        campaignId: CurrentVacancyAuctionCampaign['campaignId'] | null;
        campaignDraftId: number | null;
        campaignStatus: CurrentVacancyAuctionCampaign['campaignStatus'] | null;
    };
    bid?: CurrentVacancyAuctionCampaign['bid'];
    budget?: CurrentVacancyAuctionCampaign['budget'];
    minBudget?: AuctionData['controls'][AuctionFieldName.Budget]['minValue'];
    campaignBalance?: CurrentVacancyAuctionCampaign['campaignBalance'];
    currency?: AuctionData['currency'];
    isVacancyFromAnotherCabinet: boolean;
}

export const getNormalizedAuctionFields = (auctionData: AuctionData | null): GetNormalizedAuctionFieldsReturnType => {
    let canCreateAuction = false;
    let canEditAuction = false;
    let isAuctionDraft = false;
    const isVacancyFromAnotherCabinet = false;

    const auctionNullableAnalyticsData = {
        campaignId: null,
        campaignDraftId: null,
        campaignStatus: null,
    };

    const fallbackCampaignStatus = AuctionCampaignStatus.Blocked;
    const fallbackCampaignId = 0;
    const fallbackCampaignCreationStatus = CampaignCreationStatus.NotCreated;
    const fallbackCampaignDraftId = '';
    if (!auctionData) {
        return {
            canCreateAuction,
            canEditAuction,
            isAuctionDraft,
            campaignStatus: fallbackCampaignStatus,
            campaignId: fallbackCampaignId,
            campaignCreationStatus: fallbackCampaignCreationStatus,
            campaignDraftId: fallbackCampaignDraftId,
            auctionNullableAnalyticsData,
            isVacancyFromAnotherCabinet,
        };
    }

    const { campaignCreationStatus, currentVacancyAuctionCampaign, controls, currency, campaignDraft } = auctionData;

    if (campaignCreationStatus === CampaignCreationStatus.NotCreated) {
        canCreateAuction = true;
    } else if (campaignCreationStatus === CampaignCreationStatus.CampaignDraftCreated) {
        canCreateAuction = true;
        isAuctionDraft = true;
    } else if (campaignCreationStatus === CampaignCreationStatus.CampaignCreated) {
        canEditAuction = true;
    }

    return {
        canCreateAuction,
        canEditAuction,
        isAuctionDraft,
        campaignStatus: currentVacancyAuctionCampaign?.campaignStatus || fallbackCampaignStatus,
        campaignId: currentVacancyAuctionCampaign?.campaignId || fallbackCampaignId,
        campaignCreationStatus,
        campaignDraftId: campaignDraft?.campaignDraftId || fallbackCampaignDraftId,
        bid: currentVacancyAuctionCampaign?.bid,
        budget: currentVacancyAuctionCampaign?.budget,
        minBudget: controls?.['publication.auction.budget']?.minValue,
        campaignBalance: currentVacancyAuctionCampaign?.campaignBalance,
        currency,
        auctionNullableAnalyticsData: {
            campaignId: currentVacancyAuctionCampaign?.campaignId || null,
            campaignDraftId: campaignDraft?.campaignDraftId ? Number(campaignDraft.campaignDraftId) : null,
            campaignStatus: currentVacancyAuctionCampaign?.campaignStatus || null,
        },
        isVacancyFromAnotherCabinet: currentVacancyAuctionCampaign?.campaignAccountInfo.isMain === false || false,
    };
};
