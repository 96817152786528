import { Tag, TagStyle } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { AuctionCampaignStatus } from 'src/models/auctionData';

const TrlKeys = {
    active: 'label.active',
    pause: 'label.pause',
    campaignLowBalance: 'label.limitReached',
    accountLowBalance: 'label.no.money',
};

const LabelsStatusMap = {
    [AuctionCampaignStatus.Active]: {
        label: TrlKeys.active,
        style: 'positive',
    },
    [AuctionCampaignStatus.Blocked]: {
        label: TrlKeys.pause,
        style: 'neutral',
    },
    [AuctionCampaignStatus.CampaignLowBalance]: {
        label: TrlKeys.campaignLowBalance,
        style: 'negative',
    },
    [AuctionCampaignStatus.AccountLowBalance]: {
        label: TrlKeys.accountLowBalance,
        style: 'negative',
    },
};

interface CampaignStatusLabelProps {
    dataQaPrefix: string;
}

const CampaignStatusLabel: TranslatedComponent<CampaignStatusLabelProps> = ({ dataQaPrefix, trls }) => {
    const campaignStatus = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign?.campaignStatus);

    if (!campaignStatus) {
        return null;
    }

    const labelProps = LabelsStatusMap[campaignStatus] || LabelsStatusMap[AuctionCampaignStatus.Active];

    return (
        <Tag
            size="large"
            style={labelProps.style as TagStyle}
            data-qa={`${dataQaPrefix}-campaign-status-${campaignStatus}`}
        >
            {trls[labelProps.label]}
        </Tag>
    );
};

export default translation(CampaignStatusLabel);
