import { useCallback, useRef, useState } from 'react';

import modalArchiveButtonButtonClick, {
    Reason,
} from '@hh.ru/analytics-js-events/build/xhh/employer/archive/modal_archive_button_button_click';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ArchiveVacancyApplicantsFeedback from 'src/components/VacancyModals/ArchiveVacancyApplicantsFeedback';
import HiringSurvey from 'src/components/VacancyModals/HiringSurvey';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { UXFeedback } from 'src/utils/uxfeedback';

import ArchiveModal from 'src/components/VacancyModals/Archive/ArchiveModal';
import useArchiveVacancyFetcher from 'src/components/VacancyModals/Archive/useArchiveVacancyFetcher';

const POSTFIX_NAME = 'foundHere';

const TrlKeys = {
    emptyReasonError: 'employer.myVacancies.archive.emptyReasonError',
};

const Features = {
    hiringSurveyModal: 'hiring_survey_modal',
};

interface Props {
    isVisible: boolean;
    vacanciesIds: number[];
    vacancyName: string;
    onArchiveSuccess: (params: { isFeedbackSent: boolean; responseQuery: string }) => void;
    handleCloseModal: () => void;
    onCancel?: () => void;
}

const Archive: TranslatedComponent<Props> = ({
    isVisible,
    vacanciesIds = [],
    vacancyName = '',
    onArchiveSuccess,
    handleCloseModal,
    trls,
    onCancel,
}) => {
    const features = useSelector((state) => state.features);
    const userType = useSelector((state) => state.userType);
    const employerRegistrationTimeInDelta = useSelector((state) => state.employerRegistrationTimeInDelta);

    const isArchivedRef = useRef(false);
    const responseQueryRef = useRef('');
    const isFeedbackSentRef = useRef(false);

    const [archivationReason, setArchivationReason] = useState<string | null>(null);
    const [archivationSubmitError, setArchivationSubmitError] = useState(false);
    const [archivationReasonError, setArchivationReasonError] = useState<string | null>(null);
    const [showHiringSurvey, setShowHiringSurvey] = useState(false);
    const [showApplicantsFeedback, setShowApplicantsFeedback] = useState(false);
    const { fetchArchiveVacancy } = useArchiveVacancyFetcher();

    const archivationReasonRequired = vacanciesIds.length === 1 && userType !== UserType.BackOffice;
    const enableHiringSurvey =
        features[Features.hiringSurveyModal] && archivationReasonRequired && archivationReason === POSTFIX_NAME;

    const handleSelectArchivationReason = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setArchivationReason(value);
        setArchivationReasonError(null);
    }, []);

    const handleTriggerUxFeedbackSurvey = useCallback(() => {
        if (employerRegistrationTimeInDelta) {
            UXFeedback.sendEvent('first_experience_survey');
        }
    }, [employerRegistrationTimeInDelta]);

    const handleCloseModalWithUxFeedbackSurvey = useCallback(() => {
        isArchivedRef.current &&
            onArchiveSuccess({ isFeedbackSent: isFeedbackSentRef.current, responseQuery: responseQueryRef.current });

        handleCloseModal();
        handleTriggerUxFeedbackSurvey();
    }, [handleCloseModal, handleTriggerUxFeedbackSurvey, onArchiveSuccess]);

    const handleCloseHiringSurvey = () => {
        setShowHiringSurvey(false);
        setShowApplicantsFeedback(true);
    };

    const handleCloseArchiveVacancyApplicantsFeedback = useCallback(
        ({ feedbackSent = false } = {}) => {
            isFeedbackSentRef.current = feedbackSent;

            handleCloseModalWithUxFeedbackSurvey();
        },
        [handleCloseModalWithUxFeedbackSurvey]
    );

    const handleSubmit = useCallback(() => {
        modalArchiveButtonButtonClick({ vacancyIdList: vacanciesIds.join(), reason: archivationReason as Reason });

        if (archivationReasonRequired && !archivationReason) {
            setArchivationReasonError(trls[TrlKeys.emptyReasonError]);
            return;
        }

        let name: string | null = null;

        if (archivationReasonRequired) {
            name = vacancyName;
        }

        fetchArchiveVacancy({
            params: { reason: archivationReason!, id: vacanciesIds },
            additional: { name: name! },
        }).then(
            (query) => {
                isArchivedRef.current = true;
                responseQueryRef.current = query;

                if (enableHiringSurvey) {
                    setShowHiringSurvey(true);
                    return;
                }
                setShowApplicantsFeedback(true);
            },
            () => {
                setArchivationSubmitError(true);
            }
        );
    }, [
        vacanciesIds,
        vacancyName,
        archivationReason,
        archivationReasonRequired,
        fetchArchiveVacancy,
        enableHiringSurvey,
        trls,
    ]);

    if (showHiringSurvey) {
        return (
            <HiringSurvey
                visible={isVisible}
                isVacancyArchived
                vacancyId={vacanciesIds[0]}
                onTriggerUxFeedbackSurvey={handleTriggerUxFeedbackSurvey}
                onClose={handleCloseHiringSurvey}
            />
        );
    }

    if (showApplicantsFeedback) {
        return (
            <ArchiveVacancyApplicantsFeedback
                visible={isVisible}
                vacanciesIds={vacanciesIds}
                onClose={handleCloseArchiveVacancyApplicantsFeedback}
            />
        );
    }

    return (
        <ArchiveModal
            visible={isVisible}
            vacanciesToArchiveCount={vacanciesIds.length}
            archivationReasonRequired={archivationReasonRequired}
            archivationReason={archivationReason}
            archivationReasonError={archivationReasonError}
            archivationSubmitError={archivationSubmitError}
            onSelectArchivationReason={handleSelectArchivationReason}
            onClose={handleCloseModalWithUxFeedbackSurvey}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    );
};

export default translation(Archive);
