import { forwardRef, ForwardRefRenderFunction, Ref } from 'react';
import classnames from 'classnames';

import { Button as MagritteButton, Text } from '@hh.ru/magritte-ui';
import { CalendarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    selectPrefix: 'hiringPlan.startDate.selectPrefix',
    selectDefaultPlaceholder: 'hiringPlan.startDate.selectDefaultPlaceholder',
    expiredError: 'hiringPlan.startDate.expiredError',
};

interface StartDateSelectProps {
    isExpiredError?: boolean;
    fieldRef?: HTMLDivElement;
    dateValue?: string;
    onClick: () => void;
    disabled: boolean;
    fwdRef?: Ref<HTMLButtonElement>;
}

const StartDateSelectRaw: TranslatedComponent<StartDateSelectProps> = ({
    trls,
    dateValue,
    isExpiredError,
    disabled,
    onClick,
    fwdRef,
}) => {
    const startDateSelectText = dateValue ? (
        <div className={classnames(styles.date, { [styles.dateError]: isExpiredError })}>
            {trls[TrlKeys.selectPrefix]}
            {NON_BREAKING_SPACE}
            <HumanDate showYear={ShowYear.NotCurrent} date={dateValue} />
        </div>
    ) : (
        trls[TrlKeys.selectDefaultPlaceholder]
    );

    return (
        <MagritteButton
            ref={fwdRef}
            stretched
            mode="secondary"
            icon={<CalendarOutlinedSize24 />}
            data-qa="hiring-plan-start-date"
            onClick={onClick}
            disabled={disabled}
        >
            <Text typography="label-2-regular">{startDateSelectText}</Text>
        </MagritteButton>
    );
};
const Translated = translation(StartDateSelectRaw);
const StartDateSelect: ForwardRefRenderFunction<HTMLButtonElement, StartDateSelectProps> = (props, ref) => (
    <Translated {...props} fwdRef={ref} />
);

export default forwardRef(StartDateSelect);
