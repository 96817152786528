import { useMemo } from 'react';
import classnames from 'classnames';

import { Text, TextTypography } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import translation from 'src/components/translation';
import useEnableCompensationWithModeAndFrequency from 'src/hooks/useEnableCompensationWithModeAndFrequency';
import { useSelector } from 'src/hooks/useSelector';

enum CompensationType {
    Net = 'net',
    Gross = 'gross',
}

const TrlKeys = {
    compensationNotSet: 'similarVacancyBlock.compensationNotSet',
    [CompensationType.Net]: 'employer.newVacancy.compensation.net',
    [CompensationType.Gross]: 'employer.newVacancy.compensation.gross',
};

interface VacancyCompensationProps {
    noCompensation?: boolean;
    isRedesign?: boolean;
    isNeutral?: boolean;
    inHeader?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyCompensation: TranslatedComponent<VacancyCompensationProps> = ({
    isRedesign,
    trls,
    isNeutral,
    inHeader,
    isMagritteVacancy,
    isMobile,
}) => {
    const compensation = useSelector((state) => state.vacancyView.compensation);
    const modeDictionary = useSelector((state) => state.vacancyCompensationFieldsDictionary.mode);

    const enableNewCompensation = useEnableCompensationWithModeAndFrequency();

    const { from, to, perModeFrom, perModeTo, currencyCode, noCompensation, gross, mode } = compensation;

    const fallbackFrom = perModeFrom ?? from ?? null;
    const fallbackTo = perModeTo ?? to ?? null;
    const isCompensationNotSet = !fallbackFrom && !fallbackTo;

    const modeText = modeDictionary.find(({ id }) => id === mode)?.text;

    // Тут какая-то ерунда с "title-4-semibold"
    // Аналогично: https://github.com/hhru/employer-reviews-front/blob/c430c7d92b934d7319ade22cf98ec163f19bd0ec/src/components/ModalDidntWorkHere/ModalDidntWorkHereContent.tsx#L31
    // Когда будем финализировать эксп, проблема уже должна будет решиться.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const typography: TextTypography = useMemo(() => {
        if (isMagritteVacancy) {
            return isMobile ? 'label-1-regular' : 'title-4-semibold';
        }
        if (inHeader) {
            return 'label-2-regular';
        }
        return 'label-1-regular';
    }, [isMagritteVacancy, isMobile, inHeader]);

    if (isCompensationNotSet) {
        return (
            <Text Element="span" typography={typography}>
                {trls[TrlKeys.compensationNotSet]}
            </Text>
        );
    }

    if (!currencyCode) {
        return null;
    }

    let compensationType;
    if (gross === true) {
        compensationType = CompensationType.Gross;
    } else if (gross === false) {
        compensationType = CompensationType.Net;
    }

    return (
        <Text data-qa="vacancy-salary" Element={inHeader ? 'span' : 'div'}>
            <Text
                Element="span"
                typography={typography}
                data-qa={`vacancy-salary-compensation-type-${compensationType || ''}`}
                style={isNeutral ? 'tertiary' : 'primary'}
            >
                <Compensation
                    {...compensation}
                    groupSeparator={'\u00A0'}
                    analyticsContext="VacancyView"
                    showNoSalaryMessage
                />
                {enableNewCompensation && !!modeText && (
                    <>
                        {` ${modeText.toLowerCase()}`}
                        {(isMagritteVacancy || !isMobile) && `, `}
                    </>
                )}
                {!noCompensation && compensationType && (
                    <span
                        className={classnames({
                            'vacancy-salary-compensation-type':
                                isRedesign && !(isMagritteVacancy && enableNewCompensation),
                        })}
                    >
                        {' '}
                        {trls[TrlKeys[compensationType]]}
                    </span>
                )}
            </Text>
        </Text>
    );
};

export default translation(VacancyCompensation);
