import { Fragment } from 'react';
import classnames from 'classnames';

import vacancyToChatButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_communication/vacancy_to_chat_button_click';
import { useChatik } from '@hh.ru/chatik-integration';
import { Button as MagritteButton, useBreakpoint } from '@hh.ru/magritte-ui';
import { BubbleRoundOutlinedSize16, BubbleRoundOutlinedSize24, IconDynamic } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

interface VacancyActionChatProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    stretched?: boolean;
}

const TrlKeys = {
    hint: 'hint.chat.open',
    text: 'vacancy.contacts.chat.button',
};

const VacancyActionChat: TranslatedComponent<VacancyActionChatProps> = ({
    trls,
    isRedesign,
    isMagritteVacancy,
    stretched,
}) => {
    const { openChatik } = useChatik();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const chatId = useSelector((state) => (vacancyId ? state.vacancyChatInfo[vacancyId]?.data?.chatId : undefined));
    const { isMobile } = useBreakpoint();

    const commonProps = {
        'data-qa': 'vacancy__question-chat-open',
        onClick: () => {
            if (chatId && vacancyId) {
                vacancyToChatButtonClick({ vacancyId });
                openChatik?.({ chatId });
            }
        },
        title: trls[TrlKeys.hint],
    };

    const Wrapper = stretched ? Fragment : IconDynamic;

    const renderRedesignChatButton = () =>
        stretched ? (
            <Button
                appearance={ButtonAppearance.Outlined}
                kind={ButtonKind.Success}
                scale={ButtonScale.Large}
                stretched
                {...commonProps}
            >
                {trls[TrlKeys.text]}
            </Button>
        ) : (
            <button
                className="vacancy-action-bubble-redesign vacancy-action-bubble-redesign_appearance-outlined"
                {...commonProps}
            >
                {stretched ? trls[TrlKeys.text] : <span className="vacancy-action-bubble-icon-redesign" />}
            </button>
        );

    const renderMagritteChatButton = () => {
        const commonMagritteProps = {
            ...commonProps,
            style: 'accent',
            mode: 'secondary',
            size: isMobile ? 'small' : 'medium',
        } as const;

        return stretched ? (
            <MagritteButton stretched {...commonMagritteProps}>
                {trls[TrlKeys.text]}
            </MagritteButton>
        ) : (
            <MagritteButton
                hideLabel
                aria-label={commonProps.title}
                icon={<BubbleRoundOutlinedSize24 />}
                {...commonMagritteProps}
            />
        );
    };

    const renderChatButton = () => {
        if (isMagritteVacancy) {
            return renderMagritteChatButton();
        }

        if (isRedesign) {
            return renderRedesignChatButton();
        }

        return (
            <Wrapper>
                <Button
                    appearance={ButtonAppearance.Outlined}
                    stretched={stretched}
                    icon={
                        stretched ? undefined : (
                            <BlokoIconReplaceContainer>
                                <BubbleRoundOutlinedSize16 initial="secondary" />
                            </BlokoIconReplaceContainer>
                        )
                    }
                    {...commonProps}
                >
                    {stretched ? trls[TrlKeys.text] : undefined}
                </Button>
            </Wrapper>
        );
    };

    return (
        <span className={classnames('vacancy-action', { 'vacancy-action_stretched': stretched })}>
            {renderChatButton()}
        </span>
    );
};

export default translation(VacancyActionChat);
