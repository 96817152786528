import { useDispatch } from 'react-redux';

import { CellText } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FieldTip from 'src/components/FieldTip';
import CheckboxWithLabel from 'src/components/VacancyCreate/common/CheckboxWithLabel';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { changeUseAvailableMoney } from 'src/models/auctionData';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ClickmeAvailableMoney from 'src/components/Auction/Common/AuctionControls/AvailableMoney/ClickmeAvailableMoney';

const TrlKeys = {
    payWithClickmeCheckbox: 'auction.controls.payWithClickmeCheckbox',
    availableMoney: 'auction.controls.availableMoney',
    accountBalanceInfo: 'auction.controls.accountBalance.info',
    accountBalanceInfoTitle: 'auction.controls.accountBalance.infoTitle',
};

interface AvailableMoneyProps {
    onUseAvailableMoneyChange?: (use: boolean) => void;
}

const AvailableMoney: TranslatedComponent<AvailableMoneyProps> = ({ trls, onUseAvailableMoneyChange }) => {
    const dispatch = useDispatch();
    const useAvailableMoney = useSelector(({ auctionData }) => auctionData?.useAvailableMoney || false);
    const availableMoney = useSelector(({ auctionData }) => auctionData?.availableMoney || 0);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);

    const toggleUseAvailableMoney = () => {
        dispatch(changeUseAvailableMoney(!useAvailableMoney));
        onUseAvailableMoneyChange?.(!useAvailableMoney);
    };

    return (
        <CheckboxWithLabel
            name="useAvailableMoney"
            title={
                <CellText type="title">
                    <FieldTip
                        tipContent={trls[TrlKeys.accountBalanceInfo]}
                        fieldName={trls[TrlKeys.accountBalanceInfoTitle]}
                        withoutVertPadding
                    >
                        {trls[TrlKeys.payWithClickmeCheckbox]}
                    </FieldTip>
                </CellText>
            }
            subtitle={
                <>
                    {trls[TrlKeys.availableMoney]}
                    {NON_BREAKING_SPACE}
                    <ClickmeAvailableMoney availableMoney={availableMoney} currency={currency} />
                </>
            }
            checked={useAvailableMoney && !!availableMoney}
            data-qa="use-available-money"
            disabled={!availableMoney}
            onChange={toggleUseAvailableMoney}
        />
    );
};

export default translation(AvailableMoney);
