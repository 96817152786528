import { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react';

import { CheckableCard, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ContactsAction } from 'src/components/VacancyContactsChangeModal/types';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    [ContactsAction.Update]: 'vacancy.contacts.change.modal.button.update',
    [ContactsAction.Hide]: 'vacancy.contacts.change.modal.button.hide',
    [ContactsAction.DontChange]: 'vacancy.contacts.change.modal.button.dont.change',
};

interface ContactsActionSwitcherProps {
    value: ContactsAction;
    options?: ContactsAction[];
    onChange: Dispatch<SetStateAction<ContactsAction>>;
}

const ContactsActionSwitcher: TranslatedComponent<ContactsActionSwitcherProps> = ({
    trls,
    options,
    value,
    onChange,
}) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            onChange(event.target.value as ContactsAction);
        },
        [onChange]
    );

    if (!options?.length) {
        return null;
    }

    return (
        <div className={styles.controlsContainer}>
            {options.map((option) => (
                <CheckableCard
                    key={option}
                    padding={16}
                    borderRadius={12}
                    type="radio"
                    flexible
                    value={option}
                    checked={option === value}
                    onChange={handleChange}
                >
                    <span className={styles.controlsText}>
                        <Text typography="label-2-regular">{trls[TrlKeys[option]]}</Text>
                    </span>
                </CheckableCard>
            ))}
        </div>
    );
};

export default translation(ContactsActionSwitcher);
