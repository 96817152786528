import { FC } from 'react';
import { AxiosError } from 'axios';

import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { useNotification } from 'src/components/Notifications/Provider';
import { vacancySubmitDuplicateError } from 'src/components/Notifications/VacancyCreate';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import fetcher from 'src/utils/fetcher';

interface RestoreFastResponse {
    redirect: string;
}

declare global {
    interface FetcherPostApi {
        [key: `/employer/vacancy/restore_fast/${number}?ignoreDuplicates=${boolean}`]: {
            queryParams: {
                ignoreDuplicates: string;
            };
            body: null;
            response: RestoreFastResponse;
        };
    }
}

const TrlKeys = {
    restore: 'vacancy.tools.repostFromArchive',
};

interface RestoreActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    vacancyId: number;
}

const RestoreFastAction: TranslatedComponent<RestoreActionProps> = ({
    Component,
    triggerType,
    trls,
    vacancyId,
    icon,
}) => {
    const { addNotification } = useNotification();
    const [isLoading, startLoading, stopLoading] = useOnOffState(false);
    const [ignoreDuplicates, startIgnore] = useOnOffState(false);
    const push = usePush();

    const onTrigger = async () => {
        let redirectUrl = null;

        startLoading();
        try {
            const response = await fetcher.post(
                `/employer/vacancy/restore_fast/${vacancyId}?ignoreDuplicates=${ignoreDuplicates}`
            );
            redirectUrl = response.data.redirect;
        } catch (e) {
            const error = e as AxiosError<{
                publicationResult?: {
                    publicationError?: {
                        duplicationCheck: boolean;
                    };
                };
            }>;

            if (error.response?.data?.publicationResult?.publicationError?.duplicationCheck) {
                addNotification(vacancySubmitDuplicateError);
                startIgnore();
            } else {
                defaultRequestErrorHandler(error, addNotification);
            }
        } finally {
            stopLoading();
        }

        if (redirectUrl) {
            push(redirectUrl);
        }
    };

    return (
        <Component
            isPermitted
            isLoading={isLoading}
            onTrigger={onTrigger}
            triggerName="restoreFast"
            triggerType={triggerType}
            icon={icon}
        >
            {trls[TrlKeys.restore]}
        </Component>
    );
};

export default translation(RestoreFastAction);
