import { Dispatch, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { Action, Button, ButtonMode, ButtonStyle, Card, Tag, TooltipHover } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16, EyeCrossedOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TriggerType } from 'src/components/ControlsTrigger';
import VacancyTrigger from 'src/components/EmployerVacancies/VacancyTrigger';
import updateVacancyInfoAfterProlongate from 'src/components/EmployerVacancies/updateVacancyInfoAfterProlongate';
import { useNotification } from 'src/components/Notifications/Provider';
import ProlongateAction from 'src/components/VacancyActions/ProlongateAction';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { MarketRange } from 'src/models/canvacVacancySalaryData';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import {
    VacancyAdvice,
    VacancyAdviceMetric,
    VacancyAdviceOutcome,
    VacancyAdviceSeverity,
    VacancyAdviceWithStatistics,
} from 'src/models/vacancy/vacancyAdvices.types';
import fetcher from 'src/utils/fetcher';

import AdviceCardContent from 'src/components/VacancyModals/VacancyAdviceModal/AdviceCardContent';
import {
    HIDE_ADVICE_URL,
    NO_PERCENT,
    VacancyAdviceGroup,
    VacancyAdviceToGroup,
} from 'src/components/VacancyModals/VacancyAdviceModal/constants';
import { getLinkUrl, getPercentValue } from 'src/components/VacancyModals/VacancyAdviceModal/utils';

import styles from './vacancy-advice-card.less';

interface VacancyAdviceCardProps {
    adviceItem: VacancyAdvice;
    adviceWithStatisticsItem?: VacancyAdviceWithStatistics;
    compensation: VacancyCompensation;
    marketRange: MarketRange;
    salaryRange: SalaryRange;
    vacancyId: number;
    numInList: number;
    totalInList: number;
    hideAdviceItem: (adviceItem: VacancyAdvice) => void;
    vacancyName: string;
    numberOfSimilarVacancies: number;
    dispatchModal: Dispatch<AnyAction>;
}

const TrlKeys = {
    advice: {
        [VacancyAdvice.WorkAddress]: {
            title: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.title',
            description: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.description',
            link: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.link',
        },
        [VacancyAdvice.ConditionsToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.RequirementsToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.ResponsibilitiesToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.SalaryCloserToMarket]: {
            title: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.title',
            description: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.description',
            link: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.link',
        },
        [VacancyAdvice.SalaryAboveMarket]: {
            title: 'employer.myVacancies.advice.list.important.MAKE_SALARY_ABOVE_MARKET.title',
            description: 'employer.myVacancies.advice.list.important.MAKE_SALARY_ABOVE_MARKET.description',
            link: 'employer.myVacancies.advice.list.important.MAKE_SALARY_ABOVE_MARKET.link',
        },
        [VacancyAdvice.UseStandardPlus]: {
            title: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.title',
            description: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.description',
            link: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.link',
        },
        [VacancyAdvice.PublishSalary]: {
            title: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.title',
            description: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.description',
            link: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.link',
        },
        [VacancyAdvice.EmployerContacts]: {
            title: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.title',
            description: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.description',
            link: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.link',
        },
        [VacancyAdvice.UseResumeSearch]: {
            title: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.title',
            description: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.description',
            link: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.link',
        },
        [VacancyAdvice.UseSearchTop]: {
            title: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.title',
            description: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.description',
            link: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.link',
        },
        [VacancyAdvice.DecreaseWorkExperience]: {
            title: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.title',
            description: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.description',
            link: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.link',
        },
        [VacancyAdvice.ChangeVacancyName]: {
            title: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.title',
            description: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.description',
            link: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.link',
        },
        [VacancyAdvice.SalaryMoreSpecific]: {
            title: 'employer.myVacancies.advice.list.important.MAKE_SALARY_MORE_SPECIFIC.title',
            description: 'employer.myVacancies.advice.list.important.MAKE_SALARY_MORE_SPECIFIC.description',
            link: 'employer.myVacancies.advice.list.important.MAKE_SALARY_MORE_SPECIFIC.link',
        },
        [VacancyAdvice.RaiseVacancy]: {
            title: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.title',
            description: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.description',
            link: 'employer.myVacancies.advice.list.important.RAISE_VACANCY.link',
        },
    },
    outcome: {
        [VacancyAdviceOutcome.ImproveVacancy]: 'employer.myVacancies.advice.outcome.improveVacancy',
        [VacancyAdviceOutcome.PromoteVacancy]: 'employer.myVacancies.advice.outcome.promoteVacancy',
        [VacancyAdviceOutcome.AccelerateHiring]: 'employer.myVacancies.advice.outcome.accelerateHiring',
        [VacancyAdviceOutcome.IncreaseViews]: 'employer.myVacancies.advice.outcome.increaseViews',
        [VacancyAdviceOutcome.IncreaseMetricsRel]: '',
    },
    metric: {
        [VacancyAdviceMetric.ViewCount]: 'employer.myVacancies.advice.metric.viewCount',
        [VacancyAdviceMetric.ResponseCount]: 'employer.myVacancies.advice.metric.responseCount',
    },
    upTo: 'employer.myVacancies.advice.upTo',
    tooltipText: 'employer.myVacancies.advice.hide.tooltipText',
};

interface FormValues {
    vacancyId: number;
    adviceCode: VacancyAdvice;
}

declare global {
    interface FetcherPostApi {
        [HIDE_ADVICE_URL]: {
            queryParams: void;
            body: FormValues;
            response: void;
        };
    }
}

const VacancyAdviceCard: TranslatedComponent<VacancyAdviceCardProps> = ({
    adviceItem,
    adviceWithStatisticsItem,
    hideAdviceItem,
    compensation,
    marketRange,
    salaryRange,
    vacancyId,
    numInList,
    totalInList,
    vacancyName,
    numberOfSimilarVacancies,
    dispatchModal,
    trls,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const activatorRef = useRef<HTMLSpanElement>(null);
    const adviceType: VacancyAdviceGroup = VacancyAdviceToGroup[adviceItem];

    const [firstDelta] = adviceWithStatisticsItem?.deltas ?? [];
    const percent = getPercentValue(firstDelta?.deltaRel);

    const buttonLink = getLinkUrl(adviceItem, vacancyId);

    const analyticParams = useMemo(
        () => ({
            vacancyId,
            advice: adviceItem,
            numInList,
            totalInList,
            salaryFrom: marketRange.bottom,
            salaryTo: marketRange.upper,
            severity: adviceWithStatisticsItem?.severity,
            outcome: adviceWithStatisticsItem?.outcome,
            metric: firstDelta?.metric,
            deltaPercent: firstDelta?.deltaRel,
            displayedPercent:
                adviceWithStatisticsItem?.outcome === VacancyAdviceOutcome.IncreaseMetricsRel ? percent : NO_PERCENT,
        }),
        [
            adviceWithStatisticsItem?.outcome,
            adviceWithStatisticsItem?.severity,
            firstDelta?.deltaRel,
            firstDelta?.metric,
            marketRange.bottom,
            marketRange.upper,
            numInList,
            percent,
            adviceItem,
            totalInList,
            vacancyId,
        ]
    );

    const cardAdviceRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_item',
        ...analyticParams,
    });

    const onHideAdvice = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_item_hide', analyticParams);
        fetcher
            .postFormData(HIDE_ADVICE_URL, {
                vacancyId,
                adviceCode: adviceItem,
            })
            .catch(console.error);
        hideAdviceItem(adviceItem);
    };

    const onClickToLink = useCallback(() => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_item_link', analyticParams);
    }, [analyticParams]);

    const onChartClick = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_chart', analyticParams);
    };

    let buttonLinkProps: { mode: ButtonMode; style?: ButtonStyle } =
        adviceType === VacancyAdviceGroup.Free ? { mode: 'secondary' } : { mode: 'primary' };

    if (adviceWithStatisticsItem) {
        buttonLinkProps =
            adviceWithStatisticsItem?.severity === VacancyAdviceSeverity.Minor
                ? { mode: 'secondary' }
                : { mode: 'primary' };
    }

    return (
        <div ref={cardAdviceRef} className={styles.vacancyAdviceCard}>
            <Card padding={12} borderRadius={24} showBorder stretched>
                <div className={styles.vacancyAdviceCardContent}>
                    <div className={styles.vacancyAdviceIcons}>
                        <Tag
                            icon={AstrostarOutlinedSize16}
                            style={
                                adviceWithStatisticsItem?.severity === VacancyAdviceSeverity.Major
                                    ? 'special'
                                    : 'neutral'
                            }
                        >
                            {adviceWithStatisticsItem?.outcome === VacancyAdviceOutcome.IncreaseMetricsRel
                                ? `${trls[TrlKeys.upTo]} +${percent}% ${trls[TrlKeys.metric[firstDelta?.metric]]}`
                                : trls[
                                      TrlKeys.outcome[
                                          adviceWithStatisticsItem?.outcome ?? VacancyAdviceOutcome.IncreaseMetricsRel
                                      ]
                                  ]}
                        </Tag>
                        <span className={styles.vacancyAdviceIconHide} ref={activatorRef}>
                            <Action onClick={onHideAdvice} mode="secondary" icon={EyeCrossedOutlinedSize16} />
                        </span>
                        <TooltipHover placement="top-center" activatorRef={activatorRef}>
                            {trls[TrlKeys.tooltipText]}
                        </TooltipHover>
                    </div>
                    <AdviceCardContent
                        title={trls[TrlKeys.advice[adviceItem].title]}
                        description={trls[TrlKeys.advice[adviceItem].description]}
                        adviceItem={adviceItem}
                        compensation={compensation}
                        marketRange={marketRange}
                        salaryRange={salaryRange}
                        onChartClick={onChartClick}
                        vacancyId={vacancyId}
                        vacancyName={vacancyName}
                        numberOfSimilarVacancies={numberOfSimilarVacancies}
                    />
                </div>{' '}
                {adviceItem === VacancyAdvice.RaiseVacancy ? (
                    <span onClick={onClickToLink}>
                        <ProlongateAction
                            Component={VacancyTrigger}
                            key="prolongate"
                            triggerType={TriggerType.VacancyAdviceModalButton}
                            vacanciesIds={[vacancyId]}
                            dispatchModal={dispatchModal}
                            afterSuccessAction={({ handleCloseModal, vacancyIds }) => {
                                dispatch(updateVacancyInfoAfterProlongate({ addNotification, vacancyIds }));
                                handleCloseModal();
                            }}
                            additionalAnalyticsParams={{
                                fromPlace: 'vacancy_advice_modal',
                                vacanciesIds: String(vacancyId),
                            }}
                        />
                    </span>
                ) : (
                    <Button
                        onClick={onClickToLink}
                        stretched
                        Element={Link}
                        target="_blank"
                        to={buttonLink}
                        size="small"
                        style="accent"
                        {...buttonLinkProps}
                    >
                        {trls[TrlKeys.advice[adviceItem].link]}
                    </Button>
                )}
            </Card>
        </div>
    );
};

export default translation(VacancyAdviceCard);
