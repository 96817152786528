import { ElementShownAnchor } from '@hh.ru/analytics-js';
import auctionBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_banner_element_shown';
import { VSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import useAuctionCreationModal from 'src/components/Auction/hooks/useAuctionCreationModal';
import { QS_PARAM_TO_OPEN_AUCTION } from 'src/components/Auction/utils';
import FormatMoney from 'src/components/FormatMoney';
import BannerWrapper from 'src/components/VacancyBannerWrapper';
import translation from 'src/components/translation';
import useRunEmployerVacancyAction from 'src/hooks/useRunEmployerVacancyAction';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldName } from 'src/models/auctionData';

const TrlKeys = {
    title: 'auction.title',
    description: 'auction.banner.description',
    priceInfo: 'auction.banner.priceInfo',
    configure: 'auction.banner.configure',
};

const AuctionBanner: TranslatedComponent = ({ trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const minBudget = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Budget].minValue
    );
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId!);
    const campaignDraftId = useSelectorNonNullable(({ auctionData }) => auctionData.campaignDraft?.campaignDraftId);
    const campaignCreationStatus = useSelectorNonNullable(({ auctionData }) => auctionData.campaignCreationStatus);
    const { showAuctionCreationModal, renderAuctionCreationModal } = useAuctionCreationModal({
        vacancyId,
        campaignCreationStatus,
    });

    useRunEmployerVacancyAction(QS_PARAM_TO_OPEN_AUCTION, showAuctionCreationModal);

    return (
        <>
            <div data-qa="open-create-auction-modal" onClick={showAuctionCreationModal}>
                <BannerWrapper header={trls[TrlKeys.title]}>
                    <ElementShownAnchor
                        fn={auctionBannerElementShown}
                        vacancyId={vacancyId}
                        campaignDraftId={campaignDraftId}
                        campaignCreationStatus={campaignCreationStatus}
                    >
                        {trls[TrlKeys.description]}
                        <VSpacing default={16} />
                        <Text style="secondary" typography="paragraph-3-regular">
                            {formatToReactComponent(trls[TrlKeys.priceInfo], {
                                '{0}': (
                                    <FormatMoney currency={currency} inCents>
                                        {minBudget}
                                    </FormatMoney>
                                ),
                            })}
                        </Text>
                    </ElementShownAnchor>
                </BannerWrapper>
            </div>
            {renderAuctionCreationModal()}
        </>
    );
};

export default translation(AuctionBanner);
