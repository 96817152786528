import { useCallback } from 'react';
import addHours from 'date-fns/addHours';
import compareAsc from 'date-fns/compareAsc';

import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const IGNORE_ADVICE_RISE_INFO_URL = '/shards/employer/vacancy/ignore_advice';

declare global {
    interface FetcherPostApi {
        [IGNORE_ADVICE_RISE_INFO_URL]: {
            queryParams: {
                vacancyId: number;
            };
            body: null;
            response: null;
        };
    }
}

interface UseRiseAdvice {
    isAllowed: boolean;
    spoilPristineNotice: () => void;
    resetTrigger: () => void;
}
export function useRiseAdvice(): UseRiseAdvice {
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);

    // будет в true если совет не показывали еще за последнии 24 часа
    const isAdvicePristine = useSelector(({ isVacancyAdvicePlaceAllowed }) => isVacancyAdvicePlaceAllowed);
    // можно ли вакансию поднимать
    const isRiseAllowed = useSelector((state) => !!state.availableVacancyActions.update);

    // если поднимали  вакансию за последнии 24 часа то будет false
    const isMoreThan24HoursHasPassedSinceLastRise = useSelector(({ vacancyView }) =>
        vacancyView.systemInfo?.publicationTime
            ? compareAsc(new Date(), addHours(new Date(vacancyView.systemInfo.publicationTime), 24)) === 1
            : false
    );
    // если создали вакансию менее чем 2 дня назад то будет  false
    const isMoreThan48HoursHasPassedSinceCreated = useSelector(({ vacancyView }) =>
        vacancyView.systemInfo?.creationTime
            ? compareAsc(new Date(), addHours(new Date(vacancyView.systemInfo.creationTime), 48)) === 1
            : false
    );

    const isAllowed =
        isAdvicePristine &&
        isRiseAllowed &&
        isMoreThan24HoursHasPassedSinceLastRise &&
        isMoreThan48HoursHasPassedSinceCreated;

    const spoilPristineNotice = useCallback(() => {
        if (vacancyId) {
            return fetcher.post(IGNORE_ADVICE_RISE_INFO_URL, null, { params: { vacancyId } });
        }
        return null;
    }, [vacancyId]);

    const resetTrigger = useCallback(() => {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.delete('advice');
        history.replaceState(null, '', `?${String(currentSearchParams)}${location.hash}`);
    }, []);

    return {
        isAllowed,
        spoilPristineNotice,
        resetTrigger,
    };
}
