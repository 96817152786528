import { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow, ColumnsWrapper } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import useAdvInfoRequest from 'src/components/Banner/useAdvInfoRequest';
import LuxDividerRenderer from 'src/components/CodeInjector/ContainerForXSL';
import Markup from 'src/components/Markup';
import useLinkifyWhiteList, {
    useWhiteListDomainsRegexp,
    findDomNodesMatchingPattern,
} from 'src/components/Markup/hooks/useLinkifyWhiteList';
import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import { useSelector } from 'src/hooks/useSelector';

import VacancyDefaultContainer from 'src/components/VacancyView/DefaultContainer';
import VacancySignUpBlock from 'src/components/VacancyView/SignUpBlock';
import VacancyActions from 'src/components/VacancyView/VacancyActions';
import VacancyAdditionalAccepts from 'src/components/VacancyView/VacancyAdditionalAccepts';
import VacancyBlockEmployer from 'src/components/VacancyView/VacancyBlock/Employer';
import VacancyBlockTop from 'src/components/VacancyView/VacancyBlock/Top';
import VacancyCompany from 'src/components/VacancyView/VacancyCompany';
import VacancyCompensationFrequency from 'src/components/VacancyView/VacancyCompensationFrequency';
import VacancyConstructor from 'src/components/VacancyView/VacancyConstructor';
import VacancyContacts from 'src/components/VacancyView/VacancyContacts';
import VacancyDriverLicense from 'src/components/VacancyView/VacancyDriverLicense';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment';
import VacancyFooter from 'src/components/VacancyView/VacancyFooter';
import VacancyIsArchived from 'src/components/VacancyView/VacancyIsArchived';
import VacancyKeySkills from 'src/components/VacancyView/VacancyKeySkills';
import VacancyMap from 'src/components/VacancyView/VacancyMap';
import VacancyOnlineUsersCounter from 'src/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyPreviewTools from 'src/components/VacancyView/VacancyPreviewTools';
import VacancyResponseQuestions from 'src/components/VacancyView/VacancyResponseQuestions';
import VacancySimilarResumes from 'src/components/VacancyView/VacancySimilarResumes';
import VacancyTitle from 'src/components/VacancyView/VacancyTitle';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import useIsVisibleBody from 'src/components/VacancyView/hooks/useIsVisibleBody';
import useContentColumns from 'src/components/VacancyView/useContentColumns';

const DefaultAdditionalWrapper = ({ columnParams, children }) => (
    <ColumnsRow>
        <Column {...columnParams}>{children}</Column>
    </ColumnsRow>
);

const DefaultDescriptionWrapper = ({ columnParams, children }) => (
    <Column {...columnParams}>
        <div className="vacancy-description-print">{children}</div>
    </Column>
);

const VacancyBody = ({
    status,
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    isEmployerView,
    bottomContent,
    isRedesign,
    CustomColumnsWrapper = ColumnsWrapper,
    DescriptionWrapper = DefaultDescriptionWrapper,
    AdditionalWrapper = DefaultAdditionalWrapper,
    hasTopPadding,
    newEmployerVacancyMap = false,
}) => {
    const resumeFormNodeRef = useRef();
    const luxDividerRenederRef = useRef();
    const { isVisibleBody, setIsVisibleBody } = useIsVisibleBody();
    const { contentColumnParams, bottomColumnParams } = useContentColumns();
    const branding = useBranding();
    const { isM, isS, isXS } = useBreakpoint();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const systemInfo = useSelector((state) => state.vacancyView.systemInfo);

    useAdvInfoRequest();

    const pattern = useWhiteListDomainsRegexp();
    const linkifyProcessor = useLinkifyWhiteList();

    const [nodesToLinkify, setNodesToLinkify] = useState(null);

    useEffect(() => {
        if (luxDividerRenederRef.current) {
            const nodes = findDomNodesMatchingPattern(luxDividerRenederRef.current, pattern);
            const portalNodes = [];
            nodes.forEach((node) => {
                const newNode = document.createElement('span');
                portalNodes.push({ newNode, oldNode: node, text: node.textContent });
                node.parentNode.insertBefore(newNode, node);
            });
            setNodesToLinkify(portalNodes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (nodesToLinkify) {
            nodesToLinkify.forEach((nodeObj) => nodeObj.oldNode.parentNode.removeChild(nodeObj.oldNode));
        }
    }, [nodesToLinkify]);

    if (branding.isChameleon) {
        return (
            <VacancyDefaultContainer
                ContainerWrapper={CustomColumnsWrapper}
                isChameleonEmployerView={isEmployerView}
                isRedesign={isRedesign}
                rightColumn={
                    isRedesign && (
                        <Column xs="0" s="0" m="0" l="6" container>
                            <div className="noprint">
                                <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                            </div>
                        </Column>
                    )
                }
            >
                {isRedesign && (
                    <>
                        <VacancyBlockTop
                            resumeFormNodeRef={resumeFormNodeRef}
                            contactsNodeRef={contactsNodeRef}
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        />
                        <Column xs="4" s="8" m="12" l="0" container>
                            <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                        </Column>
                    </>
                )}
                {!isRedesign && (
                    <>
                        <VacancyTitle />
                        <VacancyCompany />
                        <VacancyOnlineUsersCounter />
                        <VacancyActions
                            contactsNodeRef={contactsNodeRef}
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            resumeFormNodeRef={resumeFormNodeRef}
                        />
                    </>
                )}
                <VacancyIsArchived isHiddenSwitcher={isVisibleBody} setVisibilityBody={setIsVisibleBody} />
                <VacancySignUpBlock blockRef={vacancyBodyFooterNodeRef} />
                {isVisibleBody && (
                    <>
                        <div className="vacancy-description">
                            <DescriptionWrapper columnParams={contentColumnParams}>
                                {!isRedesign && (
                                    <Gap bottom>
                                        <Text>
                                            <VacancyCompensationFrequency />
                                            <VacancyEmployment />
                                        </Text>
                                    </Gap>
                                )}
                                <div ref={luxDividerRenederRef}>
                                    <LuxDividerRenderer place="vacancy-branded-xsl" styleClass="vacancy-section" />
                                </div>

                                {nodesToLinkify &&
                                    nodesToLinkify.map((node) =>
                                        ReactDOM.createPortal(
                                            <Markup processors={[linkifyProcessor]}>{node.text}</Markup>,
                                            node.newNode
                                        )
                                    )}
                            </DescriptionWrapper>
                            <AdditionalWrapper columnParams={bottomColumnParams}>
                                <VacancyAdditionalAccepts />
                                <VacancyKeySkills />
                                <VacancyDriverLicense />
                                <VacancyContacts
                                    isRedesign={isRedesign}
                                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                                    blockRef={contactsNodeRef}
                                />
                                <VacancyResponseQuestions vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                                <VacancyMap newEmployerVacancyMap={newEmployerVacancyMap} isRedesign={isRedesign} />
                                <VacancyPreviewTools />
                                {(isM || isS || isXS) && systemInfo && (
                                    <>
                                        <VacancySuitableResumesBanner vacancyId={vacancyId} />
                                        <VSpacing default={24} />
                                    </>
                                )}
                                <VacancySimilarResumes />
                            </AdditionalWrapper>
                        </div>
                        <ColumnsRow>
                            <VacancyFooter
                                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                                isRedesign={isRedesign}
                            />
                        </ColumnsRow>
                    </>
                )}
                {bottomContent}
            </VacancyDefaultContainer>
        );
    }

    const isNotShowArchiveBlock = !status.archived && !status.disabled;

    const renderSpoiler = isNotShowArchiveBlock
        ? undefined
        : () => (
              <VacancyIsArchived
                  isHiddenSwitcher={isVisibleBody}
                  setVisibilityBody={setIsVisibleBody}
                  hasVacancySectionWrapper={false}
              />
          );
    const signUpBlock = () => <VacancySignUpBlock isBrandingConstructor blockRef={vacancyBodyFooterNodeRef} />;
    return (
        <VacancyConstructor
            VacancyContainer={VacancyDefaultContainer}
            ContainerWrapper={CustomColumnsWrapper}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            resumeFormNodeRef={resumeFormNodeRef}
            isEmployerView={isEmployerView}
            hasTopPadding={!!hasTopPadding}
            isRedesign={isRedesign}
            hideContent={!isVisibleBody}
            renderSpoiler={renderSpoiler}
            beforeSections={signUpBlock}
        />
    );
};

DefaultDescriptionWrapper.propTypes = {
    children: PropTypes.node,
    columnParams: PropTypes.object,
};

DefaultAdditionalWrapper.propTypes = {
    children: PropTypes.node,
    columnParams: PropTypes.object,
};

VacancyBody.propTypes = {
    status: PropTypes.shape({
        archived: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    contactsNodeRef: PropTypes.object,
    vacancyBodyFooterNodeRef: PropTypes.object,
    isEmployerView: PropTypes.bool,
    CustomColumnsWrapper: PropTypes.func,
    DescriptionWrapper: PropTypes.func,
    AdditionalWrapper: PropTypes.func,
    isRedesign: PropTypes.bool,
    bottomContent: PropTypes.node,
    hasTopPadding: PropTypes.bool,
    newEmployerVacancyMap: PropTypes.bool,
};

export default connect(({ vacancyView }) => ({
    status: vacancyView.status,
}))(VacancyBody);
