import { Dispatch, useEffect, useRef } from 'react';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { HSpacing } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';
import useShowAdviceModal from 'src/components/VacancyImportantBanners/useShowAdviceModal';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    adviceCounter: {
        one: 'employer.myVacancies.advice.count.one',
        some: 'employer.myVacancies.advice.count.some',
        many: 'employer.myVacancies.advice.count.many',
    },
    adviceTitle: 'employer.vacancy.VacancyImportantBanners.advice',
};

interface AdviceBannerProps {
    vacancyId: string;
    vacancyName: string;
    vacancyAreaName: string;
    advicesCount: number;
    dispatchModal: Dispatch<AnyAction>;
}

const AdviceBanner: TranslatedComponent<AdviceBannerProps> = ({
    dispatchModal,
    vacancyId,
    vacancyName,
    vacancyAreaName,
    advicesCount,
    trls,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_advice',
                vacancyId,
            });
        }
    }, [vacancyId]);

    const { openModal } = useShowAdviceModal({
        dispatchModal,
        vacancyId,
        vacancyName,
        vacancyAreaName,
    });

    return (
        <BannerWrapper dataQa="vacancy-important-banner-advice" onClick={() => openModal('button')}>
            <div className={styles.adviceBanner} ref={ref}>
                <AstrostarOutlinedSize16 initial="special" />
                <HSpacing default={8} />
                <span>
                    {trls[TrlKeys.adviceTitle]}
                    {': '}
                    <Conversion
                        value={advicesCount}
                        one={trls[TrlKeys.adviceCounter.one]}
                        some={trls[TrlKeys.adviceCounter.some]}
                        many={trls[TrlKeys.adviceCounter.many]}
                    />
                </span>
            </div>
        </BannerWrapper>
    );
};

export default translation(AdviceBanner);
