import { MutableRefObject, useState } from 'react';
import classnames from 'classnames';

import { Text, Title, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import QuestionsInput from 'src/components/VacancyView/VacancyResponseQuestions/QuestionsInput';
import Suggest from 'src/components/VacancyView/VacancyResponseQuestions/Suggest';

import styles from './VacancyResponseQuestions.less';

export const checkVacancyResponseQuestionsRender = (state: AppStore): boolean => {
    const isNeededUserType = [UserType.Applicant, UserType.Anonymous, UserType.NedoUser].includes(state.userType);
    const vacancyId = state.vacancyView.vacancyId;
    const responseStatus = vacancyId ? state.applicantVacancyResponseStatuses[vacancyId] : null;
    const isCurrentlyApplied = responseStatus && responseStatus.usedResumeIds?.length > 0;
    return !isCurrentlyApplied && isNeededUserType && vacancyId !== undefined;
};

interface VacancyResponseQuestionsProps {
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    type?: 'question' | 'response';
    hasBottomGap?: boolean;
    hasSuggest?: boolean;
}

const TrlKeys = {
    responseTitle: 'vacancy.view.responseQuestions.title',
    questionTitle: 'vacancy.view.responseQuestions.questionTitle',
    questionDescription: 'vacancy.view.responseQuestions.questionDescription',
    subTitle: 'vacancy.view.responseQuestions.subTitle',
    placeholder: 'vacancy.view.responseQuestions.greeting',
};

const VacancyResponseQuestions: TranslatedComponent<VacancyResponseQuestionsProps> = ({
    trls,
    vacancyBodyFooterNodeRef,
    type = 'response',
    hasBottomGap = true,
    hasSuggest = type === 'response',
}) => {
    const [text, setText] = useState(`${trls[TrlKeys.placeholder]} `);

    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain || '');
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const applicantInfo = useSelector((state) => state.applicantInfo);
    const show = useSelector(checkVacancyResponseQuestionsRender);
    const hasHiddenResumes = Boolean(applicantInfo?.visible !== applicantInfo?.total);
    const { isMobile } = useBreakpoint();

    if (!show || typeof vacancyId !== 'number') {
        return null;
    }

    const isResponseType = type === 'response';
    const isQuestionType = type === 'question';
    const suggest =
        isResponseType || hasSuggest ? (
            <Suggest
                isMagritteVacancy
                vacancyId={vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                type={type}
                onClick={setText}
            />
        ) : null;
    const titleTrl = isResponseType
        ? trls[TrlKeys.responseTitle]
        : format(trls[TrlKeys.questionTitle], { '{0}': topLevelDomain });

    const renderQuestionDescription = () => {
        const questionDescriptionTrl = trls[TrlKeys.questionDescription];

        return (
            <Text style="secondary" typography="label-2-regular">
                {questionDescriptionTrl}
            </Text>
        );
    };

    const renderResponseTypeContent = () => {
        const subTitleTrl = trls[TrlKeys.subTitle];

        return (
            <>
                <Text typography="label-2-regular">{subTitleTrl}</Text>
                <VSpacing default={isMobile ? 16 : 24} />
                {suggest}
            </>
        );
    };

    return (
        <div
            className={classnames(styles.vacancyResponseQuestions, {
                [styles.vacancyResponseQuestionsTypeResponse]: isResponseType,
                [styles.vacancyResponseQuestionsTypeQuestion]: isQuestionType,
                [styles.vacancyResponseQuestionsWithSuggest]: Boolean(suggest),
                [styles.vacancyResponseQuestionsBottomGap]: hasBottomGap,
            })}
        >
            <Title Element="h2" size="small">
                {titleTrl}
            </Title>
            {isQuestionType && hasHiddenResumes ? renderQuestionDescription() : null}
            <VSpacing default={isResponseType ? 6 : 16} />
            {isResponseType ? (
                renderResponseTypeContent()
            ) : (
                <QuestionsInput value={text} onChange={setText} suggest={suggest} vacancyId={vacancyId} />
            )}
        </div>
    );
};

export default translation(VacancyResponseQuestions);
