import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { AnalyticsParams } from '@hh.ru/analytics-js/lib/hhAnalytics';
import { Action, BottomSheet, Button, Modal, NavigationBar, useBreakpoint, ActionBar } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import TotalPrice from 'src/components/EmployerVacancies/TotalPrice';
import useShowRisePage from 'src/components/Rise/useShowRisePage';
import { openBlankRisePage } from 'src/components/VacancyActions/risePageUtils';
import { useRiseAdvice } from 'src/components/VacancyActions/useRiseAdvice';
import ProlongateInfoBottomSheet from 'src/components/VacancyModals/components/ProlongateInfoBottomSheet';
import ProlongateInfoSubtitle from 'src/components/VacancyModals/components/ProlongateInfoSubtitle';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';

import Advice from 'src/components/VacancyModals/Prolongation/Advice';
import Content from 'src/components/VacancyModals/Prolongation/Content';
import FreeProlongationTimer from 'src/components/VacancyModals/Prolongation/FreeProlongationTimer';
import SubmitButtons from 'src/components/VacancyModals/Prolongation/SubmitButtons';
import UnavailableDetails from 'src/components/VacancyModals/Prolongation/UnavailableDetails';
import useSubmitButtons, { SubmitButtonType } from 'src/components/VacancyModals/Prolongation/useSubmitButtons';

import styles from './styles.less';

export type AfterSuccessAction = ({
    handleCloseModal,
    queryParams,
    vacancyIds,
}: {
    handleCloseModal: () => void;
    queryParams: Record<string, number[]>;
    vacancyIds: number[];
}) => void;

interface ProlongationProps {
    afterSuccessAction: AfterSuccessAction;
    handleCloseModal: () => void;
    freeRenewalParameters: {
        intervalMinutes: string | null;
        lastActivationTimeIso: string | null;
    };
    isVisible: boolean;
    showRegularButton: boolean;
    vacanciesIds: number[];
    additionalAnalyticsParams?: AnalyticsParams;
}

const TrlKeys = {
    moreReaction: 'vacancy.prolongate.submit.moreReaction',
    title: 'employer.myVacancies.prolongate.title',
    vacancy: {
        one: 'employer.vacancyRisePage.vacancyOne',
        some: 'employer.vacancyRisePage.vacancySome',
        many: 'employer.vacancyRisePage.vacancyMany',
    },
};

const sendEventElementShown = (
    element: HTMLElement,
    bodyData: {
        availableToSpendIds: number[];
        availableToFreeProlongateIds: number[];
        availableToPurchaseIds: number[];
        unavailableIds: number[];
        submitButtonType: SubmitButtonType;
        vacancyId?: number;
    }
) =>
    Analytics.sendHHEventElementShown(
        element,
        {
            elementName: 'prolongation_modal',
        },
        // Из-за большого кол-ва вакансий используем body, а не query параметры
        {
            ...bodyData,
        }
    );

const sendEventElementShownOnHoverInfoTip = (vacancyId: number) =>
    Analytics.sendHHEvent('element_shown', {
        elementName: 'prolongation_modal_info_tip',
        vacancyId,
    });

const setVacancyAdvicePlaceAllowedAction = makeSetStoreField('isVacancyAdvicePlaceAllowed');

const Prolongation: TranslatedComponent<ProlongationProps> = ({
    afterSuccessAction,
    handleCloseModal,
    freeRenewalParameters,
    isVisible,
    showRegularButton,
    vacanciesIds,
    additionalAnalyticsParams = {},
    trls,
}) => {
    const { isRisePageEnabled } = useShowRisePage();

    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToPurchaseIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds
    );
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);
    const { currency, totalPrice } = purchase || {};
    const unavailableIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable.vacancyIds);

    const total = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.total);
    const { lastActivationTimeIso, intervalMinutes } = freeRenewalParameters;
    const isTimerVisible = !!lastActivationTimeIso && !!intervalMinutes;
    const { isMobile, isGtS } = useBreakpoint();

    const [locVisible, onLocVisible, offLocVisible] = useOnOffState(true);
    const [isVisibleDetails, onVisibleDetails, offVisibleDetails] = useOnOffState(false);
    const [loading, startLoading, finishLoading] = useOnOffState(false);
    const [visibleInfo, showInfo, hideInfo] = useOnOffState(false);
    const submitButtonType = useSubmitButtons();

    const runActionArgument = useSelector((state) => state.runActionArgument);

    const dispatch = useDispatch();
    const { spoilPristineNotice, isAllowed: isAllowedRiseAdvice } = useRiseAdvice();

    const showAdvice = isAllowedRiseAdvice && runActionArgument === 'prolongate';

    useEffect(() => {
        if (showAdvice && visibleInfo) {
            sendEventElementShownOnHoverInfoTip(vacanciesIds[0]);
        }
    }, [showAdvice, visibleInfo, vacanciesIds]);

    useEffect(() => {
        return () => {
            if (showAdvice) {
                spoilPristineNotice();
            }
        };
    }, [showAdvice, spoilPristineNotice]);

    const onCloseModal = useCallback(() => {
        if (showAdvice) {
            dispatch(setVacancyAdvicePlaceAllowedAction(false));
        }
        handleCloseModal();
    }, [handleCloseModal, showAdvice, dispatch]);

    const handleClickMoreDetails = useCallback(() => {
        if (isGtS) {
            offLocVisible();
        }
        onVisibleDetails();
    }, [isGtS, offLocVisible, onVisibleDetails]);

    const handleCloseDetailsModal = useCallback(() => {
        offVisibleDetails();
        if (isGtS) {
            onLocVisible();
        }
    }, [isGtS, offVisibleDetails, onLocVisible]);

    const handleOpenBlankRisePage = useCallback(() => {
        Analytics.sendHHEventButtonClick(
            'prolongation_modal_regular_rise_button',
            {
                ...additionalAnalyticsParams,
            },
            {
                vacanciesIds,
            }
        );
        openBlankRisePage(vacanciesIds, 'regularly');
    }, [additionalAnalyticsParams, vacanciesIds]);

    const title = formatToReactComponent(trls[TrlKeys.title], {
        '{0}': (
            <ConversionNumber
                one={trls[TrlKeys.vacancy.one]}
                some={trls[TrlKeys.vacancy.some]}
                many={trls[TrlKeys.vacancy.many]}
                hasValue={total !== 1}
                value={total}
            />
        ),
    });

    const content = (
        <ElementShownAnchor
            fn={sendEventElementShown}
            availableToSpendIds={availableToSpendIds}
            availableToFreeProlongateIds={availableToFreeProlongateIds}
            availableToPurchaseIds={availableToPurchaseIds}
            unavailableIds={unavailableIds}
            submitButtonType={submitButtonType}
            vacancyId={showAdvice ? vacanciesIds[0] : undefined}
            {...additionalAnalyticsParams}
        >
            {showAdvice && <Advice vacancyId={vacanciesIds[0]} />}
            <Content handleClickMoreDetails={handleClickMoreDetails} />
        </ElementShownAnchor>
    );

    const footer = (
        <>
            {isRisePageEnabled && showRegularButton && (
                <Button
                    mode="secondary"
                    style="accent"
                    size="medium"
                    stretched
                    data-qa="to-rise-page"
                    onClick={handleOpenBlankRisePage}
                >
                    {trls[TrlKeys.moreReaction]}
                </Button>
            )}
            <SubmitButtons
                isTimerVisible={isTimerVisible}
                afterSuccessAction={afterSuccessAction}
                handleCloseModal={onCloseModal}
                loading={loading}
                startLoading={startLoading}
                finishLoading={finishLoading}
                additionalAnalyticsParams={additionalAnalyticsParams}
            />
            <FreeProlongationTimer freeRenewalParameters={freeRenewalParameters} />
            {availableToPurchaseIds.length > 1 && (
                <div className={styles.totalWrap}>
                    <TotalPrice currency={currency} totalPrice={totalPrice} />
                </div>
            )}
        </>
    );

    return (
        <>
            {isMobile ? (
                <>
                    <BottomSheet
                        header={
                            <NavigationBar
                                title={title}
                                right={<Action icon={InfoCircleOutlinedSize24} onClick={showInfo} />}
                                showDivider="always"
                            />
                        }
                        footer={<ActionBar primaryActions={footer} showDivider={false} />}
                        visible={isVisible && locVisible}
                        onClose={onCloseModal}
                    >
                        {content}
                    </BottomSheet>
                    <ProlongateInfoBottomSheet visible={visibleInfo} onClose={hideInfo} />
                </>
            ) : (
                <Modal
                    visible={isVisible && locVisible}
                    size="small"
                    title={title}
                    titleSize="medium"
                    actions={
                        <Action
                            mode="secondary"
                            icon={CrossOutlinedSize24}
                            data-qa="prolongate-modal-close"
                            onClick={onCloseModal}
                        />
                    }
                    titleDescription={
                        <ProlongateInfoSubtitle
                            onHoverAppear={
                                showAdvice ? () => sendEventElementShownOnHoverInfoTip(vacanciesIds[0]) : undefined
                            }
                        />
                    }
                    titleDescriptionStyle="secondary"
                    footer={<ActionBar type="modal-vertical" primaryActions={footer} />}
                    onClose={onCloseModal}
                >
                    {content}
                </Modal>
            )}
            <UnavailableDetails visible={isVisibleDetails} handleCloseDetailsModal={handleCloseDetailsModal} />
        </>
    );
};

export default translation(Prolongation);
