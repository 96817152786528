import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const VacancyAndArea: FC = () => {
    const areaName = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.areaName);
    const name = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.name);

    return (
        <Text style="secondary" typography="paragraph-2-regular">
            {name},{NON_BREAKING_SPACE}
            {areaName}
        </Text>
    );
};

export default VacancyAndArea;
