import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppStore } from 'src/app/store';
import { VacancyManagers, vacancyUpdateManagersAction } from 'src/models/vacancyView';
import fetcher from 'src/utils/fetcher';

const GET_VACANCY_MANAGERS_URL = '/shards/employer/vacancy/managers';

declare global {
    interface FetcherGetApi {
        [GET_VACANCY_MANAGERS_URL]: {
            queryParams: { vacancyId: string; ownerVacancyId: string };
            response: VacancyManagers | null;
        };
    }
}

interface FetchVacancyManagersParams {
    params: {
        vacancyId: string;
        ownerVacancyId: string;
    };
}
export type FetchVacancyManagers = (
    params: FetchVacancyManagersParams
) => ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction>;

const fetchVacancyManagers: FetchVacancyManagers =
    ({ params: { vacancyId, ownerVacancyId } }) =>
    async (dispatch) => {
        let managers: VacancyManagers | null;

        try {
            managers = await fetcher.get(GET_VACANCY_MANAGERS_URL, { params: { vacancyId, ownerVacancyId } });
        } catch (error) {
            console.error(error);
            return;
        }

        dispatch(vacancyUpdateManagersAction(managers));
    };

export default fetchVacancyManagers;
