import { FC, PropsWithChildren } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import auctionContentElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_content_element_shown';

import useHhtmSourceLabelFromRunActionQs from 'src/components/Auction/hooks/useHhtmSourceLabelFromRunActionQs';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { AuctionFieldName } from 'src/models/auctionData';

import styles from './styles.less';

const AuctionContentStatisticsShown: FC<PropsWithChildren> = ({ children }) => {
    const {
        bidRating,
        controls,
        vacancyId,
        campaignDraft,
        currentVacancyAuctionCampaign,
        campaignCreationStatus,
        auctionStatistics,
    } = useSelectorNonNullable(({ auctionData }) => auctionData);
    const vacancyCreateDraftId = useSelector(({ vacancyCreateDraftId }) => vacancyCreateDraftId);
    const hhtmSourceLabel = useHhtmSourceLabelFromRunActionQs();

    const campaignId = currentVacancyAuctionCampaign ? String(currentVacancyAuctionCampaign.campaignId) : undefined;
    const campaignDraftId = campaignDraft ? String(campaignDraft.campaignDraftId) : undefined;
    const campaignStatus = currentVacancyAuctionCampaign?.campaignStatus;
    return (
        <ElementShownAnchor
            fn={auctionContentElementShown}
            vacancyId={vacancyId}
            campaignId={campaignId}
            campaignDraftId={campaignDraftId}
            campaignStatus={campaignStatus}
            campaignCreationStatus={campaignCreationStatus}
            draftId={vacancyCreateDraftId}
            bid={controls[AuctionFieldName.Bid].value}
            budget={controls[AuctionFieldName.Budget].value}
            competingCampaignBids={JSON.stringify(bidRating.data.competingCampaignBids)}
            competingCampaignIds={JSON.stringify(bidRating.data.competingCampaignIds)}
            clicks={auctionStatistics.clicks}
            response={auctionStatistics.responses}
            className={styles.modalContentShownAnchor}
            initialBid={controls[AuctionFieldName.Bid].initialValue}
            initialBudget={controls[AuctionFieldName.Budget].initialValue}
            ratingStatus={bidRating.data.bidFrequencyStatus}
            hhtmSourceLabel={hhtmSourceLabel}
        >
            {children}
        </ElementShownAnchor>
    );
};

export default AuctionContentStatisticsShown;
